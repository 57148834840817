import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule, HelpersModule } from 'core';
import { RouterModule } from '@angular/router';
import { SiteSetupEditComponent } from './site-setup-edit/site-setup-edit.component';
import { SiteSetupComponent } from './site-setup.component';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    RouterModule,
    HelpersModule,
    ReactiveFormsModule
  ],
  declarations: [SiteSetupEditComponent, SiteSetupComponent]
})
export class SiteSetupModule { }
