import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { UrlModel } from '../../../_models/urlModel';
import { SettingsService, AlertifyService } from 'core';

@Component({
  selector: 'app-redirect-urls',
  templateUrl: './redirect-urls.component.html',
  styleUrls: ['./redirect-urls.component.scss'],
  providers: [SettingsService]
})
export class RedirectUrlsComponent implements OnInit {
  @Input() instanceId: string;
  urls: any = {completeRedirectURL: '', terminateRedirectURL: '', overQuotaRedirectURL: '', qcRedirectURL: ''};

  constructor( private settingsService: SettingsService, private alertify: AlertifyService) {  }


  ngOnInit() {
    this.getRedirectUrls();
  }

  getRedirectUrls() {
   this.settingsService.getSurveyLinkUrls().subscribe( data => {
    this.urls = data;
    }, error => {
       this.alertify.error('Unable to get Survey Links');
    },
    () => {

    });
   }


copyLink(url: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.alertify.message('The link is now copied.');
  }

copyAllLinks() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = 'CompleteRedirectUrl  = ' + this.urls.completeRedirectURL + '\n' +
                   'TerminateRedirectUrl = ' + this.urls.terminateRedirectURL + '\n' +
                   'OverQuotaRedirectUrl = ' + this.urls.overQuotaRedirectURL + '\n' +
                   'QCRedirectUrl        = ' + this.urls.qcRedirectURL;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.alertify.message('All links are now copied.');
  }
}
