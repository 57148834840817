@if (names?.length > 0 || name || url) {
  @if (names?.length == 0) {
    <div [ngClass]="'image-container ' + type + ' ' + size" [class.selected]="selected">
      @if (url != null &&  url != '') {
        <img [src]="url" [alt]="name" />
      }
      @if (url == null || url == '') {
        @if (showInitials) {
          <div class="initials">
            @if (this.name) {
              <span class="editable">{{ getInitials(name) }}</span>
            }
          </div>
        }
        @if (!showInitials) {
          <div class="picture"></div>
        }
      }
      @if (icon != null && icon != '') {
        <span class="badge badge-info notify-badge"><i [ngClass]="'fa ' + icon" aria-hidden="true"></i></span>
      }
    </div>
  }
  <!-- multiple types (PM, SecondPM, AccountOwner) -->
  @if (names?.length > 0) {
    <div class="row">
      @for (item of names; track item; let idx = $index) {
        <div [style.z-index]="names.length-idx"
          [ngClass]="'image-container multiple ' + type + ' ' + size + ' ' + item?.type"
          [class.selected]="selected"
          [tooltip]="getTooltip(item)">
          @if (url != null &&  url != '') {
            <img [src]="url" />
          }
          @if (url == null ||  url == '') {
            @if (showInitials) {
              <div class="initials">
                <span class="">{{ getInitials(item?.name) }}</span>
              </div>
            }
            @if (!showInitials) {
              <div class="picture"></div>
            }
          }
          @if (icon != null && icon != '') {
            <span class="badge badge-info notify-badge"><i [ngClass]="'fa ' + icon" aria-hidden="true"></i></span>
          }
        </div>
      }
    </div>
  }
}






