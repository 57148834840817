
<ng-container *ngIf="auth.isSuperAdmin()">
  <div class="d-flex mb-3 mt-1 border-bottom">
    <div class="text-truncate or-secondary h4 m-2"> Site Setup </div>
    <div class="vl ml-2"></div>
    <div class="sectionbtn nav-item mt-1">
      <a class="nav-link or-primary cursor-pointer" [class.font-weight-bold]="tab == 'navigation'" (click)="tab = 'navigation'">Navigation Bar </a>
    </div>
    <div class="sectionbtn nav-item mt-1">
      <a class="nav-link or-primary cursor-pointer" [class.font-weight-bold]="tab == 'theme'" (click)="tab = 'theme'">Theme </a>
    </div>
    <div class="sectionbtn nav-item mt-1 mr-auto">
      <a class="nav-link or-primary d-none" [class.font-weight-bold]="tab == 'settings'">Others </a>
    </div>
  </div>
  
  <div class="card" *ngIf="tab == 'navigation'">
    <div class="card-header or-primary-bg text-white">
      <p class="h4 inline float-left"><em class="fa fa-cog fa-sm"></em> Site Setup - Navigation Menu</p>
      <div class="float-right ml-2">
          <or-view-toggle (valueChange)='mode=$event' [showList]=true [showCard]=true active='list' [class]="'btn-sm btn-light'"></or-view-toggle>
          <button type="button" (click)="openModal('add')" class="btn btn-sm btn-outline-light inline ml-2" routerLinkActive="active"><em class="fa fa-plus-circle"></em> Add Section</button>
      </div>
    </div>
    <div class="card-body">
      <div class="my-2">
        <nav *ngIf="mode == 'card'" class="navbar navbar-expand-lg navbar-light bg-light shadow-sm">
          <div class="container-fluid d-block p-2">
            <a class="navbar-brand">Navigation Bar Preview <i class="fa fa-arrow-down fa-sm or-secondary opacity-70"></i></a>
            <ul class="navbar-nav my-3">
              <ng-container *ngFor="let section of sections | filter : filterText : filterColumns">
                <li class="nav-item p-2 dropdown onhover-dropdown border" *ngIf="section.parentId == null">
                  <a class="nav-link fa-lg" [tooltip]="section.label">
                    <i class="{{ section.icon }} or-secondary opacity-70" *ngIf="section.icon !== null && section.icon !== ''"></i>&nbsp;
                    <span class="d-none d-xl-inline d-lg-none d-xs-inline">{{ section.label }}</span>
                  </a>
                  <div class="m-0 dropdown-menu mt-2 dropdown-menu-right onhover-show-div" *ngIf="section?.childs && section.childs?.length > 0">
                    <a class="dropdown-item or-secondary" *ngFor="let child of section.childs">
                      <i class="{{ child.icon }} or-secondary opacity-70" *ngIf="child.icon !== null && child.icon !== ''"></i>&nbsp;
                      <span class="d-none d-xl-inline d-lg-none d-xs-inline">{{ child.label }}</span>
                    </a>
                  </div>
                </li>
              </ng-container>
            </ul>
          </div>
        </nav>
  
        <or-list *ngIf="mode == 'list'" [items]="sections | filter : filterText : filterColumns" [layout]="layout" [actions]="actions" [enableExport]=false (action)="handleAction($event)"></or-list>
  
      </div>
  
    </div>
  </div>
  
  <div class="card" *ngIf="tab == 'theme'">
    <div class="card-header or-primary-bg text-white">
      <p class="h4 inline float-left"><em class="fa fa-cog fa-sm"></em> Site Setup - Theme</p>
    </div>
    <div class="card-body">
      <div class="row m-3">
        <div class="col-12">
          <label for="logo">Logo Preview <span class="text-muted"></span></label>
        </div>
        <div class="col-md-12 col-lg-6">
          <or-upload  [smallMsg]="'png or jpg'" [showPreviewImg]=true [directToS3]=true [acceptImages]=true (uploadCompleted)="uploadCompleted($event)" [(ngModel)]="instance.logo" id="logo"></or-upload>
        </div>
      </div>
    </div>
  </div>
</ng-container>



<ng-template #modalConfirm>
  <div class="modal-header">
    <p class="h5">Confirm Delete</p>
  </div>
  <div class="modal-body text-center">
    <p class="h6">Do you wish to continue?</p>
    <button type="button" class="btn btn-outline-secondary m-2" (click)="confirmDelete()">Yes</button>
    <button type="button" class="btn btn-secondary" (click)="bsModalRef.hide()">No</button>
  </div>
</ng-template>
