import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Client, AlertifyService, DropdownService, ClientService, AuthService, UtilsService, UserService } from 'core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.css'],
  providers: [UserService]
})
export class ClientEditComponent implements OnInit {
  model: Client;
  clientForm: FormGroup;
  owners: any[];
  submitted = false;
  cloneid: string;
  constructor(private alertify: AlertifyService, private router: Router, private dd: DropdownService, private client: ClientService, public auth: AuthService,
    private route: ActivatedRoute, public userService: UserService, public utils: UtilsService) { }

  ngOnInit() {

    this.userService.GetInstanceUsers(this.auth.getInstance(), true).subscribe(data => {
      this.owners = data;
    }, error => {
      this.alertify.error('Unable to get users');
    },
      () => {
      });

    this.route.paramMap.subscribe(params => {
      const id: string = params.get('id');
      this.cloneid = params.get('cloneid');

      if (id) {
        this.loadData(id);
      } else if (this.cloneid) {
        this.loadData(this.cloneid);
      } else if (history.state.data != null) {
        this.model = history.state.data;
        this.bindForm();
      } else {
        this.router.navigate(['/clients']);
      }

    });

  }

  clientEdit() {

    this.submitted = true;
    if (!this.clientForm.valid) {
      return;
    }
    if (this.cloneid) {
      this.clientForm.value.id = null;
      this.client.AddClient(this.clientForm.value).subscribe( data => {
        this.model = data;
      }, error => {
         this.alertify.error('Unable to add client');
      },
      () => {
        this.alertify.success('Client cloned successfully');
        this.router.navigate(['/clients']);
      });
    } else {
      this.client.EditClient(this.clientForm.value).subscribe(data => {
        this.model = data;
      }, error => {
        this.alertify.error('Unable to update client');
      },
        () => {
          this.alertify.success('Client updated successfully');
          this.router.navigate(['/clients']);
        });
    }
  }


  loadData(id: any) {
    this.client.GetClient(id, true).subscribe(data => {
      this.model = data;
    }, error => {
      this.alertify.error('Unable to get client');
    },
      () => {
        this.bindForm();
      });
  }

  bindForm() {
    if (this.model) {
      this.clientForm = new FormGroup({
        id: new FormControl(this.model.id, [Validators.required]),
        name: new FormControl(this.model.name, [Validators.required]),
        website: new FormControl(this.model.website),
        img: new FormControl(this.model.img),
        instanceId: new FormControl(this.model.instanceId),
        address1: new FormControl(this.model.address.address1),
        address2: new FormControl(this.model.address.address2),
        city: new FormControl(this.model.address.city),
        country: new FormControl(this.model.address.country),
        state: new FormControl(this.model.address.state),
        zip: new FormControl(this.model.address.zip),
        facebook: new FormControl(this.model.social.facebook),
        linkedIn: new FormControl(this.model.social.linkedIn),
        twitter: new FormControl(this.model.social.twitter),
        skype: new FormControl(this.model.social.skype),
        salesTerm: new FormControl(this.model.salesTerm),
        salesTermValue: new FormControl(this.model.salesTermValue),
        internalAccountOwnerId: new FormControl(this.model.internalAccountOwnerId)
      });
    }
  }


}
