import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'core';
import { AlertifyService } from 'core';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private alertify: AlertifyService) {}

  canActivate(next: ActivatedRouteSnapshot): boolean {

    let allow = this.authService.loggedIn();
    const roles = next.firstChild.data['roles'] as Array<string>;
    if (allow && roles) {
      const match = this.authService.roleMatch(roles);
      if (match) {
        allow =  true;
      } else {
        allow =  false;
      }
    }
    if(!allow)
    {
      this.router.navigate(['']);
    }
    return allow;
  }
}
