import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {AuthService, AlertifyService} from 'core';


@Component({
  selector: 'app-reset-link',
  templateUrl: './reset-link.component.html',
  styleUrls: ['./reset-link.component.scss']
})
export class ResetLinkComponent implements OnInit {
  model: any = {};
  resetForm: FormGroup;
  constructor(private auth: AuthService, private router: Router,
              private activatedRoute: ActivatedRoute, private alertify: AlertifyService) { }

  ngOnInit() {
      this.activatedRoute.queryParams.subscribe(params => {
          this.model.code = params['code'];
      });
      this.resetForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required, Validators.minLength(4)]),
        confirmPassword: new FormControl('', [Validators.required])
      }, this.passwordMatchValidator);
  }

  passwordMatchValidator(g: FormGroup) {
    return g.get('password').value === g.get('confirmPassword').value ? null : {mismatch: true};
  }

  reset() {
    this.model.email = this.resetForm.value.email;
    this.model.password = this.resetForm.value.password;
    this.auth.reset(this.model).subscribe(next => {
      this.alertify.success('Your password has been changed');
      this.router.navigate(['']);
    }, error => {
      this.alertify.error(error);
    }, () => {
       this.router.navigate(['']);
    });
  }

}