import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SurveySettingsComponent } from './survey-settings.component';
import { AlertsComponent } from './alerts/alerts.component';
import { RedirectUrlsComponent } from './redirect-urls/redirect-urls.component';
import { ComponentsModule, HelpersModule } from 'core';
import { HealthConfigComponent } from './health-config/health-config.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    HelpersModule,
    ComponentsModule,
    HelpersModule
  ],
  declarations: [SurveySettingsComponent, AlertsComponent, RedirectUrlsComponent, HealthConfigComponent]
})
export class SurveySettingsModule { }
