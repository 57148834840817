<div class="row">
  <div class="form-group col-12 col-md-4">
    <label class="required">Name</label>
    <input [(ngModel)]="selectedType.name" class="form-control" name="name" required [ngClass]="showCssValidField('name')">
  </div>
  <div class="form-group col-12 col-md-4">
    <label class="required">Prefix</label>
    <input [(ngModel)]="selectedType.prefix" class="form-control" name="prefix" required [ngClass]="showCssValidField('prefix')">
  </div>
  <div class="form-group col-12 col-md-4">
    <label class="required">Description</label>
    <input [(ngModel)]="selectedType.description" class="form-control" name="description" required [ngClass]="showCssValidField('description')">
  </div>
</div>

<hr>
<div class="card">
  <div class="card-header d-flex align-items-center bg-white border-bottom-0">
    <span class="h6 or-primary">Enable Features</span>
  </div>
  <div class="px-4 py-2">
    <div class="row">
      <div class="form-group  col-6 col-md-2">
        <label>Show Download</label>
        <or-toggle-button msgON="On" msgOFF="Off" size="small" [(ngModel)]="selectedType.showDownload" name="showDownload"></or-toggle-button>
      </div>
      <div class="form-group  col-6 col-md-2">
        <label>Show Reconciliation</label>
        <or-toggle-button msgON="On" msgOFF="Off" size="small" [(ngModel)]="selectedType.showReconciliation" name="showReconciliation"></or-toggle-button>
      </div>
      <div class="form-group  col-6 col-md-2">
        <label>Show Notes</label>
        <or-toggle-button msgON="On" msgOFF="Off" size="small" [(ngModel)]="selectedType.showNotes" name="showNotes"></or-toggle-button>
      </div>

      <div class="form-group  col-6 col-md-2">
        <label>Show Copy URLs</label>
        <or-toggle-button msgON="On" msgOFF="Off" size="small" [(ngModel)]="selectedType.showCopyUrls" name="showCopyUrls"></or-toggle-button>
      </div>
      <div class="form-group  col-6 col-md-2">
        <label>Show Audit Log</label>
        <or-toggle-button msgON="On" msgOFF="Off" size="small" [(ngModel)]="selectedType.showAuditLogs" name="showAuditLogs"></or-toggle-button>
      </div>
      <div class="form-group  col-6 col-md-2">
        <label>Show Project Manager</label>
        <or-toggle-button msgON="On" msgOFF="Off" size="small" [(ngModel)]="selectedType.showProjectManager" name="showProjectManager"></or-toggle-button>
      </div>
     <!-- <div class="form-group  col-6 col-md-2">
        <label>Quick Add</label>
        <or-toggle-button msgON="On" msgOFF="Off" size="small" [(ngModel)]="selectedType.quickAddMode" name="quickAddMode"></or-toggle-button>
      </div>-->
      <div class="form-group  col-6 col-md-2">
        <label>Show Full Service/Sample Only</label>
        <or-toggle-button msgON="On" msgOFF="Off" size="small" [(ngModel)]="selectedType.showFSSO" name="showFSSO"></or-toggle-button>
      </div>
    </div>
  </div>
</div>

<hr>
<div class="card">
  <div class="card-header d-flex align-items-center bg-white border-bottom-0">
    <span class="h6 or-primary">Project Health and Security</span>
  </div>
  <div class="px-4 py-2 mb-3">
    <div class="row">
      <div class="col-6 col-md-3"><label>Show Health Icon</label></div>
      <div class="col-6 col-md-9"><label>Default Health Configs</label></div>
    </div>
    <div class="row">
      <div class="col-6 col-md-3 d-flex align-items-center">
        <label>&nbsp;</label>
        <or-toggle-button msgON="On" msgOFF="Off" size="small" [(ngModel)]="selectedType.showHealth" name="showHealth"></or-toggle-button>
      </div>
      <div class="col-6 col-md-9" *ngIf="healthConfigs.length > 0">
        <div class="row">
          <div class="col-4">
            <div class="text-secondary small">
              Consumer
            </div>
            <select class="col-12 form-control form-control-sm" [(ngModel)]="selectedType.defaultHealthConfigConsumer" name="healthConfigConsumer">
              <option selected [value]="'00000000-0000-0000-0000-000000000000'" >default</option>
              <option *ngFor="let config of healthConfigs" [value]="config.id">{{config.configName}}</option>
            </select>
          </div>
          <div class="col-4">
            <div class="text-secondary" style="font-size: 85%">
              B2B
            </div>
            <select class="col-12 form-control form-control-sm" [(ngModel)]="selectedType.defaultHealthConfigB2B" name="healthConfigB2B">
              <option selected [value]="'00000000-0000-0000-0000-000000000000'" >default</option>
              <option *ngFor="let config of healthConfigs" [value]="config.id">{{config.configName}}</option>
            </select>
          </div>
          <div class="col-4">
            <div class="text-secondary" style="font-size: 85%">
              Healthcare
            </div>
            <select class="col-12 form-control form-control-sm" [(ngModel)]="selectedType.defaultHealthConfigHealthcare" name="healthConfigHealthcare">
              <option selected [value]="'00000000-0000-0000-0000-000000000000'" >default</option>
              <option *ngFor="let config of healthConfigs" [value]="config.id">{{config.configName}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6 col-md-3"><label>Survey to NavigatOR link security</label></div>
    </div>
    <div class="row">
      <div class="col-8 col-md-3 d-flex align-items-center">
        <label>&nbsp;</label>
        <or-toggle-button msgON="On" msgOFF="Off" size="small" [(ngModel)]="linkSecure" (changed)="selectedType.defaultSecurityConfigSurvey = null" name="linkSecure"></or-toggle-button>
        <div class="col-6 text-secondary small ml-3">
          Select API Key:
        </div>
        <select class="col-12 form-control form-control-sm" [(ngModel)]="selectedType.defaultSecurityConfigSurvey" name="healthConfigConsumer" [disabled]="!linkSecure" placeholder="Select Default API Key">
          <option *ngFor="let config of securityConfigs" [value]="config.id">{{config.name}}</option>
        </select>
      </div>
    </div>
  </div>
</div>
