
<div class="modal-header text-white">
  <p class="h4 position-absolute"><em class="fa fa-cog fa-sm"></em> Section Details</p>
  <button type="button" class="close pull-right text-white" aria-label="Close" (click)="bsModalRef.hide();">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="editForm" (ngSubmit)="submitForm()" novalidate>
    <input type="hidden" formControlName="id" id="id">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="row mb-3">
              <div class="col">
                <label for="label" class="required">Label</label>
                <div class="input-group">
                  <input type="text" class="form-control" formControlName="label" id="label" [ngClass]="submitted && utils.showCssValidField(editForm, 'label')">
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <label for="type">Type</label>
                <select class="custom-select d-block w-100" #type formControlName="type" id="type" [ngClass]="submitted && utils.showCssValidField(editForm, 'type')">
                  <option [ngValue]=null>- None -</option>
                  <option *ngFor="let type of sectionTypes" [value]="type.value">{{type.name}}</option>
                </select>
              </div>        
              <div class="col">
                <label for="sortOrder" class="required">Sort Order</label>
                <input type="number" maxlength="3" oninput="validity.valid||(value='');" min="0" class="form-control" formControlName="sortOrder" id="sortOrder" [ngClass]="submitted && utils.showCssValidField(editForm, 'sortOrder')">
              </div>

              <div class="col-12" *ngIf="editForm.get('type').value == 'ReportsResponseIDApi' || editForm.get('type').value == 'ReportsCleanID'">
                <label for="type">Other InstanceId (leave blank if data is for this instance)</label>
                <input type="text" class="form-control" formControlName="otherInstanceId" id="otherInstanceId">
              </div>
            </div>          
            <div class="row mb-3">
              <div class="col">
                <label for="icon">Icon</label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i [ngClass]="editForm.get('icon').value"></i>
                    </span>
                  </div>
                  <input type="text" formControlName="icon" id="icon" autocomplete="off"
                          [iconPicker]="editForm.get('icon').value"
                          [ipIconPack]="['fa5']"
                          [ipPosition]="'bottom'"
                          [ipFallbackIcon]="' '"
                          (iconPickerSelect)="onIconPickerSelect($event)" class="form-control"
                          [ngClass]="submitted && utils.showCssValidField(editForm, 'icon')">
                </div>
              </div>
              <div class="col">
                <label for="parentId">Parent Section</label>
                <select class="custom-select d-block w-100" formControlName="parentId" id="parentId">
                  <option [ngValue]=null>- None -</option>
                  <option *ngFor="let section of sections" [value]="section.id">{{section.label}}</option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <label for="instanceRoleId">Roles</label>
                <or-type-ahead [selectedValues]="selectedRoles === null ? [] : selectedRoles" [labelField]="'name'" [valueField]="'id'" multi=true [dataCallback]="getRoleList" placeholder="Select a Role" (valueChange)="updateRole($event)"></or-type-ahead>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer text-right">
        <button type="submit" class="btn btn-sm or-primary-bg text-white"><em class="fa fa-save"></em> Save Section</button>
      </div>
    </div>
  </form>
</div>
