import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { AlertifyService, User, DropdownService, UserService, AuthService, UtilsService, Client, ClientService, RoleService, PartnerService, Partner, CountriesService} from 'core';
import { map } from 'rxjs';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.scss']
})
export class UserAddComponent implements OnInit {

  instanceId = '';
  model: User;
  registerForm: FormGroup;
  country: any;
  states: any[];
  clients: Client[];
  countryList: any;
  vendors: Partner[];
  headers = ['Roles', 'Added Roles'];
  roles: any[] = [];
  selectedRoles: any[] = [];
  countries: any;
  callingCode: any;
  selectedManagers: any = [];
  isAdmin = false; 
  showClients = false;
  showVendors = false;
  submitted = false;
  isBlockLogon = false;
  isPasswordSetupEmail = true;
  btnImpersonate:boolean=false;
  
  constructor(
    private alertify: AlertifyService,
    private router: Router,
    private dd: DropdownService,
    private user: UserService,
    public auth: AuthService,
    public utils: UtilsService,
    public roleService: RoleService,
    private clientService: ClientService,
    private countriesService: CountriesService,
    private partnerService: PartnerService) { }

  ngOnInit() {
    this.instanceId = this.auth.getInstance();
    this.isAdmin = this.auth.isAdmin();
    this.dd.getStates().subscribe(data => {
      this.states = data;
    }, error => {
       this.alertify.error('Unable to get states');
    });

    this.countriesService.getCountries().subscribe(data => {
      this.countries = data;
    }, error => {
       this.alertify.error('Unable to get countries');
    });

    this.roleService.GetInstanceRoles(this.instanceId).subscribe(data => {
      this.roles = data.map(x => ({ id: x.id, label: x.name }));
    }, error => {
        this.alertify.error('Unable to get roles');
    });

    this.clientService.GetClients(this.auth.getInstance()).subscribe(data => {
      this.clients = data;
    }, err => {
      this.alertify.error('Unable to get clients');
    });

    this.partnerService.GetPartners(this.auth.getInstance(), false).subscribe(data => {
      this.vendors = data;
    }, err => {
      this.alertify.error('Unable to get vendors');
    });

    this.registerForm = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      image: new FormControl(''),
      title: new FormControl(''),
      managerId: new FormControl(null),
      callingCode: new FormControl(''),
      phone: new FormControl(''),
      ableToImpersonate: new FormControl({value: false, disabled: true}),
      country: new FormControl(''),
      instanceId: new FormControl(this.auth.getInstance(), [Validators.required]),
      role: new FormControl('Member', [Validators.required]),
      instanceRoles: new FormArray([]),
      clientId: new FormControl(null),
      vendorId: new FormControl(null),
      slackUsername: new FormControl(''),
      blockLogon: new FormControl(false),
      passwordSetupEmail: new FormControl(true)
    });

    this.registerForm.get('role').valueChanges.subscribe((newRole) => {

      this.registerForm.get('clientId').removeValidators(Validators.required);
      this.registerForm.get('vendorId').removeValidators(Validators.required);
      this.showClients = this.showVendors = false;

      if (newRole === 'Client') {
        this.registerForm.get('clientId').addValidators(Validators.required);
        this.showClients = true;
      } else if (newRole === 'Vendor' || newRole === 'VendorAdmin') {
        this.registerForm.get('vendorId').addValidators(Validators.required);
        this.showVendors = true;
      }
    });

    this.registerForm.controls['blockLogon'].setValue(this.isBlockLogon);
    this.registerForm.controls['passwordSetupEmail'].setValue(this.isPasswordSetupEmail);
  }

  register() {  
    this.submitted = true;
    if (!this.registerForm.valid) {
      let role = this.registerForm.controls['role'].value;
      if (role === 'Client' && this.registerForm.controls['clientId'].status === 'INVALID')
        this.alertify.error("Please select a Client");
      if ( (role === 'Vendor' || role === 'VendorAdmin' )  && this.registerForm.controls['vendorId'].status === 'INVALID')
        this.alertify.error("Please select a Vendor");
      return;
    }

    if (this.registerForm.controls['phone'].value?.length > 0) {
      if (this.registerForm.controls['callingCode'].value === undefined || this.registerForm.controls['callingCode'].value === null || this.registerForm.controls['callingCode'].value?.length <= 0) {
        this.alertify.error("Please select a country calling code");
        return;
      } else {
        this.registerForm.controls['phone'].setValue(+this.registerForm.controls['phone'].value + "");
      }
    } else {
      this.registerForm.controls['phone'].setValue(null);
    }
    if (this.registerForm.controls['callingCode'].value?.length > 0) {
      if (this.registerForm.controls['phone'].value === undefined || this.registerForm.controls['phone'].value === null || this.registerForm.controls['phone'].value?.length <= 0) {
        this.alertify.error("Please add a phone number");
        return;
      }
    } else {
      this.registerForm.controls['callingCode'].setValue(null);
    }

    if (this.selectedRoles.length > 0) {
      this.selectedRoles.forEach(item => {
        (this.registerForm.get('instanceRoles') as FormArray).push(new FormControl(item));
      });
    }

    this.user.Register(this.registerForm.value).subscribe( data => {
      this.model = data;
    }, error => {
       this.alertify.error(error);
    }, () => {
      this.alertify.success('User registered successfully');
      this.router.navigate(['/usermanager']);
    });
  }



  showImpersonateButton(){
    //this button has been disabled for now
    return false;
    var email = this.registerForm.get('email').value;
    const domain = email.substring(email.lastIndexOf('@') + 1);
    if (email !== '' && domain === 'opinionroute.com') {
      return this.auth.getUser().superAdmin;   
    }else{
     return false; 
    }
  }

  getCountryList = (filter) => {
    return this.dd.getCountries();
  }

  getCountryCode = (filter) => {
    return this.dd.getFullCountries();
  }

  handleSelectedRoles(event) {
    this.selectedRoles = event.map(x => x.id);
  }

  setCountry(value) {
    // this.hasChanges=true;
    if (value.length > 0) {
      this.registerForm.controls['country'].setValue(value[0].value);
    }
    else {
      this.registerForm['country'].setValue("");
    }
  }

  setCallingCode(selected) {
    if (selected.length > 0) {
      let country = this.countries.find(x => x.iso3 === selected[0].value);
      this.registerForm.controls['callingCode'].setValue(country.callingCode);
    }
    else {
      this.registerForm.controls['callingCode'].setValue("");
    }
  }

  
  setManager(value) {
    if (value.length > 0) {
      this.registerForm.controls['managerId'].setValue(value[0].id);
    } else {
      this.registerForm.controls['managerId'].setValue(null);
    }  }

  getUserList = () => {
    return this.user.GetUsers()
  }

  blockLogon(event) {
    this.isBlockLogon = event;
    this.isPasswordSetupEmail = false;
    this.registerForm.controls['passwordSetupEmail'].setValue(this.isPasswordSetupEmail);
  }
  passwordSetupEmail(event) {
    this.isPasswordSetupEmail = event;
  }
}
