<div class="row">
  <div class="col-6">
    <p class="text-secondary mb-1">NAV OQ's </p>
    <div class="input-group">
      <input type="number" class="form-control form-control-sm" [(ngModel)]="settingsModel.navOQ">
      <input type="hidden" [(ngModel)]="settingsModel.instanceId">
    </div>
  </div>
  <div class="col-6">
    <p class="text-secondary mb-1">Dropout Rate </p>
    <div class="input-group">
      <input type="number"  class="form-control form-control-sm" [(ngModel)]="settingsModel.dropoutRate">
    </div>
  </div>
  <div class="col-6">
    <p class="text-secondary mb-1">QC Rate Consumer </p>
    <div class="input-group">
      <input type="number" class="form-control form-control-sm" [(ngModel)]="settingsModel.qcRateConsumer">
    </div>
  </div>
  <div class="col-6">
    <p class="text-secondary mb-1">QC Rate Health Care </p>
    <div class="input-group">
      <input type="number" class="form-control form-control-sm" [(ngModel)]="settingsModel.qcRateHealthCare">
    </div>
  </div>
  <div class="col-6">
    <p class="text-secondary mb-1">QC Rate B2B </p>
    <div class="input-group">
      <input type="number" class="form-control form-control-sm" [(ngModel)]="settingsModel.qcRateB2B">
    </div>
  </div>
  <div class="col-6">
    <p class="text-secondary mb-1">Pace Metric </p>
    <div class="input-group">
      <input type="number" class="form-control form-control-sm" [(ngModel)]="settingsModel.paceMetric">
    </div>
  </div>
  <div class="col-6">
    <p class="text-secondary mb-1">Deadline Remaining Days </p>
    <div class="input-group">
      <input type="number" class="form-control form-control-sm" [(ngModel)]="settingsModel.deadlineRemainingDays">
    </div>
  </div>
</div>
<hr>
<div class="row float-right">
  <div class="col">
    <button type="button" class="btn or-primary-bg text-white" (click)="saveSettings()"> Save</button>
  </div>
</div>


