import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { AlertifyService, User, DropdownService, UserService, AuthService, UtilsService, Client, ClientService, RoleService, PartnerService, Partner, CountriesService } from 'core';
import { forkJoin, map } from 'rxjs';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  instanceId = '';
  model: User;
  editForm: FormGroup;
  states: any[];
  emailFocus = false;
  country: any;
  callingCode: any;
  countryList: any;
  submitted = false;

  headers = ['Roles', 'Added Roles'];
  roles: any[] = [];
  selectedRoles: any[] = [];
  isSuperAdmin = false;
  showClients = false;
  showVendors = false;
  clients: Client[] = [];
  vendors: Partner[] = [];
  countries: any;
  role: any;
  selectedManagers: any = null;

  isBlockLogon = false;

  constructor(
    private alertify: AlertifyService,
    private router: Router,
    private dd: DropdownService,
    public roleService: RoleService,
    public user: UserService,
    public auth: AuthService,
    private clientService: ClientService,
    public utils: UtilsService,
    private activatedRoute: ActivatedRoute,
    private countriesService: CountriesService,
    private partnerService: PartnerService) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.role = params['role'];
    });
    this.isSuperAdmin = this.auth.getUser().superAdmin;
    this.instanceId = this.auth.getInstance();
    this.model = this.user.getEditUser();

    if (this.model.address.country) {
      this.countryList = [{ name: this.model.address.country, value: this.model.address.country }]
    }
    else {
      this.countryList = ""
    }

    if (this.model.managerId) {
      this.selectedManagers = [{ name: this.model.managerName, id: this.model.managerId }]
    }


    this.dd.getStates().subscribe(data => {
      this.states = data;
    }, error => {
      this.alertify.error('Unable to get states');
    });

    this.countriesService.getCountries().subscribe(data => {
      this.countries = data;
      let country = data.find(x => x.callingCode === this.model.callingCode);
      if (country) {
        if (country.callingCode == '001') {
          country.display = 'USA/Canada (+1)';
        }
        this.callingCode = [{ name: country?.display, value: country?.iso3 }];
      }
      else {
        this.callingCode = [];
      }
    }, error => {
      this.alertify.error('Unable to get countries');
    });

    forkJoin([this.roleService.GetInstanceRoles(this.instanceId), this.user.GetAllUserRoles(this.model.id)]).subscribe(results => {
      this.roles = results[0].map(x => ({ id: x.id, label: x.name }));
      this.selectedRoles = results[1].map(x => ({ id: x.instanceRoleId, label: x.role }));
    }, error => {
      this.alertify.error('Unable to get roles');
    });

    this.clientService.GetClients(this.auth.getInstance()).subscribe(data => {
      this.clients = data;
    }, err => {
      this.alertify.error('Unable to get clients');
    });

    this.partnerService.GetPartners(this.auth.getInstance(), false).subscribe(data => {
      this.vendors = data;
    }, err => {
      this.alertify.error('Unable to get Vendors');
    });

    let role = 'Member';
    if (this.model.roles.indexOf('Admin') > -1) {
      role = "Admin";
    } else if (this.model.roles.includes('Client')) {
      role = 'Client';
    } else if (this.model.roles.includes('VendorAdmin')) {
      role = 'VendorAdmin';
    } else if (this.model.roles.includes('Vendor')) {
      role = 'Vendor';
    }


    this.editForm = new FormGroup({
      id: new FormControl(this.model.id, [Validators.required]),
      firstName: new FormControl(this.model.firstName, [Validators.required]),
      lastName: new FormControl(this.model.lastName, [Validators.required]),
      email: new FormControl(this.model.email),
      newemail: new FormControl(this.model.email),
      title: new FormControl(this.model.title),
      managerId: new FormControl(this.model.managerId),
      billing: new FormControl(this.model.billing),
      image: new FormControl(this.model.image),
      callingCode: new FormControl(this.model.callingCode),
      phone: new FormControl(this.model.phone),
      ableToImpersonate: new FormControl(this.model.ableToImpersonate),
      country: new FormControl(this.model.address.country),
      instanceId: new FormControl(this.model.instanceId, [Validators.required]),
      role: new FormControl(role, [Validators.required]),
      instanceRoles: new FormArray([]),
      clientId: new FormControl(this.model.clientId || ''),
      vendorId: new FormControl(this.model.vendorId || ''),
      blockLogon: new FormControl(this.model.blockLogon),
      slackUsername: new FormControl(this.model.social.slackUsername)
    });

    this.showClients = (role === 'Client');
    this.showVendors = (role === 'Vendor' || role === 'VendorAdmin');


    const domain = this.model.email.substring(this.model.email.lastIndexOf('@') + 1);
    if (this.model.email !== '' && domain === 'opinionroute.com') {
      this.editForm.get('ableToImpersonate')?.enable();
    } else {
      this.editForm.get('ableToImpersonate')?.disable();
    }


    this.editForm.get('role').valueChanges.subscribe((newRole) => {
      this.editForm.get('clientId').removeValidators(Validators.required);
      this.editForm.get('vendorId').removeValidators(Validators.required);
      this.showClients = this.showVendors = false;

      if (newRole === 'Client') {
        this.editForm.get('clientId').addValidators(Validators.required);
        this.showClients = true;
      } else if (newRole === 'Vendor' || newRole === 'VendorAdmin') {
        this.editForm.get('vendorId').addValidators(Validators.required);
        this.showVendors = true;
      }

    });
  }
  cancel() {
    this.router.navigate(['/usermanager'], { queryParams: { role: this.role } });
  }

  onBlurEmail() {   
    this.emailFocus = false;
    const email = this.editForm.get('email');
    const trimmedEmail = email?.value.trim();  // Trim the value
    email?.setValue(trimmedEmail);

    const newemail = this.editForm.get('newemail');
    const trimmednewemail = newemail?.value.trim();  // Trim the value
    newemail?.setValue(trimmednewemail);
  }
  edit() {
    let role = this.role;
    this.submitted = true;

    if (!this.editForm.valid) {
      return;
    }

    let selectedRoles = this.selectedRoles.map(x => x.id);
    if (selectedRoles.length > 0) {
      selectedRoles.forEach(item => {
        (this.editForm.get('instanceRoles') as FormArray).push(new FormControl(item));
      });
    }

    if (this.editForm.controls['phone'].value?.length > 0) {
      if (this.editForm.controls['callingCode'].value === undefined || this.editForm.controls['callingCode'].value === null || this.editForm.controls['callingCode'].value?.length <= 0) {
        this.alertify.error("Please select a country calling code!");
        return;
      } else {
        this.editForm.controls['phone'].setValue(+this.editForm.controls['phone'].value + "");
      }
    } else {
      this.editForm.controls['phone'].setValue(null);
    }
    if (this.editForm.controls['callingCode'].value?.length > 0) {
      if (this.editForm.controls['phone'].value === undefined || this.editForm.controls['phone'].value === null || this.editForm.controls['phone'].value?.length <= 0) {
        this.alertify.error("Please add a phone number");
        return;
      }
    } else {
      this.editForm.controls['callingCode'].setValue(null);
    }   

    this.user.EditUser(this.editForm.value).subscribe(data => {
      // All good
      // Send back to user manger and alertify
    }, error => {
      if (error == 'EMAIL_EXISTS') {
        this.alertify.error('User already exists please try again');
      }
      else {
        this.alertify.error('Unable to update ' + this.model.name);
      }

    }, () => {
      // 'onCompleted' callback.
      // No errors, route to new page here
      this.alertify.success(this.model.name + ' has been updated successfully');
      //this.router.navigate(['/usermanager']);
      this.router.navigate(['/usermanager'], { queryParams: { role: role } });
    });
  }

  getCountryList = (filter) => {
    return this.dd.getCountries();
  }

  getCountryCode = (filter) => {
    return this.dd.getFullCountries();
  }

  setCountry(value) {
    if (value.length > 0) {
      this.editForm.controls['country'].setValue(value[0].value);
    }
    else {
      this.editForm.controls['country'].setValue("");
    }
  }

  setCallingCode(selected) {
    if (selected.length > 0) {
      let country = this.countries.find(x => x.iso3 === selected[0].value);
      this.editForm.controls['callingCode'].setValue(country.callingCode);
    }
    else {
      this.editForm.controls['callingCode'].setValue("");
    }
  }

  setManager(value) {
    if (value.length > 0) {
      this.editForm.controls['managerId'].setValue(value[0].id);
    } else {
      this.editForm.controls['managerId'].setValue(null);
    }
  }

  getUserList = () => {

    // remove the id of the current account
    var users = this.user.GetUsers().pipe(
      map(objects => objects.filter(obj => obj.id !== this.model.id))
    );
    return users;
  }

  handleSelectedRoles(event) {
    this.selectedRoles = event;
  }

  sendPasswordReset() {
    var email = this.model.email;
    var sender = "admin";
    this.auth.forgot({ email, sender }).subscribe(result => {
      this.alertify.success("Reset Password email successfully sent");
    }, error => {
      this.alertify.error("Reset Password email failed to send");
    });
  }

  blockLogon(event) {
    this.isBlockLogon = event;
  }
  showImpersonateButton() {
    return false;
  }

}
