<div class="card">
  <div class="card-header text-white  or-primary-bg">
    <p class="h4 inline float-left"><em class="fa fa-key fa-sm"></em> Manage API Keys</p>
    <div class="float-right">
      <div class="form-inline form">
        <button type="button" class="btn btn-sm btn-outline-light inline" *ngIf="this.auth.getUser().superAdmin" (click)="openModal(modal)" routerLinkActive="active"><em class="fa fa-plus-circle"></em> Add new license</button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="mt-0">
      <or-list [items]="publickeys" [layout]="layout" [actions]="this.auth.getUser().superAdmin ? actions : []" (action)="handleAction($event)"></or-list>
    </div>
  </div>
</div>

<!-- MODAL ADD PUBLIC KEY -->
<ng-template #modal>
  <div class="modal-header">
    <p class="h4 position-absolute"><em class="fa fa-key fa-sm"></em> API Key</p>
    <button type="button" class="close pull-right text-white" aria-label="Close" (click)="resetForm(); modalOpenRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="editForm" (ngSubmit)="submitForm()" novalidate>
      <input type="hidden" formControlName="instanceId" id="instanceId">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div class="row mb-3">
                <div class="col">
                  <label for="name" class="required">Name</label>
                  <div class="input-group">
                    <input type="text" class="form-control" formControlName="name" id="name" [ngClass]="submitted && utils.showCssValidField(editForm, 'name')">
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <label for="name" class="required">Product</label>
                  <div class="input-group">
                    <select class="custom-select" formControlName="scope" id="scope" #scope [ngClass]="submitted && utils.showCssValidField(editForm, 'scope')">
                      <option value="cleanid">CleanID</option>
                      <option value="responseid">ResponseID</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <label for="apiKey">Public API Key</label>
                  <div class="input-group">
                    <input type="text" class="form-control" [readonly]="editForm.value.id !== ''" formControlName="apiKey" id="apiKey" placeholder="Leave blank to auto generate">
                  </div>
                </div>
              </div>
              <hr class="mt-4 mb-1">
              <p class="font-weight-bold">License Information</p>
              <div class="row mb-3">
                <div class="col">
                  <label for="startDate" class="required">Start Date</label>
                  <input type="text" autocomplete="off" class="form-control" formControlName="startDate" id="startDate" bsDatepicker readonly [ngClass]="submitted && utils.showCssValidField(editForm, 'startDate')"
                  [bsConfig]="{ dateInputFormat: 'MMM Do, YYYY', isAnimated: true, showWeekNumbers:false, customTodayClass: 'todays-date' }">
                </div>
                <div class="col">
                  <label for="endDate" class="required">End Date</label>
                  <input type="text"  autocomplete="off" class="form-control" formControlName="endDate" id="endDate" bsDatepicker readonly [ngClass]="submitted && utils.showCssValidField(editForm, 'endDate')"
                  [bsConfig]="{ dateInputFormat: 'MMM Do, YYYY', isAnimated: true, showWeekNumbers:false, customTodayClass: 'todays-date' }">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <label for="maxHits" class="required">Transactions Purchased</label>
                  <div class="input-group">
                    <input type="number" class="form-control" oninput="validity.valid||(value='');" min="0" [ngClass]="submitted && utils.showCssValidField(editForm, 'maxHits')"
                    formControlName="maxHits" id="maxHits" maxlength="7">
                  </div>
                </div>
                <div class="col">
                  <label for="enable">Enable API Key</label>
                  <div class="input-group">
                    <or-toggle-button [id]="'enable'" formControlName="enabled" msgON="YES" msgOFF="NO" class="light"></or-toggle-button>
                  </div>
                </div>
              </div>
              <div class="row mb-3" *ngIf="scope.value == 'cleanid' && this.auth.getUser().superAdmin">
                <div class="col-6">
                  <label for="transactionOffset">Transactions <strong>Offset</strong></label>
                  <div class="input-group">
                    <input type="text" maxlength="9" currencyMask [options]="{ precision: 0, prefix: '', align: 'left', thousands: '', decimal: '' }" formControlName="transactionOffset" class="form-control">
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <label for="urlWhitelist">URL White List</label>
                  <div class="input-group">
                    <input type="text" class="form-control" formControlName="urlWhitelist" id="urlWhitelist" placeholder="">
                  </div>
                </div>
              </div>

              <div *ngIf="errorMsg" class="alert alert-danger py-2" role="alert">{{ errorMsg }}</div>

            </div>
          </div>
        </div>
        <div class="card-footer text-right">
          <button type="submit" class="btn btn-sm or-primary-bg text-white"><em class="fa fa-save"></em> Save API Key</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #modalConfirm>
  <div class="modal-header">
    <p class="h5">Confirm Delete</p>
  </div>
  <div class="modal-body text-center">
    <p class="h6">Do you wish to continue?</p>
    <button type="button" class="btn btn-outline-secondary m-2" (click)="confirmDelete()">Yes</button>
    <button type="button" class="btn btn-secondary" (click)="modalOpenConfirmRef.hide()">No</button>
  </div>
</ng-template>
