import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertifyService, ApiKeyService, AuthService, UtilsService, PublicKey } from 'core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-key-manager',
  templateUrl: './key-manager.component.html',
  styleUrls: ['./key-manager.component.scss']
})
export class KeyManagerComponent implements OnInit {

  tab = 'api-keys';

  instanceId: string;
  submitted = false;

  @ViewChild('modal')
  modalRef: BsModalRef;
  modalOpenRef;

  @ViewChild('modalConfirm')
  modalRefConfirm: TemplateRef<any>;
  modalOpenConfirmRef;

  publickeys: any[];
  model = {} as PublicKey;
  editForm: FormGroup;
  errorMsg: string = null;

  layout = [
    {label: 'API Key', id: 'apiKey'},
    {label: 'Name', id: 'name'},
    {label: 'Product', id: 'scope', sortable:true, filterable: true},
    {label: 'Start Date', id: 'startDate', type: 'date', sortable:true},
    {label: 'End Date', id: 'endDate', type: 'date', sortable:true},
    {label: 'Max Transactions', id: 'maxHits', sortable:true, type: 'number'},
    {label: 'Transactions', id: 'hits', sortable:true, type: 'number'},
    {label: 'Remaining', id: 'hitsRemaining', sortable:true, type: 'number'},
    {label: 'Enabled', id: 'enabled', type: 'toggle'},
    {label: 'Actions', type: 'actions-inline'}
  ];
  actions = [
    {label: 'Edit', type: 'edit', icon: 'pen-square'},
    {label: 'Delete', type: 'delete', icon: 'trash-alt'}
  ];

  constructor(
    private modalService: BsModalService,
    private router: Router,
    private alertify: AlertifyService,
    private apiKeyService: ApiKeyService,
    private route: ActivatedRoute,
    public auth: AuthService,
    public utils: UtilsService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const id: string = params.get('id');
      if (id) {
        this.loadData(id);
      }
    });

    this.instanceId = this.auth.getInstance();
    this.resetForm();
    this.getPublicKeys();
  }

  public openModal(template: any) { this.modalOpenRef = this.modalService.show(template, {ignoreBackdropClick: true, keyboard: false }); }

  confirmDelete() {
    this.apiKeyService.deletePublicKey(this.model.id).subscribe( data => {
      this.model = data;
    }, error => {
       this.alertify.error('Unable to delete API Key');
    }, () => {
      this.alertify.success('API Key deleted successfully');
      this.modalOpenConfirmRef.hide();
      this.getPublicKeys();
    });
  }

  handleAction(action) {
    const dataEdit: any = action.row;
    this.model = dataEdit;

    if (action.type === 'edit') {
      this.bindForm();
      this.openModal(this.modalRef);
    }
    if (action.type === 'delete') { this.modalOpenConfirmRef = this.modalService.show(this.modalRefConfirm); }
  }

  submitForm() {
    this.submitted = true;

    if (!this.editForm.valid) return;

    if (this.editForm.value.scope !== 'cleanid') {
      this.editForm.value.transactionOffset = 0;
    }

    if (this.editForm.value.scope == '' || this.editForm.value.scope == null) {
      this.alertify.error('Please select a Product');
      return;
    }

    if (!this.editForm.value.id) {
      this.apiKeyService.addPublicKey(this.editForm.value).subscribe( data => {
        this.model = data;
      }, error => {
         this.errorMsg = error;
         this.alertify.error('Unable to add API Key');
      }, () => {
        this.alertify.success('API Key added successfully');
        this.resetForm();
        this.modalOpenRef.hide();
        this.getPublicKeys();
      });
    } else {
      this.apiKeyService.editPublicKey(this.editForm.value).subscribe( data => {
        this.model = data;
      }, error => {
         this.errorMsg = error;
         this.alertify.error('Unable to edit API Key');
      }, () => {
        this.alertify.success('API Key edited successfully');
        this.resetForm();
        this.modalOpenRef.hide();
        this.getPublicKeys();
      });
    }
  }

  loadData(id: any) {
    this.apiKeyService.getPublicKey(id).subscribe( data => {
      this.model = data;
    }, error => {
       this.alertify.error('Unable to fetch API Keys');
    }, () => {
      this.bindForm();
      this.openModal(this.modalRef);
    });
  }

  getPublicKeys() {
    this.apiKeyService.getPublicKeys(this.instanceId).subscribe( data => {
      data.forEach(e => {
        e.hitsRemaining = (e.maxHits == null) ? null : e.maxHits - e.hits;
      });
      this.publickeys = data;
    }, error => {
       this.alertify.error('Unable to fetch API Keys');
    }, () => {
      // 'onCompleted' callback.
    });
  }

  bindForm() {
    if (this.model) {
      this.editForm = new FormGroup({
        id: new FormControl(this.model.id),
        instanceId: new FormControl(this.model.instanceId),
        apiKey: new FormControl(this.model.apiKey),
        name: new FormControl(this.model.name, Validators.required),
        startDate: new FormControl(new Date(this.model.startDate), Validators.required),
        endDate: new FormControl(new Date(this.model.endDate), Validators.required),
        hits: new FormControl(this.model.hits, Validators.maxLength(7)),
        maxHits: new FormControl(this.model.maxHits, [Validators.required, Validators.maxLength(7)]),
        transactionOffset: new FormControl(this.model.transactionOffset, [Validators.maxLength(7)]),
        urlWhitelist: new FormControl(this.model.urlWhitelist),
        enabled: new FormControl(this.model.enabled),
        scope: new FormControl(this.model.scope)
      });
    }
  }

  resetForm() {
    this.errorMsg = null;
    this.editForm = new FormGroup({
      id: new FormControl(''),
      apiKey: new FormControl(''),
      instanceId: new FormControl(this.instanceId),
      name: new FormControl('', Validators.required),
      startDate: new FormControl(new Date(), Validators.required),
      endDate: new FormControl(new Date(), Validators.required),
      hits: new FormControl(0, [Validators.maxLength(7)]),
      maxHits: new FormControl(0, [Validators.required, Validators.maxLength(7)]),
      transactionOffset: new FormControl(0, [Validators.maxLength(7)]),
      urlWhitelist: new FormControl(''),
      enabled: new FormControl(true),
      scope: new FormControl('')
    });
  }
}
