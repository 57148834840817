import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ComponentsModule, HelpersModule } from 'core';
import { RouterModule } from '@angular/router';

import { UserManagerComponent } from './user-manager.component';
import { UsersComponent } from './users/users.component';
import { UserAddComponent } from './users/user-add/user-add.component';
import { UserEditComponent } from './users/user-edit/user-edit.component';

import { RolesComponent } from './roles/roles.component';
import { RoleEditComponent } from './roles/role-edit/role-edit.component';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    HelpersModule,
    RouterModule,
    ReactiveFormsModule
  ],
  declarations: [
    UserManagerComponent,
    UsersComponent,
    UserAddComponent,
    UserEditComponent,
    RolesComponent,
    RoleEditComponent
  ]
})
export class UserManagerModule { }
