import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AuthService, DropdownService, AlertifyService, ProjectService, UtilsService, RoleService, SecurityKeyService } from 'core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';
import { of } from 'rxjs';

@Component({
  selector: 'app-project-types',
  templateUrl: './project-types.component.html',
  styleUrls: ['./project-types.component.scss']
})
export class ProjectTypesComponent implements OnInit {
  instanceId = '';
  projectTypes = [];
  submitted = false;
  selectedType = null;
  deleteType = null;
  openModalRef: any;
  types = {};
  healthConfigs = [];
  secretKeys = []
  tab = 'main-details';
  cloneid: string;

  @ViewChild('modalConfirm')
  modalRefConfirm: TemplateRef<any>;

  @Output() onselect = new EventEmitter();

  roles: any = [];

  layout = [
    { label: 'Name', id: 'name', sortable: true },
    { label: 'Description', id: 'description' },
    { label: 'Type', id: 'type' },
    { label: 'Prefix', id: 'prefix' },
    { label: 'Actions', type: 'actions-dropdown' }
  ];
  actions = [
    { label: 'Edit', type: 'edit' },
    { label: 'Delete', type: 'delete' },
    { label: 'Clone', type: 'clone' }
  ];

  constructor(
    public auth: AuthService,
    private dd: DropdownService,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    public utils: UtilsService,
    private projectService: ProjectService,
    private securityKeyService: SecurityKeyService,
    private roleService: RoleService) { }

  ngOnInit(): void {
    this.instanceId = this.auth.getInstance();
    if (!this.instanceId) {
      this.alertify.warning('Please select an Instance');
      return;
    }

    this.projectService.GetAllProjectHealthConfigs(this.instanceId).subscribe(data => {
      this.healthConfigs = data;
      for (let i = 0; i < this.healthConfigs.length; i++) {
        this.healthConfigs[i].settings = JSON.parse(this.healthConfigs[i].settings)
      }
    }, error => {
      this.alertify.error('Unable to fetch project health configs');
    });

    this.getSecretKeys()

    this.roleService.GetInstanceRoles(this.instanceId, true).subscribe((data) => {
      this.roles = data;
      this.getProjectTypes();
    });
  }

  getSecretKeys() {
    this.securityKeyService.getSecurityKeys(this.instanceId).subscribe(data => {
      this.secretKeys = data;
    }, error => {
      this.alertify.error('Unable to fetch secret keys configs');
    });
  }

  addProjectType() {
    // TO DO: OTHER DEFAULTS
    const newType = {
      id: null,
      name: '',
      type: 'survey',
      sections: [],
      stages: []
    };
    this.selectedType = newType;
    this.onselect.emit(this.selectedType);
  }

  saveProjectType() {
    if (this.cloneid) {
      this.selectedType.actionType="clone";
      this.selectedType.id = null;
    }
    this.selectedType.sections = this.removeDeletedSections(this.selectedType.sections);
    this.projectService.SaveProjectType(this.instanceId, this.selectedType).subscribe((responseList) => {
      this.selectedType = null;
      this.onselect.emit(this.selectedType)
      this.getProjectTypes();
      this.alertify.success('Project type saved succesfully.');
    }, error => {
      this.alertify.error('There was an error saving this project type.');
    }, () => {
      this.getProjectTypes();
    });
  }
  removeDeletedSections(sections) { return sections.filter(ele => ele.isDeleted !== true); }

  getProjectTypes() {
    this.projectService.GetProjectTypes(this.instanceId, false, true).subscribe((data) => {
      if (data != null) {
        this.projectTypes = data.map(item => {
          item?.sections.forEach(section => {
            section.open = (section?.cards.length > 0);

            // Default roles to be empty Internal User Type
            section.roles = section.roles ?? [];
            if (section.roles.length === 0)
              section.roles.push(this.roles.find(x => x.id === 'Internal'));

            section.roles.forEach(role => {
              const mainRole = this.roles.find(e => e.id === role.id);
              if (mainRole != null) role.name = mainRole.name;
            });
            section?.cards.forEach(card => {
              card.roles = card.roles ?? [];
              if (card.roles.length === 0)
                card.roles.push(this.roles.find(x => x.id === 'Internal'));

              card.roles.forEach(role => {
                const mainRole = this.roles.find(e => e.id === role.id)
                if (mainRole != null) role.name = role.name = mainRole.name;
              });
            });
          });
          return item;
        });
      }
    }, error => {
      this.alertify.error('There was an error getting project types');
    });
  }

  handleAction(action) {
    if (action.type === 'edit') {
      this.selectedType = action.row;
      this.onselect.emit(this.selectedType);
    }
    if (action.type === 'delete') {
      this.deleteType = action.row;
      this.openModalRef = this.modalService.show(this.modalRefConfirm);
    }
    if (action.type === 'clone') {
      this.cloneid = "Clone";
      this.selectedType = action.row;
    }
  }

  confirmDelete() {
    this.projectService.DeleteProjectType(this.deleteType.id).subscribe(data => {
      this.alertify.success(this.deleteType.name + ' deleted successfully');
      this.projectTypes = this.projectTypes.filter(e => e.id !== this.deleteType.id)
    }, error => {
      this.alertify.error('Unable to delete project type');
    }, () => {
      this.openModalRef.hide();
    });
  }

  cancelEdit() {
    this.selectedType = null;
    this.onselect.emit(this.selectedType)
    this.getProjectTypes();
  }
}
