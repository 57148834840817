  <form [formGroup]="questionForm" (ngSubmit)="questionAdd()" novalidate>
    <div class="card m-3">
      <div class="card-header text-white or-primary-bg">
        <p class="h4 inline"><em class="fa fa-project-diagram fa-sm"></em> New Quota Question</p>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <label class="required" for="question" class="required">Question</label>
            <input type="text" class="form-control" formControlName="question" id="question" [ngClass]="submitted && utils.showCssValidField(questionForm, 'question')" />
            <div class="invalid-feedback">Please enter question.</div>
          </div>
          <div class="col-md-6 col-sm-12">
            <label class="required" for="category">Category</label>
            <select class="custom-select" formControlName="category" id="category">
              <option *ngFor="let category of categories" [value]="category.value">{{category.name}}</option>
          </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <label class="required" for="type">Type</label>
              <select (change)="changeType($event)" class="custom-select" formControlName="type" id="type">
                <option *ngFor="let type of types" [value]="type.value">{{type.name}}</option>
            </select>
          </div>
          <div class="col-md-6 col-sm-12" *ngIf="this.auth.getUser().superAdmin">
            <label for="description">Description</label>
            <input type="text" class="form-control" formControlName="description" id="description" [ngClass]="submitted && utils.showCssValidField(questionForm, 'description')" />
            <div class="invalid-feedback">Please enter description.</div>
          </div>
        </div>
        <hr class="my-5">
        <div class="row">
          <div class="col-md-6 col-sm-12" *ngIf="showOptions">
            <h5>Options</h5>
            <button type="button" class="mb-3" (click)="addOptionClick()">Add Option</button>

            <div formArrayName="options" *ngFor="let option of questionForm.get('options')['controls']; let i = index">
              <div [formGroupName]="i" class="row my-2">

                <ng-container *ngIf="selectedType === 'HouseholdIncome'">
                  <div class="col-md-3 col-sm-12">
                    <label class="required" for="val1">Value</label>
                    <input type="text" [required]="true" class="form-control" mask="9999999" [patterns]="integerPattern" formControlName="value" id="value" [ngClass]="submitted && utils.showCssValidField(questionForm.get('options').get(i.toString()), 'value')" />
                  </div>
                </ng-container>

                <ng-container *ngIf="selectedType !== 'HouseholdIncome'">
                  <div class="col-md-3 col-sm-12">
                    <label class="required" for="val1">Value</label>
                    <input type="text" [required]="true" class="form-control" formControlName="value" id="value" [ngClass]="submitted && utils.showCssValidField(questionForm.get('options').get(i.toString()), 'value')" />
                  </div>
                </ng-container>

                <div class="col-md-4 col-sm-12">
                  <label class="required" for="label">Label</label>
                  <input type="text" [required]="true" class="form-control" formControlName="label" id="label"  [ngClass]="submitted && utils.showCssValidField(questionForm.get('options').get(i.toString()), 'label')" />
                </div>

                <ng-container *ngIf="selectedType == 'Gender' || selectedType == 'Ethnicity'">
                  <div class="col-md-2 col-sm-12">
                    <label class="required" for="default">Default %</label>
                    <input type="text" [required]="true" suffix="%" mask="percent" class="form-control" formControlName="default" id="default" [ngClass]="submitted && utils.showCssValidField(questionForm.get('options').get(i.toString()), 'default')" />
                  </div>
                </ng-container>

                <div class="col-md-1 col-sm-12 mt-2">
                  <button class="btn btn-danger opacity-70 btn-sm mt-4" (click)="removeOptionClick(i)" type="button">x</button>
                </div>

              </div>
            </div>
          </div>

          <div class="col-md-6 col-sm-12" *ngIf="showDefaults">
            <h5>Defaults</h5>
            <button type="button" class="mb-3" *ngIf="!showLocations" (click)="addOptionDefaultClick()">Add Default</button>

            <div formArrayName="defaults" *ngFor="let option of questionForm.get('defaults')['controls']; let i = index">
              <div [formGroupName]="i" class="row my-2">

                <ng-container *ngIf="selectedType == 'Age' || selectedType == 'HouseholdIncome'">
                  <div class="col-md-3 col-sm-12">
                    <label class="required" for="min">Min Value</label>
                    <input type="text" [required]="true" class="form-control" mask="9999999" [patterns]="integerPattern" formControlName="min" id="min" [ngClass]="submitted && utils.showCssValidField(questionForm.get('defaults').get(i.toString()), 'min')" />
                  </div>
                  <div class="col-md-3 col-sm-12">
                    <label class="required" for="max">Max Value</label>
                    <input type="text" [required]="true" class="form-control" mask="9999999" [patterns]="integerPattern" formControlName="max" id="max" [ngClass]="submitted && utils.showCssValidField(questionForm.get('defaults').get(i.toString()), 'max')" />
                  </div>
                  <div class="col-md-2 col-sm-12">
                    <label class="required" for="percent">Percent (%)</label>
                    <input type="text" [required]="true" suffix="%" mask="percent" class="form-control" formControlName="percent" id="percent" [ngClass]="submitted && utils.showCssValidField(questionForm.get('defaults').get(i.toString()), 'percent')" />
                  </div>
                </ng-container>

                <!-- STATE, REGION OR DIVISION -->
                <ng-container *ngIf="showLocations">
                  <div class="col-md-4 col-sm-12">
                    <label for="name">&nbsp;</label>
                    <input type="text" disabled class="form-control" formControlName="value" id="value" />
                  </div>
                  <div class="col-md-2 col-sm-12">
                    <label class="required" for="default">Percent (%)</label>
                    <input type="text" [required]="true" suffix="%" mask="percent" class="form-control" formControlName="percent" id="percent" [ngClass]="submitted && utils.showCssValidField(questionForm.get('defaults').get(i.toString()), 'percent')" />
                  </div>
                </ng-container>

                <div class="col-md-1 col-sm-12 mt-2" *ngIf="!showLocations">
                  <button class="btn btn-danger opacity-70 btn-sm mt-4" (click)="removeOptionDefaultClick(i)" type="button">x</button>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="card-footer text-right mt-5">
        <div class="btn btn-light mr-2" [routerLink]="['/quota-questions']"  type="button">Cancel</div>
        <button class="btn or-primary-bg text-white" type="submit">Save</button>
      </div>
    </div>

  </form>

