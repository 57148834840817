import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlertifyService, AuthService, SiteSection, UtilsService, PartnerService} from 'core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { QuotaService } from 'projects/core/src/services/quota.service';
import { VendorQuotaMap } from 'projects/admin/src/app/_models/vendorQuotaMapping';
import { forkJoin } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-vendor-mapping-setup',
  templateUrl: './vendor-mapping-setup.component.html',
  styleUrls: ['./vendor-mapping-setup.component.scss']
})
export class VendorMappingSetupComponent implements OnInit {
  vendorId;
  _editSection;
  _mode;
  submitted = false;
  sections: any[];
  instanceId = '';
  editForm: FormGroup;
  model: SiteSection;
  sectionTypes: any[] = [];
  instanceRoles: any[] = [];
  projectTypes = [];
  roles: any[] = [];
  selectedRoles: any[] = [];
  questionTypes = [ 'Gender', 'Age', 'Division', 'Income', 'Ethnicity', 'Hispanic'] //'State',
  addedQuestions = [];
  questionList = [];
  questionSelect = null;
  optionSelect = []
  triedSubmitting = false;
  groupCodeDict = [];
  quotaCodeDict = [];

  partner = null;

  emptyCodeExists = false;
  duplicateCodeExists = false;
  duplicateQuotaCodeExists = false;
  vendorQuotaMap: VendorQuotaMap;

  @Output() saveevent = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    private alertify: AlertifyService,
    public utils: UtilsService,
    public auth: AuthService,
    private quotaService: QuotaService,
    private partnerService: PartnerService) { }

  ngOnInit(): void {
    this.vendorQuotaMap = {
      vendorId: this.vendorId,
      quotaGroupMappings: []
    }
    this.setup();
  }

  setup() { this.getQuestions(); }

  addQuestion() {
    this.addedQuestions.push({type: this.questionSelect, code: null, mappings: []});
    this.questionList[this.questionSelect].setup = true;
    this.vendorQuotaMap.quotaGroupMappings.unshift( {
      type: this.questionSelect,
      code: null,
      quotaMappings: [],
      isDeleted: false
    })
    this.checkForOverlaps();

    this.questionSelect = null;
  }

  addMapping(question) {
    if (this.optionSelect[question.type] != null) {
      if (this.optionSelect[question.type] == 'all') {
        this.questionList[question.type].options.forEach(option => {
          if (question.type == 'Income') question.quotaMappings.push({quotaId: option.id, quotaString: option.label, code: null, isDeleted: false});
          else question.quotaMappings.push({quotaId: option.id, quotaString: option.value, code: null, isDeleted: false});
        });
      } else {
        var option = this.questionList[question.type].options.filter(x => x.value == this.optionSelect[question.type])[0];
        // question.mappings.push({quota: this.optionSelect[question.type], code:null});
        question.quotaMappings.push({quotaId: option.id, quotaString: option.value, code: null, isDeleted: false});
      }
      this.optionSelect[question.type] = null;
    }
    this.checkForOverlaps();
  }

  getVendorQuotaMapping() {
    this.partnerService.GetPartnerQuotaMapping(this.vendorId).subscribe(vendorMap => {
      if (vendorMap === null) return;

      this.vendorQuotaMap = vendorMap;
      this.vendorQuotaMap.quotaGroupMappings.forEach(group => {
        group.quotaMappings.filter(x=> x.isDeleted == false);
        this.addedQuestions.push({type: group.type, code: group.code, mappings: group.quotaMappings, isDeleted: group.isDeleted});
        if (group.type == 'HouseholdIncome') this.questionList['Income'].setup = true;
        else this.questionList[group.type].setup = true;
      });
      this.checkForOverlaps();
    });
  }

  getQuestions() {
    this.quotaService.ListQuestions().subscribe( data => {
      this.sort(data);
      for (var n = 0; n < this.questionTypes.length; n++) {
        data.forEach(element => {
          if (element.type == 'HouseholdIncome') element.type = 'Income';
          if (element.type == this.questionTypes[n]) {
            element.order = n;
            this.sort(element.options);
            if (element.type == 'Division') this.questionList['Geo/Zip'] = {options: [], order: element.order, setup: false};
            else this.questionList[element.type] = {options: element.options, order: element.order, setup: false};
            this.optionSelect[element.type] = null;
          }
        });
      }
      this.sort(this.questionList);
      this.getVendorQuotaMapping();
    });
  }

  removeMapping(question, m) {
    question.quotaMappings[m].isDeleted = true;
    if (question.quotaMappings[m].id == undefined || question.quotaMappings[m].id == null)
      question.quotaMappings.splice(m, 1);
    this.checkForOverlaps();
  }

  removeQuestion(index, type) {
    this.vendorQuotaMap.quotaGroupMappings[index].isDeleted = true;
    if (type == 'HouseholdIncome') this.questionList['Income'].setup = false;
    else this.questionList[type].setup = false;

    if (this.vendorQuotaMap.quotaGroupMappings[index].id == undefined || this.vendorQuotaMap.quotaGroupMappings[index].id == null)
      this.vendorQuotaMap.quotaGroupMappings.splice(index, 1);
    this.checkForOverlaps();
  }

  sort(array) {
    array.sort((a, b) => {
      let comparison = 0;
      if (a.order > b.order) {
        comparison = 1;
      } else if (a.order < b.order) {
        comparison = -1;
      }
      return comparison;
    });
  }

  checkForOverlaps() {
    this.emptyCodeExists = false;
    this.duplicateCodeExists = false;
    this.duplicateQuotaCodeExists = false;

    var groupDict = [];
    var quotaDict = [];

    this.vendorQuotaMap.quotaGroupMappings.filter(x => x.isDeleted == false).forEach(qg => {
      quotaDict[qg.type] = [];
      if (qg.code == "" || qg.code == null ) {
        if (this.triedSubmitting) this.emptyCodeExists = true;
      } else {
        if (groupDict[qg.code] == null || groupDict[qg.code] == undefined ) groupDict[qg.code] = 1;
        else {
          groupDict[qg.code] += 1;
          this.duplicateCodeExists = true;
        }
      }
      qg.quotaMappings.filter(x => x.isDeleted == false && x.code != null && x.code != "").forEach(q => {
        if (quotaDict[qg.type][q.code] == null || quotaDict[qg.type][q.code] == undefined) {
          quotaDict[qg.type][q.code] = 1;
        } else {
          quotaDict[qg.type][q.code] += 1;
          this.duplicateQuotaCodeExists = true;
        }
      });
    })

    this.groupCodeDict = groupDict;
    this.quotaCodeDict = quotaDict;
  }

  saveMapping() {
    this.triedSubmitting = true;
    this.checkForOverlaps();

    if (!this.emptyCodeExists && !this.duplicateCodeExists && !this.duplicateQuotaCodeExists) {
      var hhi = this.vendorQuotaMap.quotaGroupMappings.filter(x => x.type == 'Income');
      if (hhi.length > 0) {
        hhi[0].type = 'HouseholdIncome';
      }
      this.partnerService.UpdatePartnerQuotaMapping(this.vendorQuotaMap).subscribe(data => {
        if (data != null) {
          this.alertify.success("Vendor Quota Mapping succesfully updated")
          this.saveevent.emit(true)
        }
      }, error => {
        console.log(error)
      },() => {
      });
    };
  }

  copyMappings() : void {
    this.partnerService.copyMappings(this.partner.id, this.partner.linkedPartnerId).subscribe(data => {
      this.vendorQuotaMap = data;
    }, error => {
      console.log(error);
    });
  }
}
