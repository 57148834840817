<div [formGroup]="parentGroup">
  <div class="row">
    <div class="col-md-6 col-sm-12">
      <label for="name" class="required">Name</label>
      <input type="text" class="form-control" formControlName="name" id="name" placeholder="" [ngClass]="submitted && utils.showCssValidField(parentGroup.form, 'name')" />
    </div>
    <div class="col-md-6 col-sm-12">
      <label for="website">Website</label>
      <input type="website" class="form-control" formControlName="website" id="website" placeholder="www.example.com" />
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 col-sm-12">
      <span tooltip="If you would like to restrict vendor users to particular email domains when being added by Vendor Admins">
        <i class="fas fa-info-circle opacity-20 or-secondary"></i>
      </span>
      <label for="userDomain" >Vendor User domain</label>
      <input type="text" class="form-control" formControlName="userDomain" id="userDomain" />
    </div>
    <div class="col-md-6 col-sm-12" *ngIf="!parentGroup.value.isGlobal && partnersGlobal?.length > 0">
      <label for="globalPartner">Global Vendors</label>
      <div *ngIf="hasGlobalPartner">
        <input type="text" class="form-control" [value]="selectedPartner.name" id="globalPartner" readonly />
      </div>
      <div *ngIf="!hasGlobalPartner">
        <div class="justify-content-center or-dropdown">
          <select class="form-control or-dropdown" formControlName="globalPartner" id="globalPartner">
            <option *ngFor="let p of partnersGlobal" [value]="p.value.toLowerCase()">{{p.name}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-sm-12" *ngIf="auth.isSuperAdmin() && parentGroup.value.isGlobal">
      <label for="isPrimaryGlobalVendor" >Primary Global Vendor</label>
      <or-toggle-button msgON="On" msgOFF="Off" formControlName="isPrimaryGlobalVendor" class="light"></or-toggle-button>
    </div>
  </div>
  <div class="row m-3">
    <div class="col-12">
      <label for="img">Profile <span class="text-muted"> (512 x 512 PNG or JPEG)</span></label>
    </div>
    <div class="col-md-12 col-lg-6">
      <or-upload  [smallMsg]="'512 x 512 PNG or JPG'" [showPreviewImg]=true [directToS3]=true [acceptImages]=true formControlName="img" id="img" ></or-upload>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-6 col-sm-12">
      <h5>Address <small class="text-muted">(Optional)</small></h5>
      <div>
        <label for="address">Address Line 1</label>
        <input type="text" class="form-control" formControlName="address1" id="address1" placeholder="1234 Main St">
        <div class="invalid-feedback">Please enter your address.</div>
      </div>
  
      <div>
        <label for="address2">Address Line 2 </label>
        <input type="text" class="form-control"  formControlName="address2" id="address2" placeholder="Apartment or suite">
      </div>
  
      <div>
        <label for="country">City</label>
        <input type="text" class="form-control" id="city" formControlName="city" placeholder="">
      </div>
  
      <div>
        <label for="state">State/Province</label>
        <input type="text" class="form-control" id="state" formControlName="state" placeholder="">
      </div>
  
      <div>
        <label for="country">Country</label>
        <input type="text" class="form-control" id="country" formControlName="country" placeholder="">
      </div>
  
      <div>
        <label for="zip">Zip/Post Code</label>
        <input type="text" class="form-control" id="zip" formControlName="zip" placeholder="">
        <div class="invalid-feedback">
          Zip code required.
        </div>
      </div>
    </div>
  
    <div class="col-md-6 col-sm-12">
      <h5>Social <small class="text-muted">(Optional)</small></h5>
      <div>
        <label for="facebook" >Facebook</label>
        <input type="text" class="form-control"  formControlName="facebook" id="facebook" placeholder="http://facebook.com/example" >
      </div>
      <div>
        <label for="linkedIn">LinkedIn</label>
        <input type="text" class="form-control"  formControlName="linkedIn" id="linkedIn" placeholder="http://linkedIn.com/example" >
      </div>
      <div>
        <label for="twitter">Twitter </label>
        <input type="text" class="form-control" formControlName="twitter" id="twitter" placeholder="http://twitter.com/example" >
      </div>
      <div>
        <label for="skype">Skype </label>
        <input type="text" class="form-control" formControlName="skype" id="skype" placeholder="Username" >
      </div>
    </div>
  </div>
</div>