import { Component, OnInit } from '@angular/core';
import { AuthService } from 'core';

@Component({
  selector: 'app-survey-settings',
  templateUrl: './survey-settings.component.html',
  styleUrls: ['./survey-settings.component.scss']
})
export class SurveySettingsComponent implements OnInit {

  tab = 'urls'

  instanceId: string;

  constructor(public auth: AuthService) {

  }

  ngOnInit() {
    this.instanceId = this.auth.getInstance();
  }

}
