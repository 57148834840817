<div class="d-flex mb-3 mt-1 border-bottom">
  <div class="text-truncate or-secondary h4 m-2"> Users & Roles Management </div>
  <div class="vl ml-2"></div>
  <div class="sectionbtn nav-item mt-1">
    <a class="nav-link or-primary cursor-pointer" [class.font-weight-bold]="tab == 'users'" (click)="tab = 'users'">Users </a>
  </div>
  <div class="sectionbtn nav-item mt-1 mr-auto">
    <a class="nav-link or-primary cursor-pointer" [class.font-weight-bold]="tab == 'roles'" (click)="tab = 'roles'">Roles </a>
  </div>
</div>

<app-users *ngIf="tab == 'users'"></app-users>

<app-roles *ngIf="tab == 'roles'"></app-roles>


