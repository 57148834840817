import { Component, Input, OnInit } from '@angular/core';
import { AuthService, UtilsService } from 'core';
import { of } from 'rxjs';

@Component({
  selector: 'app-project-types-sections',
  templateUrl: './project-types-sections.component.html',
  styleUrls: ['./project-types-sections.component.scss']
})
export class ProjectTypesSectionsComponent implements OnInit {

  @Input('sections')
  sections = null;

  @Input('roles')
  roles = null;

  status: any = ['Soft Launch', 'Full Launch', 'Paused', 'Closed', 'Invoiced', 'Test'];

  constructor(
    public auth: AuthService,
    public utils: UtilsService) { }

  ngOnInit(): void { }

  addSection() {
    // TODO: OTHER DEFAULTS
    const newSection = {
      title: '',
      type:'card',
      status:'',
      open: false,
      cards: [],
      roles: []
    };
    if (this.sections === undefined) this.sections = [];
    this.sections.push(newSection);
  }
  addCard() {
    // TO DO: OTHER DEFAULTS
    const newCard = {
      cardTitle: '',
      cardType:'project-info',
      roles: []
    };
    return (newCard);
  }

  getStatusList = (filter) => { return of(this.status); }
  getRoleList = (filter) => { return of(this.roles); }
  updateStatus(newValue, item) { item.status = newValue.join(); }
  updateRole(newValue, item) { item.roles = newValue; }
  arrayRemove(arr, value) {
    this.sections.find(x => {return x === value}).isDeleted = true;
    return arr.filter(ele => ele !== value);
  }
  arrayRemoveCard(section, cards, value) {
    let updatedCards = cards.filter(ele => ele !== value);
    this.sections.find(x => {return x === section}).cards = updatedCards;
    return updatedCards;
  }
  changeOrder(direction, item, index) {   
    if (direction === 'down') {
      this.sections.splice(index, 1);
      this.sections.splice((index+1), 0, item);
    } else {
      this.sections.splice(index, 1);
      this.sections.splice((index-1), 0, item);
    }
    this.sections.forEach((elem, idx) => {
      elem.order = idx;
    });
  }
}
