
<div class="modal-header text-white">
  <p class="h4 position-absolute" *ngIf="_mode === 'add'"><em class="fa fa-user-tag fa-sm"></em> New Role</p>
  <p class="h4 position-absolute" *ngIf="_mode === 'edit'"><em class="fa fa-user-tag fa-sm"></em> Edit Role {{model.name}}</p>
  <p class="h4 position-absolute" *ngIf="_mode === 'manage-users'"><em class="fa fa-user-tag fa-sm"></em> Members of {{model.name}}</p>
  <button type="button" class="close pull-right text-white" aria-label="Close" (click)="bsModalRef.hide();">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="editForm" (ngSubmit)="submitForm()" novalidate>
    <input type="hidden" formControlName="id" id="id">
    <div class="card-body p-2">

      <ng-container *ngIf="_mode === 'edit' || _mode === 'add'">
        <div class="row mb-3">
          <div class="col">
            <label for="name" class="required">Name</label>
            <div class="input-group">
              <input type="text" class="form-control" formControlName="name" id="name" [ngClass]="submitted && utils.showCssValidField(editForm, 'name')">
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <label for="description">Description</label>
            <div class="input-group">
              <input type="text" class="form-control" formControlName="description" id="description">
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col">
            <label class="required">Role Type</label>
            <select class="custom-select d-block w-100" formControlName="type" id="type">
              <option value="Team">Team</option>
              <option value="Title">Title</option>              
            </select>
          </div>
        </div>


        <div class="text-right">
          <button type="submit" class="btn btn-sm or-primary-bg text-white"><em class="fa fa-save"></em> Save Role</button>
        </div>
      </ng-container>

      <ng-container *ngIf="_mode === 'manage-users'">
        <div>
          <div class="p-0">
            <div class="row mb-5"  *ngIf="!_systemRole">
              <div class="col">
                <or-type-ahead [labelField]="'name'" [valueField]="'id'" [dataCallback]="getUserList" placeholder="Type to search a member..." (valueChange)="updateUsers($event)" cleanAfterSelect=true></or-type-ahead>
              </div>
            </div>
            <div class="row scrollbar overflow-y" style="max-height: 400px;">
              <div class="col">
                <or-list [items]="model.users | filter : filterText : filterColumns" [layout]="layout" [actions]="actions" (action)="handleAction($event)"></or-list>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

    </div>
  </form>
</div>

<ng-template #modalConfirmUser>
  <div class="modal-header">
    <p class="h5">Confirm Delete</p>
  </div>
  <div class="modal-body text-center">
    <p class="h6">Are you sure you want to delete Member {{this.deleteMember.userName}}?</p>
    <button type="button" class="btn btn-outline-secondary m-2" (click)="confirmDelete()">Yes</button>
    <button type="button" class="btn btn-secondary" (click)="openModalRefUser.hide()">No</button>
  </div>
</ng-template>

