import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AlertifyService, AuthService, DropdownService, InstanceService, RoleService } from 'core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';
import { SiteSetupEditComponent } from './site-setup-edit/site-setup-edit.component';

@Component({
  selector: 'app-site-setup',
  templateUrl: './site-setup.component.html',
  styleUrls: ['./site-setup.component.scss'],
  providers: [SiteSetupEditComponent]
})
export class SiteSetupComponent implements OnInit {

  tab = 'navigation';
  mode = 'list';
  instanceId = '';
  instance: any = {};
  filterColumns = ['label', 'type', 'sortOrder'];
  filterText: string;
  sections: any[] = [];
  deleteSection: any;
  editSection = {};
  bsModalRef: BsModalRef;
  instanceRoles: any[] = [];

  @ViewChild('modalConfirm')
  modalRefConfirm: TemplateRef<any>;

  layout = [
    {label: 'Label', id: 'label', sortable: true},
    {label: 'Type', id: 'type', sortable: true},
    {label: 'Icon', id: 'icon', type: 'icon'},
    {label: 'SortOrder', id: 'sortOrder', sortable: true},
    {label: 'Parent', id: 'parentName', sortable: true},
    {label: 'Actions', type: 'actions-inline'}
  ];
  actions = [
    {label: 'Edit Section', type: 'edit', icon: 'pen-square'},
    {label: 'Delete Section', type: 'delete', icon: 'trash-alt'}
  ];

  constructor(
    public auth: AuthService,
    private dd: DropdownService,
    private alertify: AlertifyService,
    private instanceService: InstanceService,
    private modalService: BsModalService,
    private roleService: RoleService) { }

  ngOnInit(): void {
    this.instanceId = this.auth.getInstance();
    if (!this.instanceId) {
      this.alertify.warning('Please select an Instance');
      return;
    }
    this.getSiteSetup();
  }

  public openModal(mode) {
    const initialState = {
      editSection: this.editSection,
      mode: mode
    };

    this.bsModalRef = this.modalService.show(SiteSetupEditComponent, {ignoreBackdropClick: true, keyboard: false, initialState });
    this.bsModalRef.content.saveevent.subscribe((data) => {
      this.getSiteSetup();
    })
  }

  handleAction(action) {
    const dataEdit: any = action.row;
    if (action.type === 'edit') {
      this.editSection = dataEdit;
      this.openModal('edit');
    }
    if (action.type === 'delete') {
      this.deleteSection = action.row;
      this.bsModalRef = this.modalService.show(this.modalRefConfirm);
    }
  }

  confirmDelete() {
    this.instanceService.deleteSection(this.deleteSection.id).subscribe( data => {
      this.alertify.success('Site section deleted successfully');
      this.bsModalRef.hide();
      this.getSiteSetup();
    }, error => {
       this.alertify.error('Unable to delete section');
       this.bsModalRef.hide();
    });
  }

  processSections() {
    this.sections = this.sections.map(item => {

      let parent = this.sections.find(x => x.id == item.parentId);
      let idx = this.sections.findIndex(x => x.id == item.parentId);

      if (parent ==  null || parent == undefined) {
        item.parentId = null;
        item.parentName = '';
      } else {
        item.parentName = parent.label;

        if (!this.sections[idx].childs)
          this.sections[idx].childs = [];

        this.sections[idx].childs.push(item);
      }

      if (item.instanceRoleId != undefined && item.instanceRoleId != null)
        item.instanceRoleName = this.instanceRoles.find(x => x.id === item.instanceRoleId).name;

      return item;
    });
  }

  getSiteSetup() {
    forkJoin([
      this.instanceService.getSections(this.instanceId),
      this.instanceService.getInstance(this.instanceId),
      this.roleService.GetInstanceRoles(this.instanceId, true)]).subscribe((data) => {

      if(data[2] != null) {
        this.instanceRoles = data[2];
      }
      if(data[0] != null) {
        this.sections = data[0];
        this.processSections();
      }
      if(data[1] != null) {
        this.instance = data[1];
      }
    },
    error => {
      this.alertify.error('There was an error getting site setup data');
    });
  }

  uploadCompleted(event: any) {
    if (event !== null && event?.status == 'Complete') {
      this.instanceService.UpdateInstanceValue(this.instanceId, 'logo', this.instance.logo).subscribe( data => {
        this.alertify.success('Logo updated for this Instance');
      } , error => {
        this.alertify.error('Failed to Update Logo');
      });
    }
  }

}
