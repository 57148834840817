
import { PartnersManagerComponent } from './partners-manager.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ComponentsModule, HelpersModule } from 'core';
import { RouterModule } from '@angular/router';
import { VendorMappingSetupComponent } from '../vendor-mapping-setup/vendor-mapping-setup.component';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    HelpersModule,
    RouterModule
  ],
  declarations: [PartnersManagerComponent, VendorMappingSetupComponent]
})
export class PartnersManagerModule { }
