import { Component, OnInit } from '@angular/core';
import { AuthService, AlertifyService, InstanceService, DropdownService } from 'core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(public auth: AuthService,
              private alertify: AlertifyService,
              private dd: DropdownService,
              private router: Router) { }

  instances: any[];
  instanceId = '';

  ngOnInit() {
    this.getInstances();
  }

  logout() {
    this.auth.logOut();
    this.router.navigate(['/login']);
    this.alertify.message('You are now logged out');
  }

  getInstances() {
    this.dd.getInstances().subscribe(data => {
      this.instances = data;
    }, error => {
       this.alertify.error('Unable to fetch instances');
    }, () => {
      this.instanceId = this.auth.getInstance();
    });
  }

  changeInstance(event) {
    this.instanceId = event.target.value;
    this.auth.saveInstance(this.instanceId);
    this.reloadCurrentRoute();
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

}
