import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PartnerService, AlertifyService, AuthService, DropdownService } from 'core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { VendorMappingSetupComponent } from '../vendor-mapping-setup/vendor-mapping-setup.component';

@Component({
  selector: 'app-partners-manager',
  templateUrl: './partners-manager.component.html',
  styleUrls: ['./partners-manager.component.scss'],
  providers: [VendorMappingSetupComponent]
})
export class PartnersManagerComponent implements OnInit {
  deletePartner: any;
  @ViewChild('modalConfirm')
  modalRefConfirm: TemplateRef<any>;
  bsModalRef: BsModalRef;

  @ViewChild('modalAddOrLink')
  modalRefAddOrLink: TemplateRef<any>;
  bsModalAddOrLink: BsModalRef;

  openModalRef: any;
  tab = 'instance-level';
  instanceId = '';
  partnersLocal: any[];
  partnersGlobal: any[];
  createType = 'quick';
  filterColumns = ['name'];
  filterText: string;
  layoutLocal = [
    {label: 'Profile', id: 'img', type: 'img'},
    {label: 'Name', id: 'name', sortable: true},
    {label: 'Website', id: 'website', type: 'link'},
    {label: 'Linked Global Partner', id: 'linkedPartnerName', sortable: true},
    {label: 'Actions', type: 'actions-dropdown'}
  ];
  layoutGlobal = [
    {label: 'Profile', id: 'img', type: 'img'},
    {label: 'Name', id: 'name', sortable: true},
    {label: 'Website', id: 'website', type: 'link'},
    {label: 'Actions', type: 'actions-dropdown'}
  ];
  actions = [
    {label: 'Quota Mapping', type: 'quotas'},
    {label: 'Edit Vendor', type: 'edit'},
    {label: 'Delete Vendor', type: 'delete'},
    {label: 'Clone Vendor', type: 'clone'}
  ];
  selectedPartnerId = '';

  constructor(
    private modalService: BsModalService,
    private partnerService: PartnerService,
    private router: Router,
    private alertify: AlertifyService,
    public auth: AuthService,
    private dd: DropdownService) { }

  ngOnInit() {
    this.instanceId = this.auth.getInstance();
    this.getPartners();
  }

  handleAction(action) {
    const dataEdit: any = action.row;
    if (action.type === 'edit') {
      this.router.navigate(['/vendors/edit/' + dataEdit.id]);
    }
    if (action.type === 'delete') {
      this.deletePartner = action.row;
      this.openModalRef = this.modalService.show(this.modalRefConfirm);
    }
    if (action.type === 'quotas') {
      // open modal
      this.openModal('quotaVendorMapping', dataEdit);
    }
    if (action.type === 'clone') {
      this.router.navigate(['/vendors/clone/' + action.row.id]);
    }
  }

  public openModal(mode, dataEdit) {
    const initialState = {
      vendorId: dataEdit.id,
      partner: dataEdit
    };

    this.bsModalRef = this.modalService.show(VendorMappingSetupComponent, {ignoreBackdropClick: true, keyboard: false, initialState });
    this.bsModalRef.content.saveevent.subscribe((data) => {
      if (data == true) this.bsModalRef.hide();
    })

    // this.bsModalRef = this.modalService.show(SiteSetupEditComponent, {ignoreBackdropClick: true, keyboard: false, initialState });
    // this.bsModalRef.content.saveevent.subscribe((data) => {
    //   this.getSiteSetup();
    // })
  }

  confirmDelete() {
    this.partnerService.DeletePartner(this.deletePartner.id).subscribe(data => {
    }, error => {
       this.alertify.error('Unable to delete vendor');
       this.openModalRef.hide();
    }, () => {
      this.alertify.success(this.deletePartner.name + ' deleted successfully');
      this.openModalRef.hide();
      this.getPartners();
    });
  }

  getPartners() {
    this.partnerService.GetPartners(this.instanceId, true).subscribe(data => {
      this.partnersLocal = data.filter(x => x.instanceId !== null);
      this.partnersGlobal = data.filter(x => x.instanceId === null);
    }, error => {
       this.alertify.error('Unable to get vendors');
    });
  }

  addOrClone() {
    this.bsModalAddOrLink = this.modalService.show(this.modalRefAddOrLink, { ignoreBackdropClick: true, keyboard: false, class: 'modal-project-setup nav-modal-style' });
  }

  closeModal() {
    this.bsModalAddOrLink.hide();
    this.bsModalAddOrLink = null;
    this.createType = 'quick';
  }

  addPartner() {
    this.closeModal();
    this.router.navigateByUrl('/vendors/add');
  }

  copyPartner(event) { this.selectedPartnerId = event.target.value; }

  submitCopy() {
    if (this.selectedPartnerId?.length <= 0) return;

    this.partnerService.copyPartner(this.selectedPartnerId).subscribe(partnerId => {
      this.closeModal();
      this.alertify.success('New vendor added');
      this.router.navigateByUrl('/vendors/edit/' + partnerId);
    }, error => {
      console.log(error);
    });
  }

  create() {
    this.createType == 'quick' ? this.addPartner() : this.submitCopy();
  }

}
