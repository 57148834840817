import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecruitidComponent } from './recruitid.component';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from 'core';
import { RouterModule } from '@angular/router';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ComponentsModule,
    RouterModule,
    BsDatepickerModule.forRoot()
  ],
  declarations: [RecruitidComponent]
})
export class RecruitidModule { }
