import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {AuthService, AlertifyService} from 'core';
import { ChangePassword } from 'projects/admin/src/app/_models/changePassword';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  model = {} as ChangePassword;
  changePasswordForm: FormGroup;
  constructor(private auth: AuthService, private alertify: AlertifyService, private router: Router) { }

  ngOnInit() {
    this.changePasswordForm = new FormGroup({
      currentPassword: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, this.passwordComplexityValidator]),
      confirmPassword: new FormControl('', [Validators.required])
    }, this.passwordMatchValidator);
  }

  changePassword() {

     this.model.email = this.auth.getUser().email;
     this.model.currentPassword = this.changePasswordForm.get('currentPassword').value;
     this.model.newPassword = this.changePasswordForm.get('password').value;
     this.auth.changePassword(this.model).subscribe(next => {
      this.alertify.success('Password changed');
      }, error => {
        this.alertify.error(error);
      }, () => {
        this.router.navigate(['/']);
      });
  }

  passwordMatchValidator(g: FormGroup) {
    return g.get('password').value === g.get('confirmPassword').value ? null : {mismatch: true};
  }
  passwordComplexityValidator(control: FormControl): { [key: string]: boolean } | null {
    const password = control.value;
    if (password && password.length > 5) {
      const hasUpperCase = /[A-Z]/.test(password);
      const hasLowerCase = /[a-z]/.test(password);
      const hasDigit = /\d/.test(password);
      const hasNonAlphanumeric = /\W/.test(password);
      if (hasUpperCase && hasLowerCase && hasDigit && hasNonAlphanumeric) {
        return null;
      }
    }
    return { 'passwordComplexity': true };
  }

}
