import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule, FormArray } from '@angular/forms';
import { AlertifyService, DropdownService, AuthService, UtilsService, QuotaQuestion, ProjectService } from 'core';
import { Router } from '@angular/router';
import { QuotaService } from 'projects/core/src/services/quota.service';

@Component({
  selector: 'app-quota-add',
  templateUrl: './quota-add.component.html',
  styleUrls: ['./quota-add.component.scss']
})
export class QuotaAddComponent implements OnInit {

  model: QuotaQuestion;
  questionForm: FormGroup;
  submitted = false;
  locations: any[] = [];
  showOptions = true;
  showDefaults = false;
  showLocations = false;
  selectedType = 'Gender';
  integerPattern = { 9: { pattern: new RegExp('^[0-9]*$'), optional: true } };

  categories = [
    {value: 'Consumer', name: 'Consumer'},
    {value: 'B2B', name: 'B2B'},
    {value: 'HealthCare', name: 'Health Care'}
  ];

  types = [
    {value: 'Gender', name: 'Gender', inputType: 'RadioButtons'},
    {value: 'Age', name: 'Age', inputType: 'UserInput'},
    {value: 'Ethnicity', name: 'Ethnicity', inputType: 'RadioButtons'},
    {value: 'HouseholdIncome', name: 'Household Income', inputType: 'DropDown'},
    {value: 'State', name: 'Location - State', inputType: 'UserInput'},
    {value: 'Region', name: 'Location - Region', inputType: 'UserInput'},
    {value: 'Division', name: 'Location - Division', inputType: 'UserInput'}
  ];

  constructor(
    private quotaService: QuotaService,
    private alertify: AlertifyService,
    private projectService: ProjectService,
    private router: Router,
    private dd: DropdownService,
    public auth: AuthService,
    public utils: UtilsService) { }

  ngOnInit() {

    this.questionForm = new FormGroup({
      question: new FormControl('', [Validators.required]),
      category: new FormControl({value:'Consumer', disabled: true}),
      type: new FormControl('Gender'),
      description: new FormControl(''),
      inputType: new FormControl(),
      instanceId: new FormControl(this.auth.getInstance()),
      options: new FormArray([this.addOption()]),
      defaults: new FormArray([])
    });
  }

  questionAdd() {

    this.submitted = true;

    if (!this.questionForm.valid) {
      return;
    }

    // fetch the inputType from types[] based on selectedType
    let inputType = this.types.filter(item => item.value === this.selectedType).map(item => item.inputType)[0];
    this.questionForm.controls['inputType'].setValue(inputType);

    if (this.selectedType === 'Ethnicity' || this.selectedType === 'Gender') {
      let defaults = (this.questionForm.get('options') as FormArray).controls;
      if (defaults.length > 0) {
        defaults.forEach(item => {
          (this.questionForm.get('defaults') as FormArray).push(this.addOptionDefault(item.value.value, item.value.default));
        });
      }
    };

    this.quotaService.AddQuestion(this.questionForm.value).subscribe( data => {
      this.model = data;
    }, error => {
       this.alertify.error('Unable to add Quota Question');
    },
    () => {
      this.alertify.success('Quota Question added successfully');
      //this.router.navigate(['/quota-questions']);
    });

  }

  addOption(): FormGroup {
    return new FormGroup({
      label: new FormControl('', [Validators.required]),
      value: new FormControl('', [Validators.required]),
      default: new FormControl(0, [Validators.required])
    });
  }

  addOptionDefault(value: string = '', percent: number = 0): FormGroup {
    return new FormGroup({
      value: new FormControl(value),
      min: new FormControl(0, [Validators.required]),
      max: new FormControl(0, [Validators.required]),
      percent: new FormControl(percent, [Validators.required])
    });
  }

  addOptionClick() {
    (this.questionForm.get('options') as FormArray).push(this.addOption());
  }

  addOptionDefaultClick() {
    (this.questionForm.get('defaults') as FormArray).push(this.addOptionDefault());
  }

  removeOptionClick(i: number) {
    (this.questionForm.get('options') as FormArray).removeAt(i);
  }

  removeOptionDefaultClick(i: number) {
    (this.questionForm.get('defaults') as FormArray).removeAt(i);
  }

  getLocations(filter) {

    if (filter === '') {
      this.locations = [];
      return;
    }

    this.projectService.GetLocations(filter).subscribe(data => {
      this.locations = data.map(obj => ({ value: obj }));

      this.locations.forEach(item => {
        (this.questionForm.get('defaults') as FormArray).push(this.addOptionDefault(item.value));
      });

    }, error => {
       this.alertify.error('Unable to get ' + filter);
    });
  }

  changeType(event: any) {

    this.selectedType = event.target.value;

    (this.questionForm.get('options') as FormArray).clear();
    (this.questionForm.get('defaults') as FormArray).clear();

    if (this.selectedType === 'State' || this.selectedType === 'Region' || this.selectedType === 'Division') {
      this.showOptions = false;
      this.showDefaults = true;
      this.showLocations = true;

      // Fetch locations based on type (State, Region or Division)
      this.getLocations(this.selectedType.toLowerCase());

    } else if (this.selectedType === 'HouseholdIncome') {
      this.showOptions = true;
      this.showDefaults = true;
      this.showLocations = false;

      (this.questionForm.get('options') as FormArray).push(this.addOption());
      (this.questionForm.get('defaults') as FormArray).push(this.addOptionDefault());

    } else if (this.selectedType === 'Ethnicity' || this.selectedType === 'Gender') {
      this.showOptions = true;
      this.showDefaults = false;
      this.showLocations = false;

      (this.questionForm.get('options') as FormArray).push(this.addOption());

    } else {
      this.showOptions = false;
      this.showDefaults = true;
      this.showLocations = false;

      if ((this.questionForm.get('defaults') as FormArray).length < 1) {
        (this.questionForm.get('defaults') as FormArray).push(this.addOptionDefault());
      }
    }
  }

}
