import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from 'core';
import { RouterModule } from '@angular/router';
import { LiveMonitoringComponent } from './live-monitoring.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ComponentsModule,
    RouterModule,
  ],
  declarations: [LiveMonitoringComponent]
})
export class LiveMonitoringModule { }
