<div class="card">
  <div class="card-header or-primary-bg text-white">
    <p class="h4 inline float-left"><em class="fa fa-cog fa-sm"></em> Project List Views</p>
    <div class="float-right">
      <div class="form-inline form">
        <ng-container *ngIf="editListView == null">
          <button type="button" (click)="addListView()" class="btn btn-sm btn-outline-light inline"><em class="fa fa-plus-circle"></em> Add List View</button>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="card-body">
    <ng-container *ngIf="editListView == null">
      <div class="mt-0">
        <or-list [items]="projectListViews" [layout]="layout" [actions]="actions" [enableExport]=false (action)="handleAction($event)"></or-list>
      </div>
    </ng-container>

    <form #listViewForm="ngForm" novalidate>
      <ng-container *ngIf="editListView != null">
        <div class="row">
          <div class="form-group col-12 col-md-3">
            <label class="required">Name</label>
            <input [(ngModel)]="editListView.name" class="form-control" name="name" required [ngClass]="showCssValidField('name')">
          </div>
          <div class="form-group col-12 col-md-3">
            <label class="required">Description</label>
            <input [(ngModel)]="editListView.description" class="form-control" name="description" required [ngClass]="showCssValidField('description')">
          </div>
          <div class="form-group col-12 col-md-3">
            <label class="required">Icon</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i [ngClass]="editListView.icon"></i>
                </span>
              </div>
              <input type="text" id="icon" name="icon" [(ngModel)]="editListView.icon" required [iconPicker]="editListView.icon" [ipIconPack]="['fa5']" [ipPosition]="'bottom'"
                      (iconPickerSelect)="onIconPickerSelect($event)" class="form-control" [ngClass]="showCssValidField('icon')" autocomplete="off">
              
            </div>
          </div>
          <div class="form-group col-12 col-md-3" *ngIf="projectTypes.length > 1 || editListView.projectTypeId != projectTypes[0].id">
            <label>Type</label>
            <select class="form-control inline" [(ngModel)]="editListView.projectTypeId" name="projectTypeId" [ngClass]="showCssValidField('projectTypeId')">
              <option [ngValue]="null">All</option>
              <option *ngFor="let type of projectTypes" [value]="type.id">{{type.name}}</option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-12 col-md-3">
            <label class="required">Location Types</label>
            <select class="form-control inline" [(ngModel)]="editListView.locationType" name="locationType" required [ngClass]="showCssValidField('locationType')">
              <option *ngFor="let style of locationTypes" [value]="style.id">{{style.label}}</option>
            </select>
          </div>
          <ng-container *ngIf="editListView.locationType == 'default'">
            <div class="form-group col-12 col-md-3">
              <label class="required">Card Mode</label>
              <select class="form-control inline" [(ngModel)]="editListView.cardStyle" name="cardStyle" required [ngClass]="showCssValidField('cardStyle')">
                <option *ngFor="let style of cardStyles" [value]="style.id">{{style.label}}</option>
              </select>
            </div>
            <div class="form-group col-12 col-md-3">
              <label for="sortOrder">Sort Order</label>
              <input type="number" maxlength="3" oninput="validity.valid||(value='');" [(ngModel)]="editListView.sortOrder" min="0" class="form-control" name="sortOrder" [ngClass]="showCssValidField('sortOrder')">
            </div>
            <div class="form-group col-12 col-md-3">
              <label>Show Archived Projects Only</label>
              <or-toggle-button msgON="On" msgOFF="Off" size="small" [(ngModel)]="editListView.showArchivedProjectsOnlyButton" name="showArchivedProjectsOnlyButton"></or-toggle-button>
            </div>
          </ng-container>
         
        </div>
        <ng-container *ngIf="editListView.locationType == 'default'">
          <div class="row">
            <div class="form-group col-12 col-md-3">
              <label class="required">View Modes</label>
              <or-type-ahead [selectedValues]="editListView.viewModes" multi=true [dataCallback]="getViewModes" placeholder="Type 'list', 'card' or 'calendar'" (valueChange)="updateViewMode($event)"></or-type-ahead>
            </div>
            <div class="form-group col-12 col-md-3">
              <label>Roles</label>
              <or-type-ahead [selectedValues]="editListView.roles === null ? [] : editListView.roles"  [labelField]="'name'" [valueField]="'id'" multi=true [dataCallback]="getRoleList" placeholder="Select a Role" (valueChange)="updateRole($event)"></or-type-ahead>
            </div>
            <div class="form-group col-12 col-md-6" *ngIf="!editListView.showArchivedProjectsOnlyButton">
              <label>Project Status Filter</label>
              <or-type-ahead [selectedValues]="editListView.statusFilter" multi=true [dataCallback]="getStatusList" placeholder="Type a project status. Ex: 'Full Launch'" (valueChange)="updateStatus($event)"></or-type-ahead>
            </div>
          </div>
  
          <hr>
          <div class="card">
            <div class="card-header d-flex align-items-center bg-white border-bottom-0">
              <span class="h6 or-primary">Enable Features</span>
            </div>
            <div class="px-4 py-2">
              <div class="row">
                <div class="form-group col-12 col-md-3">
                  <label>Favorite</label>
                  <or-toggle-button msgON="On" msgOFF="Off" size="small" [(ngModel)]="editListView.showFavouriteButton" name="showFavouriteButton"></or-toggle-button>
                </div>
                <div class="form-group col-12 col-md-3">
                  <label>My Projects</label>
                  <or-toggle-button msgON="On" msgOFF="Off" size="small" [(ngModel)]="editListView.showMyProjects" name="showMyProjects"></or-toggle-button>
                </div>
                <div class="form-group col-12 col-md-3">
                  <label>Copy Urls</label>
                  <or-toggle-button msgON="On" msgOFF="Off" size="small" [(ngModel)]="editListView.showCopyUrls" name="showCopyUrls"></or-toggle-button>
                </div>
                <div class="form-group col-12 col-md-3">
                  <label>Download</label>
                  <or-toggle-button msgON="On" msgOFF="Off" size="small" [(ngModel)]="editListView.showDownload" name="showDownload"></or-toggle-button>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-12 col-md-3">
                  <label>Grouping</label>
                  <or-toggle-button msgON="On" msgOFF="Off" size="small" [(ngModel)]="editListView.showGrouping" name="showGrouping"></or-toggle-button>
                </div>
                <div class="form-group col-12 col-md-3">
                  <label>Filter Text</label>
                  <or-toggle-button msgON="On" msgOFF="Off" size="small" [(ngModel)]="editListView.showFilterText" name="showFilterText"></or-toggle-button>
                </div>
                <div class="form-group col-12 col-md-4">
                  <label>Filter Text Label</label>
                  <input [(ngModel)]="editListView.filterTextLabel" class="form-control" name="filterTextLabel" required [ngClass]="showCssValidField('filterTextLabel')">
                </div>
              </div>
            </div>
            
          </div>
          <hr>
        </ng-container>
       
        
        <div class="card">
          <div class="card-header d-flex align-items-center bg-white border-bottom-0">
            <span class="h6 or-primary">Columns</span>
          </div>
          <div class="px-4 py-2">
            <div class="table-responsive">
              <table class="table table-hover table-light table-striped">
                <thead>
                  <tr>
                    <th class="nowrap">Label</th>
                    <th class="nowrap">Field</th>
                    <th class="nowrap">Type</th>
                    <th class="nowrap text-center">Selectable</th>
                    <th class="nowrap text-center">Sortable</th>
                    <th class="nowrap text-center">Filterable</th>
                    <th class="nowrap text-center">Text Filterable</th>
                    <th class="nowrap text-center">Editable</th>
                    <th class="nowrap text-center">Auto Save</th>
                    <th class="nowrap">Show from Screen</th>
                    <th class="nowrap"></th>
                    <th class="nowrap"></th>
                    <th class="nowrap"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of editListView.tableColumns; let idx = index">
                    <td class="align-middle nowrap">
                      <input [(ngModel)]="item.label" name="label-{{item.id}}" class="form-control form-control-sm" required [ngClass]="showCssValidField('label-' + item.id)">
                    </td>
                    <td class="align-middle nowrap">
                      <select class="form-control form-control-sm inline" required (change)="firstLoading = false; handleFieldChange($event.target.value, item)" [(ngModel)]="item.field" name="field-{{item.id}}" [ngClass]="showCssValidField('field-' + item.id)">
                        <option [ngValue]="null">--Select--</option>
                        <option *ngFor="let field of fieldTypes" [value]="field.field">{{field.label}}</option>
                      </select>
                    </td>
                    <td class="align-middle nowrap">
                      <select class="form-control form-control-sm inline" [class.d-none]="types[item.id]?.length == 1" required [(ngModel)]="item.type" name="type-{{item.id}}" [ngClass]="showCssValidField('type-' + item.id)">
                        <option [ngValue]="null">--Select--</option>
                        <option *ngFor="let type of types[item.id]" [value]="type">{{type}}</option>
                      </select>
                    </td>
                    <td class="align-middle nowrap">
                      <div class="d-flex justify-content-center">
                        <or-toggle-button msgON="On" msgOFF="Off" [isDisabled]="item.selectable.isDisabled" size="small" [(ngModel)]="item.selectable.value" name="selectable-{{item.id}}"></or-toggle-button>
                      </div>
                    </td>
                    <td class="align-middle nowrap">
                      <div class="d-flex justify-content-center">
                        <or-toggle-button msgON="On" msgOFF="Off" [isDisabled]="item.sortable.isDisabled" size="small" [(ngModel)]="item.sortable.value" name="sortable-{{item.id}}"></or-toggle-button>
                      </div>
                    </td>
                    <td class="align-middle nowrap">
                      <div class="d-flex justify-content-center">
                        <or-toggle-button msgON="On" msgOFF="Off" [isDisabled]="item.filterable.isDisabled" size="small" [(ngModel)]="item.filterable.value" name="filterable-{{item.id}}"></or-toggle-button>
                      </div>
                    </td>
                    <td class="align-middle nowrap">
                      <div class="d-flex justify-content-center">
                        <or-toggle-button msgON="On" msgOFF="Off" [isDisabled]="item.textFilterable.isDisabled" size="small" [(ngModel)]="item.textFilterable.value" name="textFilterable-{{item.id}}"></or-toggle-button>
                      </div>
                    </td>
                    <td class="align-middle nowrap">
                      <div class="d-flex justify-content-center">
                        <or-toggle-button msgON="On" msgOFF="Off" [isDisabled]="item.editable.isDisabled" size="small" [(ngModel)]="item.editable.value" name="editable-{{item.id}}"></or-toggle-button>
                      </div>
                    </td>
                    <td class="align-middle nowrap">
                      <div class="d-flex justify-content-center">
                        <or-toggle-button msgON="On" msgOFF="Off" [isDisabled]="item.autoSave.isDisabled" size="small" [(ngModel)]="item.autoSave.value" name="autoSave-{{item.id}}"></or-toggle-button>
                      </div>
                    </td>
                    <td class="align-middle nowrap">
                      <div class="d-flex justify-content-center">
                        <select class="form-control form-control-sm inline" [(ngModel)]="item.screenSize" name="screenSize-{{item.id}}">
                          <option [ngValue]="null">--Select--</option>
                          <option value="xs">XSmall</option>
                          <option value="sm">Small</option>
                          <option value="md">Medium</option>
                          <option value="lg">Large</option>
                          <option value="xl">XLarge</option>
                        </select>
                      </div>
                    </td>
                    <td class="align-middle nowrap">
                      <button (click)="changeOrder('down', item, idx);" type="button" class="btn btn-xs border-0 opacity-70" *ngIf="idx < editListView.tableColumns.length - 1">
                        <i class="fa fa-arrow-down"></i>
                      </button>
                    </td>
                    <td class="align-middle nowrap">
                      <button (click)="changeOrder('up', item, idx);" type="button" class="btn btn-xs border-0 opacity-70" *ngIf="idx > 0">
                        <i class="fa fa-arrow-up"></i>
                      </button>
                    </td>
                    <td class="align-middle nowrap py-0" [class.alert-warning]="columnsConfirmDeletes[item.id] == true">
                      <div class="d-flex justify-content-center" >
                        <button *ngIf="columnsConfirmDeletes[item.id] != true" (click)="columnsConfirmDeletes[item.id] = true" type="button" class="btn btn-xs btn-outline-danger">
                          <i class="fa fa-times"></i>
                        </button>
                      </div>
                      <div *ngIf="columnsConfirmDeletes[item.id]" class="mx-2 my-0 text-nowrap" role="alert">
                        <div class="row nav-font12 d-flex justify-content-center">
                          Delete column?
                        </div>
                        <div class="row d-flex justify-content-center">
                          <button (click)="columnsConfirmDeletes[item.id] = null" type="button" class="btn btn-xs btn-outline-secondary mr-1">
                            <i class="fa fa-times"></i>
                          </button>
                          <button (click)="removeColumnClick(idx); columnsConfirmDeletes[item.id] = null;" type="button" class="btn btn-xs btn-outline-secondary">
                            <i class="fa fa-check"></i>
                          </button>
                        </div>
                       
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button type="button" class="btn btn-sm btn-outline-secondary inline mb-3" (click)="addColumnClick()"><em class="fa fa-plus-circle"></em> Add Column</button>
          </div>
        </div>

      </ng-container>
    </form>

  </div>

  <div class="card-footer text-right" *ngIf="editListView != null">
    <div class="btn btn-light mr-2" (click)="editListView = null" type="button">Cancel</div>
    <button class="btn or-primary-bg text-white" (click)="saveListView()">Save</button>
  </div>
</div>

<ng-template #modalConfirm>
  <div class="modal-header">
    <p class="h5">Confirm Delete</p>
  </div>
  <div class="modal-body text-center">
    <p class="h6">Are you sure you want to delete the List View '{{this.deleteView.name}}'?</p>
    <button type="button" class="btn btn-outline-secondary m-2" (click)="confirmDelete()">Yes</button>
    <button type="button" class="btn btn-secondary" (click)="openModalRef.hide()">No</button>
  </div>
</ng-template>
