import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Client, AlertifyService, DropdownService, ClientService, AuthService, UtilsService, UserService } from 'core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-client-add',
  templateUrl: './client-add.component.html',
  styleUrls: ['./client-add.component.css'],
  providers: [UserService]
})
export class ClientAddComponent implements OnInit {
  model: Client;
  clientForm: FormGroup;
  instances: any[];
  states: any[];
  owners: any[];
  submitted = false;

  constructor(private alertify: AlertifyService, private router: Router, private dd: DropdownService, public userService: UserService, private client: ClientService, public auth: AuthService, public utils: UtilsService) { }

  ngOnInit() {

    this.dd.getStates().subscribe( data => {
      this.states = data;
    }, error => {
       this.alertify.error('Unable to get states');
    },
    () => {
    });

    this.userService.GetInstanceUsers(this.auth.getInstance(), true).subscribe( data => {
      this.owners = data;
    }, error => {
       this.alertify.error('Unable to get users');
    },
    () => {
    });


    this.clientForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      website: new FormControl(''),
      img: new FormControl(''),
      instanceId: new FormControl(this.auth.getInstance()),
      address1: new FormControl(''),
      address2: new FormControl(''),
      city: new FormControl(''),
      country: new FormControl(''),
      state: new FormControl(''),
      zip: new FormControl(''),
      facebook: new FormControl(''),
      linkedIn: new FormControl(''),
      twitter: new FormControl(''),
      skype: new FormControl(''),
      salesTerm: new FormControl(''),
      salesTermValue: new FormControl(0),
      internalAccountOwnerId: new FormControl(this.auth.getUser().id)
    });

    this.dd.getInstances().subscribe( data => {
      this.instances = data;
    }, error => {
       this.alertify.error('Unable to get instances');
    },
    () => {
      // 'onCompleted' callback.
      // No errors, route to new page here
    });
  }

  clientAdd() {

    this.submitted = true;

    if (!this.clientForm.valid) {
      return;
    }

    this.client.AddClient(this.clientForm.value).subscribe( data => {
      this.model = data;
    }, error => {
       this.alertify.error('Unable to add client');
    },
    () => {
      this.alertify.success('Client added successfully');
      this.router.navigate(['/clients']);
    });


  }



}
