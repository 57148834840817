import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private http: HttpClient) { }

  hasErrorRequired(form: FormGroup, field: string) {
    return (!form.get(field).valid && form.get(field).errors?.required);
  }
  hasErrorMinlenght(form: FormGroup, field: string) {
    return (!form.get(field).valid && form.get(field).errors?.minlength);
  }

  hasErrorEmail(form: FormGroup, field: string) {
    return (!form.get(field).valid && form.get(field).errors?.email);
  }
  longestCommonSubstring(strings, minlength = null) {
    if (strings.length === 0) {
        return '';
    }

    const firstString = strings[0];
    let longestCommon = '';

    for (let i = 0; i < firstString.length; i++) {
        for (let j = i + 1; j <= firstString.length; j++) {
            const substring = firstString.slice(i, j);

            if (strings.every(str => str.includes(substring))) {
                if (substring.length > longestCommon.length) {
                    longestCommon = substring;
                }
            }
        }
    }

    return (minlength == null || longestCommon.length >= minlength ) ? longestCommon : '';
}

  daysBetween(startDate, endDate) {
    const start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    const end = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    let dayCount = 0;
    while (end > start) {
      dayCount++;
      start.setDate(start.getDate() + 1);
    }
    return dayCount;
  }

  showCssValidField(form: any, field: string) {
    try {
      const isValid = form.get(field)?.valid;
      return {
        'is-invalid': !isValid,
        'is-valid': isValid
      };
    } catch (error) {
    }
  }

  copyTextToBuffer(totalString) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = totalString;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  async copyTextToClipboard(text: string): Promise<void> {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  }

  downloadXLSX(url, filename){
    this.http.get(url, { responseType: 'arraybuffer' })
    .subscribe(response => {
      this.generateXLSX(response, filename);
    });
  }
  generateXLSX(data, filename) {
    const blob = new Blob([data], { type: 'text/xlsx', });
    const url = window.URL.createObjectURL(blob);
    const hiddenElement = document.createElement('a');
    hiddenElement.href = url;
    hiddenElement.target = '_blank';
    hiddenElement.download = filename;
    hiddenElement.click();
  }

  createDialogList(event, key) {
    let myList;
    let storedList = localStorage.getItem('dialogList');
    if (storedList) {
      myList = JSON.parse(storedList);
      const index = myList.findIndex(item => item.data === key);
      if (index !== -1) {
        // Key found, overwrite the value
        if (event) {
          myList[index].value = new Date();
        } else {
          myList.splice(index, 1);
        }
      } else {
        if (event) {
          // Key not found, optionally add a new key-value pair
          myList.push({ data: key, value: new Date() });
        }
      }
    }
    else {
      myList = [{ data: key, value: new Date() }];
    }
    localStorage.setItem('dialogList', JSON.stringify(myList));

  }
  calculateDayDifference(date1: Date, date2: Date): number {
    const diffInTime = date2.getTime() - date1.getTime();
    const diffInDays = diffInTime / (1000 * 3600 * 24); // Convert from milliseconds to days
    return diffInDays;
  }
  getDialogItems(key) {
    let storedList = localStorage.getItem('dialogList');
    let dayDifference = -1;
    if (storedList) {
      let myList = JSON.parse(storedList);
      myList.filter(x => x.data === key).forEach(item => {
        item.value = new Date();
        dayDifference = this.calculateDayDifference(item.value, new Date());
        if (dayDifference > 5) {
          dayDifference = -1;
        }
      });
      console.log(myList);  // This will be an array of objects
    }
    return dayDifference;
    // if (dayDifference == -1) {
    //   this.model = team;
    //   this.openModalRef = this.modalService.show(this.modalRefConfirm, { class: 'nav-modal-style' });
    // }
    // this.model = team;
    // this.openModalRef = this.modalService.show(this.modalRefConfirm, { class: 'nav-modal-style' });
  }
}


