import { Component, OnInit } from '@angular/core';
import { CleanidService, AlertifyService, DropdownService, AuthService, PublicService } from 'core';

@Component({
  selector: 'app-cleanid',
  templateUrl: './cleanid.component.html',
  styleUrls: ['./cleanid.component.scss']
})
export class CleanidComponent implements OnInit {

  tab = '';
  instanceId = '';
  profile = 'moderate';
  profileDefaults: any;
  settings: any;
  threshold = 25;
  licenseDataV3: any;
  fullLicenceData: any;
  filterMode = 'active';

  licenseColsV3 = [
    { label: 'Instance', id: 'client', sortable: true },
    { label: 'ApiKey', id: 'licenseKey' },
    { label: 'Api Name', id: 'licenseName' },
    { label: 'Start', id: 'beginDate', sortable: true, type: 'date' },
    { label: 'End', id: 'endDate', sortable: true, type: 'date' },
    { label: 'Allowance', id: 'transactionsAllowed', sortable: true, type: 'number' },
    { label: 'Total Used', id: 'transactionsTotal', sortable: true, type: 'number' },
    { label: 'Remaining', id: 'totalRemaining', sortable: true, type: 'number' },
    { label: 'Active', id: 'enabled', type: 'toggle'},
    {label: 'Actions', type: 'actions-inline'}
  ];

  layout = [
    {
      section: 'Device', settings: [
        { label: 'Unknown Browser', ref: 'browserUnknownRiskWeightId' },
        { label: 'Obsolete Browser', ref: 'browserObsoleteRiskWeightId' },
        { label: 'Unknown Operating System', ref: 'osUnknownRiskWeightId' },
        { label: 'Obsolete Operating System', ref: 'osObsoleteRiskWeightId' },
        { label: 'Unknown Device Type', ref: 'deviceTypeUnknownRiskWeightId' },
        { label: 'Unknown User Agent', ref: 'userAgentUnknownRiskWeightId' },
        { label: 'Harmful Bot Detected', ref: 'botRiskWeightId' },
        { label: 'Device occurrences are higher than normal', ref: 'velocityRiskWeightId' },
        { label: 'Device Characteristics are changing frequently', ref: 'oscillatingRiskWeightId' },
        { label: 'Tampered Device: Invalid User Agent', ref: 'userAgentInvalidRiskWeightId' },
        { label: 'Tampered Device: Invalid Browser Properties', ref: 'browserPropertiesRiskWeightId' },
        { label: 'Tampered Device: Unknown Configuration - Platform', ref: 'platformUnknownRiskWeightId' },
        { label: 'Tampered Device: Unknown Configuration - OS', ref: 'platformOsUnknownRiskWeightId' },
        { label: 'Tampered Device: Unknown Configuration - Browser', ref: 'browserOsUnknownRiskWeightId' },
        { label: 'Tampered Device: Request Inputs are Invalid', ref: 'tamperedInputRiskWeightId' },
        { label: 'Tampered Device: Request Token is Invalid', ref: 'tamperedTokenRiskWeightId' },
      ]
    },
    {
      section: 'Privacy', settings: [
        { label: 'IP Address has been Blacklisted', ref: 'blacklistedRiskWeightId' },
        { label: 'Anonymous: ID is hidden by Datacenter', ref: 'datacenterRiskWeightId' },
        { label: 'Anonymous: ID is hidden by VPN', ref: 'vpnRiskWeightId' },
        { label: 'Anonymous: ID is hidden by Proxy', ref: 'proxyRiskWeightId' },
        { label: 'Anonymous: Tor (Dark Web) Browser', ref: 'torRiskWeightId' },
        { label: 'Anonymous: Private Browser Mode', ref: 'privacyModeRiskWeightId' },
        { label: 'Anonymous: Privacy Browser', ref: 'privacyBrowserRiskWeightId' },
        { label: 'Anonymous: Masked User Agent', ref: 'maskedRiskWeightId' },
        { label: 'Anonymous: Browser Emulator', ref: 'emulatorRiskWeightId' }
      ]
    },
    {
      section: 'Location', settings: [
        { label: 'Postal Fail: Country Code on device is Unknown', ref: 'postalCodeRiskWeightId' },
        { label: 'Postal Fail: Country Code comparison fails', ref: 'geoCountryCodeRiskWeightId' },
        { label: 'Postal Fail: Postal Code on device is Unknown', ref: 'postalCodeRiskWeightId' },
        { label: 'Postal Fail: Postal Code proximity check fails', ref: 'geoProximityRiskWeightId' },
        { label: 'Browser Country Code does NOT match location of IP Address', ref: 'geoBrowserCountryCodeRiskWeightId' },
        { label: 'Browser TZ does NOT match location of IP Address', ref: 'geoBrowserTimezoneRiskWeightId' },
        { label: 'IP location is currently in "off-hours"', ref: 'geoOffhoursRiskWeightId' }
      ]
    },
    {
      section: 'Language', settings: [
        { label: 'Browser Language is NOT same as OS', ref: 'langRiskWeightId' },
        { label: 'Browser Language is NOT the same as IP Address Location', ref: 'geoBrowserLangRiskWeightId' },
        { label: 'OS language is NOT the same as IP Address Location', ref: 'geoOsLangRiskWeightId' }
      ]
    }
  ];

  constructor(
    public auth: AuthService,
    private cleanidService: CleanidService,
    private alertify: AlertifyService,
    private publicService: PublicService) {
    this.instanceId = this.auth.getInstance();
  }

  ngOnInit() {
    this.showLicenseV3();
  }

  showSettingsTab() {
    this.tab = 'settings';
    this.showSettings();
  }

  setTableData(mode) {
    this.filterMode = mode;
    this.licenseDataV3 = [];
    this.licenseDataV3 = (mode === 'active') ? this.fullLicenceData.filter(e => e.enabled) : this.fullLicenceData;
  }

  showSettings() {
    this.cleanidService.GetCleanIDSettings(this.instanceId).subscribe((responseList ) => {
        const settings = responseList[0];
        this.settings = settings.settings;
        this.profile = settings.mode;
        this.threshold = settings.threshold;

        const profiles = responseList[1];
        this.profileDefaults = {};
        for (let i = 0; i < profiles.length; i++) {
          if (profiles[i].name.toLowerCase() === 'conservative') {
            this.profileDefaults.conservative = profiles[i];
          }
          else if (profiles[i].name.toLowerCase() === 'moderate') {
            this.profileDefaults.moderate = profiles[i];
          }
          else if (profiles[i].name.toLowerCase() === 'aggressive') {
            this.profileDefaults.aggressive = profiles[i];
          }
        }

        // If no settings read from DB then we update to use default
        if (this.settings?.id == null) { this.setProfile('moderate'); }
      },
      error => {
        this.alertify.error('There was a problem getting your latest settings.');
      }
    );
  }

  setProfile(type: string) {
    this.profile = type;
    if (this.profile !== 'custom' && this.profileDefaults[this.profile] != null) {
      this.settings = { ... this.profileDefaults[this.profile] };
    }
    if (this.profile === 'conservative') { this.threshold = 30; }
    else if (this.profile === 'moderate') { this.threshold = 25; }
    else if (this.profile === 'aggressive') { this.threshold = 20; }
  }

  saveSettings() {
    // Some items share settings so update them now
    this.settings.tamperedCookieRiskWeightId = this.settings.tamperedInputRiskWeightId;

    if (this.profile === 'custom') {
      this.settings.name = 'Custom';
    }
    this.cleanidService.SaveCleanIDSettings({ mode: this.profile, threshold: this.threshold, settings: this.settings }).subscribe((res) => {
      this.alertify.success('These settings have been submitted for review, once evaluated they will be added to your live settings.');
    }, error => {
      this.alertify.error('Failed to save CleanID Settings');
    });
  }

  showReport() {
    this.tab = 'report';
  }

  showLicenseV3() {
    this.tab = 'licenseV3';
    this.cleanidService.GetCleanIDLicenseDataV3().subscribe(data => {
      data.forEach(e => {
        e.totalRemaining = (e.transactionsAllowed == null)
          ? null
          : e.transactionsAllowed - e.transactionsTotal;
      });
      this.licenseDataV3 = data;
      this.fullLicenceData = data;
      this.setTableData('active');
    }, error => {
       this.alertify.error('Unable to fetch CleaID License info');
    });
  }

  getCleanIDTestPage() {
    return this.publicService.getCleanIDTest();
  }

}
