<div *ngIf="this.auth.getUser()" class="bg-light border-right sidebar-sticky" id="sidebar-wrapper">
  <div class="list-group list-group-flush">
    <!--<a [routerLink]="['/home']" *ngIf="this.auth.getUser().superAdmin" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
      <i class="fas fa-tachometer-alt fa-lg or-secondary"></i> Home
    </a>-->
    <a [routerLink]="['/usermanager']" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
      <i class="fas fa-users fa-lg or-secondary"></i> Users & Roles
    </a>
     <a [routerLink]="['/instancemanager']" *ngIf="this.auth.getUser().superAdmin" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
      <i class="fas fa-server fa-lg or-secondary"></i> Instances
    </a>
     <a [routerLink]="['/site-setup']" *ngIf="this.auth.getUser().superAdmin" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
      <i class="fas fa-sitemap fa-lg or-secondary"></i> Site Setup
    </a>
    <a [routerLink]="['/vendors']" *ngIf="this.auth.getUser().superAdmin || this.auth.getUser().access.partnerEnable" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
      <i class="fas fa-handshake fa-lg or-secondary"></i> Vendors
    </a>
    <a [routerLink]="['/clients']" *ngIf="this.auth.getUser().superAdmin || this.auth.getUser().access.clientEnable" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
      <i class="fas fa-user-tie fa-lg or-secondary"></i> Clients
    </a>
    <ng-container *ngIf="this.auth.getUser().superAdmin || this.auth.getUser().access?.projectEnable">
      <a [routerLink]="['/project-manager']"  routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
        <i class="fas fa-chart-line fa-lg or-secondary"></i> Project Manager
      </a>
    </ng-container>
    <a  *ngIf="this.auth.getUser().superAdmin" [routerLink]="['/survey-settings']" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
      <i class="fas fa-cog fa-lg or-secondary"></i> Survey Settings
    </a>
    <a [routerLink]="['/cleanid']" *ngIf="this.auth.getUser().superAdmin || this.auth.getUser().access.cleanIDEnable" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
      <i class="fas fa-user-check fa-lg or-secondary"></i> CleanID
    </a>
    <a [routerLink]="['/responseid']" *ngIf="this.auth.getUser().superAdmin || this.auth.getUser().access.responseIDEnable" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
      <i class="fas fa-check-double fa-lg or-secondary"></i> ResponseID
    </a>
    <a [routerLink]="['/keys']" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
      <i class="fas fa-key fa-lg or-secondary"></i> Keys
    </a>
    <a [routerLink]="['/quota-questions']" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
      <i class="fas fa-question fa-lg or-secondary"></i> Quota Questions
    </a>
    <!--<a [routerLink]="['/dashboard-manager']" *ngIf="this.auth.getUser().superAdmin" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
      <i class="fas fa-grip-horizontal fa-lg or-secondary"></i> Dashboard Manager
    </a>
    <a [routerLink]="['/app-integration']" *ngIf="this.auth.getUser().superAdmin" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
      <i class="fas fa-chart-bar fa-lg or-secondary"></i> Apps
    </a>
  <a [routerLink]="['/live-monitoring']" routerLinkActive="nav-active" class="list-group-item list-group-item-action bg-light">
        <i class="fas fa-chart-bar fa-lg or-secondary"></i> Live Monitoring
      </a>-->
  </div>
</div>

