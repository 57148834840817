import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute } from '@angular/router';
import { AlertifyService, AuthService, UtilsService, SecurityKeyService, SecurityKey } from 'core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-securitykey-manager',
  templateUrl: './securitykey-manager.component.html',
  styleUrls: ['./securitykey-manager.component.scss']
})
export class SecurityKeyManagerComponent implements OnInit {

  instanceId: string;
  submitted = false;

  @ViewChild('modal')
  modalRef: BsModalRef;
  modalOpenRef;

  @ViewChild('modalConfirm')
  modalRefConfirm: TemplateRef<any>;
  modalOpenConfirmRef;

  securitykeys: any[];
  model = {} as SecurityKey;
  editForm: FormGroup;
  errorMsg: string = null;

  layout = [
    {label: 'Name', id: 'name'},
    {label: 'Value', id: 'value'},
    {label: 'Actions', type: 'actions-inline'}
  ];
  actions = [
    {label: 'Edit', type: 'edit', icon: 'pen-square'},
    {label: 'Delete', type: 'delete', icon: 'trash-alt'}
  ];

  constructor(
    private modalService: BsModalService,
    private alertify: AlertifyService,
    private securityKeyService: SecurityKeyService,
    private route: ActivatedRoute,
    public auth: AuthService,
    public utils: UtilsService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const id: string = params.get('id');
      if (id) {
        this.loadData(id);
      }
    });

    this.instanceId = this.auth.getInstance();

    this.resetForm();
    this.getSecurityKeys();
  }

  public openModal(template: any) {
    this.modalOpenRef = this.modalService.show(template, {ignoreBackdropClick: true, keyboard: false });
    this.editForm.markAsUntouched();
    this.editForm.updateValueAndValidity();
    this.editForm.get('name').setErrors(null);
    this.editForm.get('value').setErrors(null);
  }

  confirmDelete() {
    this.securityKeyService.deleteSecurityKey(this.model.id).subscribe(data => {
      this.model = data;
    }, error => {
       this.alertify.error('Unable to delete Security Key');
    }, () => {
      this.alertify.success('Security Key deleted successfully');
      this.modalOpenConfirmRef.hide();
      this.getSecurityKeys();
    });
  }

  handleAction(action) {
    const dataEdit: any = action.row;
    this.model = dataEdit;

    if (action.type === 'edit') {
      this.bindForm();
      this.openModal(this.modalRef);
    }
    if (action.type === 'delete') {
      this.modalOpenConfirmRef = this.modalService.show(this.modalRefConfirm);
    }
  }

  submitForm() {
    this.submitted = true;

    if (!this.editForm.valid) return;
    if (this.editForm.value.name === null || this.editForm.value.name === "") return;
    if (this.editForm.value.value === null || this.editForm.value.value === "") return;
    if (!this.editForm.value.id) {
      this.editForm.value.id = uuidv4();
      this.editForm.value.instanceId = this.instanceId;

      this.securityKeyService.addSecurityKey(this.editForm.value).subscribe(data => {
        this.model = data;
      }, error => {
         this.errorMsg = error;
         this.alertify.error('Unable to add Security Key');
      }, () => {
        this.alertify.success('Security Key added successfully');
        this.resetForm();
        this.modalOpenRef.hide();
        this.getSecurityKeys();
      });
    } else {
      this.securityKeyService.editSecurityKey(this.editForm.value).subscribe(data => {
        this.model = data;
      }, error => {
         this.errorMsg = error;
         this.alertify.error('Unable to edit Security Key');
      }, () => {
        this.alertify.success('Security Key edited successfully');
        this.resetForm();
        this.modalOpenRef.hide();
        this.getSecurityKeys();
      });
    }
  }

  loadData(id: any) {
    this.securityKeyService.getSecurityKey(id).subscribe(data => {
      this.model = data;
    }, error => {
       this.alertify.error('Unable to fetch Security Keys');
    }, () => {
      this.bindForm();
      this.openModal(this.modalRef);
    });
  }

  getSecurityKeys() {
    this.securityKeyService.getSecurityKeys(this.instanceId).subscribe(data => {
      this.securitykeys = data;
    }, error => {
       this.alertify.error('Unable to fetch Security Keys');
    }, () => {
      // 'onCompleted' callback.
    });
  }

  bindForm() {
    if (this.model) {
      this.editForm = new FormGroup({
        id: new FormControl(this.model.id),
        instanceId: new FormControl(this.model.instanceId),
        name: new FormControl(this.model.name, Validators.required),
        value: new FormControl(this.model.value, Validators.required)
      });
    }
  }

  resetForm() {
    this.errorMsg = null;
    this.model = {} as SecurityKey;
    this.editForm = new FormGroup({
      id: new FormControl(this.model.id),
      instanceId: new FormControl(this.model.instanceId),
      name: new FormControl(this.model.name, Validators.required),
      value: new FormControl(this.model.value, Validators.required)
    });
    this.submitted = false;
  }

  checkForDuplicateName(name) {
    let exists = this.securitykeys.find(x => x.name === name);
    if (exists) this.editForm.get('name')?.setErrors({ duplicate: true });
  }
}
