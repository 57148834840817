import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { v4 as uuidv4 } from 'uuid';
declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  baseUrl = environment.apiEndpoint + '/dashboard/';

  constructor(private http: HttpClient) { }

  getUserDashboards(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'dashboards');
  }

  setUserDashboards(dashboardSettings: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'dashboards', { dashboards: JSON.stringify(dashboardSettings) });
  }

  getProjectByCategory(settings: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'projectByCategory', settings);
  }

  getProjectHealthByClient(settings: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'projectHealthByClient', settings)
  }

  getProjectByManager(settings: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'projectByManager', settings);
  }
  GetpartnerFraudRate(settings: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'partnerFraudRate', settings);
  }

  getProjectByWorst(settings: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'projectByWorst', settings);
  }

  getProjectFavourite(settings: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'projectFavourite', settings);
  }

  getCustomReports(settings: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'customReports', settings);
  }
  getCustomReport(reportId: any): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'customReport/' + reportId);
  }

  getRevenueByManager(settings: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'revenueByManager', settings);
  }

  getMarginByCategory(settings: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'marginByCategory', settings);
  }

  getMarginOverview(settings: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'marginOverview', settings);
  }

  drawStackedChart(chartId, options, chartData) {
    google.charts.load('current', { packages: ['corechart'] });
    google.charts.setOnLoadCallback(() => {
      const data = google.visualization.arrayToDataTable(chartData);
      const chartDiv = document.getElementById(chartId);
      const chart = new google.visualization.ColumnChart(chartDiv);
      chart.draw(data, options);
    });
  }

  getDataFromRSS(settings: any): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'dataFromRSS', settings);
  }

  updateTitle(id: any, title: string): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'title/' + id, title);
  }
 
  getJobsToBeDone(userIds: Array<string>): Observable<any> {
    return this.http.post(this.baseUrl + 'jobsToBeDone',userIds);
  }
  getHomeBannerInfo(instanceId, offset): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'homeBannerInfo/' + instanceId + '/' + offset);
  }

  getDashboardGroups(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'dashboardGroups');
  }
  getDashboardWidgets(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'dashboardWidgets');
  }
  getDashboardWidgetListView(instanceId): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'dashboardWidgetListView/' + instanceId);
  }
  saveWidgetRoles(slug): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'saveWidgetRoles', slug);
  }
  getTasksCompletedOnTimePercentage(userIds: Array<string>): Observable<any> {
    return this.http.post(this.baseUrl + 'ytd',userIds);
  }
  GetAllMyTasks(filter: any = {}): Observable<any> {
    return this.http.post<any>(this.baseUrl + 'task', filter);
  }

  getUpcomingProjects(userIds: Array<string>): Observable<any> {
    return this.http.post(this.baseUrl + 'upcoming',userIds);
  }

  getDashboardChartInfo(userIds: Array<string>): Observable<any> {
    return this.http.post(this.baseUrl + 'dashboardChartInfo', userIds);
  }

}
