import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AlertifyService, AuthService, DropdownService, RoleService } from 'core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DashboardService } from 'projects/core/src/services/dashboard.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-dashboard-manager',
  templateUrl: './dashboard-manager.component.html',
  styleUrls: ['./dashboard-manager.component.scss']
})
export class DashboardManagerComponent implements OnInit {
  deletePartner: any;
  @ViewChild('modalConfirm')
  modalRefConfirm: TemplateRef<any>;

  openModalRef: any;
  widgets: any[];
  filterColumns = ['name'];
  filterText: string;
  layout = [
    {label: 'Group', id: 'groupName', type: 'text', sortable: true},
    {label: 'Title', id: 'title', type: 'text', sortable: true},
    {label: 'Roles', id: 'roles', type: 'link'},
    {label: 'Actions', type: 'actions-inline'}
  ];
  actions = [
    {label: 'Edit Partner', type: 'edit', icon: 'pen-square'},
    {label: 'Delete Partner', type: 'delete', icon: 'trash-alt'}
  ];
  roles: any = [];

  constructor(
    private modalService: BsModalService,
    private dashboardService: DashboardService,
    private router: Router,
    private alertify: AlertifyService,
    public auth: AuthService,
    private dd: DropdownService,
    private roleService: RoleService) { }

  ngOnInit() {
    this.getDashboardWidgets();
    this.getRoles();
  }

  handleAction(action) {
    const dataEdit: any = action.row;
    if (action.type === 'edit') {
      this.router.navigate(['/partner/edit/' + dataEdit.id]);
    }
    if (action.type === 'delete') {
      this.deletePartner = action.row;
      this.openModalRef = this.modalService.show(this.modalRefConfirm);
    }
  }

  getDashboardWidgets() {
    this.dashboardService.getDashboardWidgetListView(this.auth.getInstance()).subscribe(data => {
      data.forEach(widget => {
        if (widget.roles === '') widget.roles = [];
        else widget.roles = JSON.parse(widget.roles);
      });
      this.widgets = data;
    }, error => {
       this.alertify.error('Unable to fetch dashboard groups');
    });
  }
  getRoles() {
    this.roleService.GetInstanceRoles(this.auth.getInstance(), true).subscribe((data) => {
      this.roles = data;
    });
  }

  getRoleList = (filter) => {
    return of(this.roles);
  }
  updateRole(newValue, widget) {
    widget.roles = newValue;
  }
  saveWidgetRoles() {
    let data = JSON.parse(JSON.stringify(this.widgets));
    data.forEach(widget => {
      widget.roles = JSON.stringify(widget.roles);
    });
    let slug = {
      instanceId: this.auth.getInstance(),
      widgets: data
    };
    this.dashboardService.saveWidgetRoles(slug).subscribe(success => {
      this.alertify.success('Widget roles saved succesfully!');
    }, error => {
      this.alertify.error('There was an error saving widget roles.');
    }, () => {
      this.getDashboardWidgets();
    });
  }
}