import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ComponentsModule, HelpersModule } from 'core';
import { RouterModule } from '@angular/router';
import { ProjectManagerComponent } from './project-manager.component';
import { ProjectListviewsComponent } from './project-listviews/project-listviews.component';
import { ProjectTypesComponent } from './project-types/project-types.component';
import { ProjectTypesMainDetailsComponent } from './project-types/project-types-main-details/project-types-main-details.component';
import { ProjectTypesSectionsComponent } from './project-types/project-types-sections/project-types-sections.component';
import { ProjectTypesStagesComponent } from './project-types/project-types-stages/project-types-stages.component';

@NgModule({
  declarations: [
    ProjectListviewsComponent,
    ProjectManagerComponent,
    ProjectTypesComponent,
    ProjectTypesMainDetailsComponent,
    ProjectTypesSectionsComponent,
    ProjectTypesStagesComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    HelpersModule,
    RouterModule
  ]
})
export class ProjectManagerModule { }
