import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstanceEditComponent } from './instance-edit.component';
import { ComponentsModule } from 'core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [InstanceEditComponent]
})
export class InstanceEditModule { }
