import { Component, OnInit, Output } from '@angular/core';
import {AuthService, AlertifyService} from 'core';
import { Router, ActivatedRoute } from '@angular/router';
import { login } from 'projects/admin/src/app/_models/login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  model = {} as login;
  availableUsers = [];

  constructor(private auth: AuthService, private router: Router, private route: ActivatedRoute, private alertify: AlertifyService) {
    var token = this.route.snapshot.queryParams.token;
    if(token != null){
      this.auth.autoLogIn(token).subscribe(next => {
        if (this.auth.loggedIn()) {
          this.router.navigate(['/usermanager']);
        }
      }, error => {
      })
    }
    else if (this.auth.loggedIn()) {
      this.router.navigate(['/usermanager']);
    }
  }

  ngOnInit() {
  }

  login() {
    this.auth.loginAdmin(this.model).subscribe(next => {
      if(next.user != null){
        if (!this.auth.getUser()) {
          this.alertify.error('Log in failed. Please try again.');
          this.router.navigate(['/login']);
          return;
        }
        if (!this.auth.getUser().roles.includes('Admin') && !this.auth.getUser().roles.includes('SuperAdmin')) {
          this.alertify.error('Admin access required.');
          this.router.navigate(['/login']);
        } else {
          this.alertify.success('You are now logged in.');
          this.router.navigate(['/usermanager']);
        }
      }
      else {
        this.alertify.message('Please select a Workspace to access.');
        this.availableUsers = next.availableUsers;
      }
    }, error => {
      this.alertify.error('Log in failed. Please try again.');
    }, () => {
     
    });
  }
}
