import { Component, OnInit } from '@angular/core';
import { AlertifyService, AuthService, PublicService } from 'core';
import { ResponseIdService } from 'projects/core/src/services/responseid.service';

@Component({
  selector: 'app-responseid',
  templateUrl: './responseid.component.html',
  styleUrls: ['./responseid.component.scss']
})
export class ResponseIdComponent implements OnInit {

  readonly PublicAccessKeyFlags = {
    None: 0,
    OverSubscribed: 1,
    RidAPI: 2,
    RidDeprecated: 4
  };

  instanceId = '';
  licenseDataV3: any;
  fullLicenceData: any;
  filterMode = 'active';

  licenseColsV3 = [
    { label: 'Instance', id: 'client', sortable: true },
    { label: 'ApiKey', id: 'licenseKey' },
    { label: 'Api Name', id: 'licenseName' },
    { label: 'Start', id: 'beginDate', sortable: true, type: 'date' },
    { label: 'End', id: 'endDate', sortable: true, type: 'date' },
    { label: 'Allowance', id: 'transactionsAllowed', sortable: true, type: 'number' },
    { label: 'Total Used', id: 'transactionsTotal', sortable: true, type: 'number' },
    { label: 'Remaining', id: 'totalRemaining', sortable: true, type: 'number' },
    { label: 'Active', id: 'enabled', type: 'toggle'},
    { label: 'Deprecated Calls', id: 'deprecated', type: 'text'},
    {label: 'Actions', type: 'actions-inline'}
  ];

  constructor(
    public auth: AuthService,
    private responseIdService: ResponseIdService,
    private alertify: AlertifyService,
    private publicService: PublicService) {
    this.instanceId = this.auth.getInstance();
  }

  ngOnInit() {
    this.showLicenseV3();
  }

  showLicenseV3() {   
    this.responseIdService.GetResponseIDLicenseDataV3().subscribe(data => {
      data.forEach(e => {
        e.totalRemaining = (e.transactionsAllowed == null)
          ? null
          : e.transactionsAllowed - e.transactionsTotal;

          e.deprecated = (e.flags & this.PublicAccessKeyFlags.RidDeprecated) ? 'Yes' : 'No';
      });
      this.licenseDataV3 = data;
      this.fullLicenceData = data;
      this.setTableData('active');
    }, error => {
       this.alertify.error('Unable to fetch CleaID License info');
    });
  }
  setTableData(mode) {
    this.filterMode = mode;
    this.licenseDataV3 = [];
    this.licenseDataV3 = (mode === 'active') ? this.fullLicenceData.filter(e => e.enabled) : this.fullLicenceData;
  }

  getResponseIDTestPage() {
    return this.publicService.getResponseIDTest();
  }

}
