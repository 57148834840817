import { NgModule } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { UserManagerModule } from './sections/user-manager/user-manager.module';
import { LoginModule } from './sections/login/login.module';
import { HomeModule } from './sections/home/home.module';

import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';

import { CleanidModule } from './sections/cleanid/cleanid.module';
import { InstanceManagerModule } from './sections/instance-manager/instance-manager.module';
import { PartnersManagerModule } from './sections/partners-manager/partners-manager.module';
import { ChangePasswordModule } from './sections/change-password/change-password.module';
import { ResetLinkModule } from './sections/reset-link/reset-link.module';
import { ResetPasswordModule } from './sections/reset-password/reset-password.module';

import { PartnerEditModule } from './sections/partner-edit/partner-edit.module';
import { PartnerAddModule } from './sections/partner-add/partner-add.module';
import { InstanceEditModule } from './sections/instance-edit/instance-edit.module';
import { SurveySettingsModule } from './sections/survey-settings/survey-settings.module';
import { RecruitidModule } from './sections/recruitid/recruitid.module';
import { SiteSetupModule } from './sections/site-setup/site-setup.module';
import { ProjectManagerModule } from './sections/project-manager/project-manager.module';

import { ErrorInterceptorProvider } from './_interceptors/error.interceptor';
import { JwtModule } from '@auth0/angular-jwt';

import { ComponentsModule } from 'core';
import { ClientModule } from './sections/client/client.module';
import { QuotaQuestionsModule } from './sections/quota-questions/quota-questions.module';
import { LiveMonitoringModule } from './sections/live-monitoring/live-monitoring.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup

import { ResponseIdModule } from './sections/responseid/responseid.module';
import { ConfirmationLinkModule } from './sections/confirmation-link/confirmation-link.module';
import { AppIntegrationModule } from './sections/app-integration/app-integration.module';
import { DashboardManagerModule } from './sections/dashboard-manager/dashboard-manager.module';
import { KeyManagerModule } from './sections/keys/key-manager.module';
import { environment } from 'projects/core/src/environments/environment';

export function tokenGetter() {
  return localStorage.getItem('TOKEN_'+environment.apiEndpoint);
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    UserManagerModule,
    PartnersManagerModule,
    InstanceManagerModule,
    CleanidModule,
    ResponseIdModule,
    SurveySettingsModule,
    KeyManagerModule,
    LoginModule,
    ChangePasswordModule,
    PartnerAddModule,
    PartnerEditModule,
    InstanceEditModule,
    ResetLinkModule,
    ConfirmationLinkModule,
    ResetPasswordModule,
    HomeModule,
    HttpClientModule,
    ClientModule,
    RecruitidModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    QuotaQuestionsModule,
    LiveMonitoringModule,
    SiteSetupModule,
    DashboardManagerModule,
    ProjectManagerModule,
    AppIntegrationModule,
    NgIdleKeepaliveModule.forRoot(),
    JwtModule.forRoot({
         config: {
           tokenGetter,
           allowedDomains: ['localhost:44349',
                                'api.navigatorsurveys.com',
                                'api-staging.navigatorsurveys.com',
                                'api-dev.navigatorsurveys.com'],
           disallowedRoutes:  ['localhost:44349/api/auth',
                                'api.navigatorsurveys.com/api/auth',
                                'api-staging.navigatorsurveys.com/api/auth',
                                'api-dev.navigatorsurveys.com/api/auth']
         }
       })
  ],
  providers: [ErrorInterceptorProvider],
  bootstrap: [AppComponent]
})
export class AppModule {}
