import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Webhook } from '../models/webhook';

@Injectable({
  providedIn: 'root'
})
export class SurveyEngineService {
  baseUrl = environment.apiEndpoint + '/surveyengine/';

  constructor(private http: HttpClient) { }

  getWebhooks(vendorId: string): Observable<Webhook[]> { return this.http.get<any>(this.baseUrl + vendorId + '/webhooks'); }
  saveWebhooks(vendorId: string, webhooks: Webhook[]): Observable<Webhook[]> { return this.http.post<any>(this.baseUrl + vendorId + '/webhooks', webhooks); }
  deleteWebhook(vendorId: string, webhookId: string): Observable<Webhook[]> { return this.http.delete<any>(this.baseUrl + vendorId + '/webhooks/' + webhookId); }
  copyWebhooks(vendorId: string, globalVendorId: string): Observable<Webhook[]> { return this.http.post<Webhook[]>(this.baseUrl + vendorId + '/webhooks/copy/' + globalVendorId, {}); }
}