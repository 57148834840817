<div>
  <div class="row">
    <div class="col-12">
      <or-list [items]="hooks" [layout]="layout" [actions]="actions" (action)="handleAction($event, modal)"></or-list>
    </div>
  </div>
  <div class="row" style="margin-right: 8em">
    <div class="text-right" style="width: 100%">
      <div class="btn btn-light" type="button" (click)="createNew(modal)">Add New</div>
    </div>
    <div *ngIf="globalPartnerId !== '00000000-0000-0000-0000-000000000000'" class="text-right" style="width: 100%">
      <div class="btn btn-light" type="button" (click)="cloneWebhooks()">Clone Webhooks</div>
    </div>
  </div>
</div>

<ng-template #modal>
  <div>
    <div class="row m-5">
      <div class="col-3">
        <select class="form-control" [(ngModel)]="eventValue" style="width: 115px;">
          <option value="SURVEY_OVERQUOTA">Overquota</option>
          <option value="SURVEY_TERM">Term</option>
          <option value="SURVEY_DROPOFF">Dropoff</option>
          <option value="SURVEY_QC">QC</option>
          <option value="SURVEY_COMPLETE">Complete</option>
        </select>
      </div>
      <div class="col-3">
        <select class="form-control" [(ngModel)]="methodValue" style="width: 115px">
          <option value="GET">GET</option>
          <option value="POST">POST</option>
          <option value="PUT">PUT</option>
          <option value="PATCH">PATCH</option>
          <option value="DELETE">DELETE</option>
        </select>
      </div>
      <div class="col-6">
        <input type="text" class="form-control" placeholder="http://example.com/oq" [(ngModel)]="endpointValue" style="width: 300px"/>
      </div>
    </div>
  </div>

  <div class="row" style="margin-left: 4em;">
    <tabset style="width: 92%;">
      <tab>
        <ng-template tabHeading>
          <span class="nav-font14">Params</span>
        </ng-template>

        <table style="width: 100%">
          <tr>
            <td class="table-heading">Type</td>
            <td class="table-heading">Key</td>
            <td class="table-heading">Value</td>
            <i style="width:10%" class="fa fa-plus add-icon ml-4" aria-hidden="true" (click)="addUrlParam()"></i>
          </tr>
          <tr *ngFor="let urlParamItem of urlParamValues; let i = index">
            <td style="width:30%" (click)="closeAllInputs(); urlParamItem.typeEdit=true">
              <select [(ngModel)]="urlParamItem.type" class="form-control">
                <option value="freetext">Free text</option>
                <option value="passthrough">Passthrough</option>
                <option value="return">Return</option>
                <option value="secret-key">Secret/Key</option>
                <option value="built-in">Built-in</option>
              </select>
            </td>
            <td style="width:30%" (click)="closeAllInputs(); urlParamItem.keyEdit=true">
              <input 
                *ngIf="urlParamItem.keyEdit" 
                type="text" 
                (blur)="urlParamItem.keyEdit=false" 
                [(ngModel)]="urlParamItem.key"
                class="form-control"
              />
              <div *ngIf="!urlParamItem.keyEdit">{{urlParamItem.key}}</div>
            </td>
            <td style="width:30%" (click)="closeAllInputs(); urlParamItem.valueEdit=true">
              <input 
                *ngIf="urlParamItem.valueEdit && urlParamItem.type != 'built-in' && urlParamItem.type != 'secret-key'"
                type="text" 
                (blur)="urlParamItem.valueEdit=false" 
                [(ngModel)]="urlParamItem.value"
                class="form-control"
              />
              <select *ngIf="urlParamItem.type == 'built-in'" [(ngModel)]="urlParamItem.value" class="form-control">
                <option value="#cleanid#">CleanID Score</option>
                <option value="#orid#">OR1</option>
                <option value="#orvid#">Nav Vendor ID</option>
              </select>
              <select *ngIf="urlParamItem.type == 'secret-key'" [(ngModel)]="urlParamItem.value" class="form-control">
                <option *ngFor="let key of keys" value="{{key.name}}">{{key.name}}</option>
              </select>
              <div *ngIf="!urlParamItem.valueEdit && urlParamItem.type != 'built-in' && urlParamItem.type != 'secret-key'">{{urlParamItem.value}}</div>
            </td>
            <i style="width:10%" class="fa fa-trash ml-4 mr-0 delete-icon" aria-hidden="true" (click)="deleteRow('urlParam', i)"></i>
          </tr>
        </table>
      </tab>

      <tab>
        <ng-template tabHeading>
          <span class="nav-font14">Header</span>
        </ng-template>
        
        <table style="width: 100%">
          <tr>
            <td class="table-heading">Type</td>
            <td class="table-heading">Key</td>
            <td class="table-heading">Value</td>
            <i style="width:10%" class="fa fa-plus add-icon ml-4" aria-hidden="true" (click)="addHeader()"></i>
          </tr>
          <tr *ngFor="let headerItem of headerValues; let i = index">
            <td style="width: 30%" (click)="closeAllInputs(); headerItem.typeEdit=true">
              <select [(ngModel)]="headerItem.type" class="form-control">
                <option value="freetext">Free text</option>
                <option value="passthrough">Passthrough</option>
                <option value="return">Return</option>
                <option value="secret-key">Secret/Key</option>
                <option value="built-in">Built-in</option>
              </select>
            </td>
            <td style="width: 30%" (click)="closeAllInputs(); headerItem.keyEdit=true">
              <input 
                *ngIf="headerItem.keyEdit"
                type="text" 
                (blur)="headerItem.keyEdit=false" 
                [(ngModel)]="headerItem.key" 
                class="form-control" />
              <div *ngIf="!headerItem.keyEdit">{{headerItem.key}}</div>
            </td>
            <td style="width: 30%" (click)="closeAllInputs(); headerItem.valueEdit=true">
              <input 
                *ngIf="headerItem.valueEdit && headerItem.type != 'built-in' && headerItem.type != 'secret-key'" 
                type="text" 
                class="form-control"
                (blur)="headerItem.valueEdit=false" 
                [(ngModel)]="headerItem.value" />
              <select *ngIf="headerItem.type == 'built-in'" [(ngModel)]="headerItem.value" class="form-control">
                <option value="#cleanid#">CleanID Score</option>
                <option value="#orid#">OR1</option>
                <option value="#orvid#">Nav Vendor ID</option>
              </select>
              <select *ngIf="headerItem.type == 'secret-key'" [(ngModel)]="headerItem.value" class="form-control">
                <option *ngFor="let key of keys" value="{{key.name}}">{{key.name}}</option>
              </select>
              <div *ngIf="!headerItem.valueEdit && headerItem.type != 'secret-key' && headerItem.type != 'built-in'">{{headerItem.value}}</div>
            </td>
            <i style="width:10%" class="fa fa-trash ml-4 mr-0 delete-icon" aria-hidden="true" (click)="deleteRow('header', i)"></i>
          </tr>
        </table>
      </tab>

      <tab>
        <ng-template tabHeading>
          <span class="nav-font14">Body</span>
        </ng-template>

        <table style="width: 100%;">
          <tr>
            <td class="table-heading">Type </td>
            <td class="table-heading">Key</td>
            <td class="table-heading">Value</td>
            <i style="width:10%" class="fa fa-plus add-icon ml-4" aria-hidden="true" (click)="addBodyValue()"></i>
          </tr>
          <tr *ngFor="let bodyItem of bodyValues; let i = index">
            <td style="width: 30%" (click)="closeAllInputs(); bodyItem.typeEdit=true">
              <select [(ngModel)]="bodyItem.type" class="form-control">
                <option value="freetext">Free text</option>
                <option value="passthrough">Passthrough</option>
                <option value="return">Return</option>
                <option value="secret-key">Secret/Key</option>
                <option value="built-in">Built-in</option>
              </select>
            </td>
            <td style="width: 30%" (click)="closeAllInputs(); bodyItem.keyEdit=true">
              <input 
                *ngIf="bodyItem.keyEdit" 
                type="text" 
                (blur)="bodyItem.keyEdit=false" 
                [(ngModel)]="bodyItem.key" 
                class="form-control" />
              <div *ngIf="!bodyItem.keyEdit">{{bodyItem.key}}</div>
            </td>
            <td style="width: 30%" (click)="closeAllInputs(); bodyItem.valueEdit=true">
              <input 
                *ngIf="bodyItem.valueEdit && bodyItem.type != 'built-in' && bodyItem.type != 'secret-key'" 
                type="text"
                (blur)="bodyItem.valueEdit=false" 
                [(ngModel)]="bodyItem.value" 
                class="form-control" />
              <select *ngIf="bodyItem.type == 'built-in'" [(ngModel)]="bodyItem.value" class="form-control">
                <option value="#cleanid#">CleanID Score</option>
                <option value="#orid#">OR1</option>
                <option value="#orvid#">Nav Vendor ID</option>
              </select>
              <select *ngIf="bodyItem.type == 'secret-key'" [(ngModel)]="bodyItem.value" class="form-control">
                <option *ngFor="let key of keys" value="{{key.name}}">{{key.name}}</option>
              </select>
              <div *ngIf="!bodyItem.valueEdit && bodyItem.type != 'secret-key' && bodyItem.type != 'built-in'">{{bodyItem.value}}</div>
            </td>
            <i style="width:10%" class="fa fa-trash ml-4 mr-0 delete-icon" aria-hidden="true" (click)="deleteRow('body', i)"></i>
          </tr>
        </table>
      </tab>
    </tabset>
  </div>
  <div class="row" style="margin-right: 2.5em; margin-bottom: 1.25em">
    <div class="text-right" style="width: 100%">
      <div class="btn btn-light mr-3" type="button" (click)="cancel()">Cancel</div>
      <button class="btn or-primary-bg text-white" type="submit"  (click)="save()">Save</button>
    </div>
  </div>
</ng-template>