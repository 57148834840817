import { Component, OnInit } from '@angular/core';
import { AuthService} from 'core';

@Component({
  selector: 'app-project-manager',
  templateUrl: './project-manager.component.html',
  styleUrls: ['./project-manager.component.scss']
})
export class ProjectManagerComponent implements OnInit {

  tab = 'type';
  typeSelected;

  constructor(public auth: AuthService) { }

  ngOnInit() {
    this.tab = (this.auth.isSuperAdmin()) ? 'type' : 'list';
  }

  selectedProjectType(selected) {
    this.typeSelected = selected;
  }
}
