import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, ReactiveFormsModule, FormArray } from '@angular/forms';
import { AlertifyService, AuthService, UtilsService, QuotaQuestion, QuotaService, ProjectService } from 'core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { idLocale } from 'ngx-bootstrap/chronos';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-quota-edit',
  templateUrl: './quota-edit.component.html',
  styleUrls: ['./quota-edit.component.scss'],
  providers: [DatePipe]
})
export class QuotaEditComponent implements OnInit {

  @ViewChild('modalPreviewQuestion')
  modalPreviewQuestion: TemplateRef<any>;
  openModalRef: any;

  model: QuotaQuestion;
  modelGeo1: QuotaQuestion;

  optionForm: FormGroup;
  submitted = false;
  tooltip = "";
  locations: any[] = [];
  showOptions = true;
  showDefaults = false;
  showLocations = false;
  hhiInterval = 5000;
  hhiMin = 15000;
  hhiMax = 150000;
  regionData;
  questionTotal = null;
  questionTotal2 = null;
  hhiList = [];
  selectedType = '';
  integerPattern = { 9: { pattern: new RegExp('^[0-9]*$'), optional: true } };
  todaysDate = null;

  questionFormGeo1: FormGroup;
  questionForm: FormGroup;

  categories = [
    {value: 'Consumer', name: 'Consumer'},
    {value: 'B2B', name: 'B2B'},
    {value: 'HealthCare', name: 'Health Care'}
  ];

  types = [
    {value: 'Gender', name: 'Gender', inputType: 'RadioButtons'},
    {value: 'Age', name: 'Age', inputType: 'UserInput'},
    {value: 'Ethnicity', name: 'Ethnicity', inputType: 'RadioButtons'},
    {value: 'HouseholdIncome', name: 'Household Income', inputType: 'DropDown'},
    {value: 'State', name: 'State', inputType: 'UserInput'},
    {value: 'Region', name: 'Region', inputType: 'UserInput'},
    {value: 'Division', name: 'Division', inputType: 'UserInput'},
    {value: 'Hispanic', name: 'Hispanic', inputType: 'RadioButtons'}
  ];

  constructor(
    private route: ActivatedRoute,
    private quotaService: QuotaService,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private projectService: ProjectService,
    private router: Router,
    public auth: AuthService,
    public utils: UtilsService,
    public datePipe: DatePipe) { 
      this.questionForm = new FormGroup({
        id: new FormControl('', [Validators.required]),
        question: new FormControl('', [Validators.required]),
        urlIdentifier: new FormControl(''),
        category: new FormControl(''),
        type: new FormControl(''),
        description: new FormControl(''),
        inputType: new FormControl(''),
        instanceId: new FormControl(''),
        preferNotToAnswer: new FormControl(false),
        options: new FormArray([]),
        defaults: new FormArray([])
      });
    
      this.questionFormGeo1 = new FormGroup({
        id: new FormControl('', [Validators.required]),
        question: new FormControl('', [Validators.required]),
        urlIdentifier: new FormControl(''),
        category: new FormControl(''),
        type: new FormControl(''),
        description: new FormControl(''),
        inputType: new FormControl(''),
        instanceId: new FormControl(''),
        preferNotToAnswer: new FormControl(false),
        options: new FormArray([]),
        defaults: new FormArray([])
      });
      
    }

  ngOnInit() {
    this.todaysDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.route.paramMap.subscribe(params => {

        const id: string = params.get('id');
        if (id) {
          this.loadData(id);
          }
        else {
          if (history.state.data != null) {
            if (history.state.data.type != 'Geo/Zip') {
              this.model = history.state.data;
              this.bindForm();
            }
            else {
              this.model = history.state.data.division;
              this.modelGeo1 = history.state.data.region;
              this.bindGeoForm();
            }
          } else {
            this.router.navigate(['/quota-questions']);
          }
        }
    });

      for (var n = this.hhiMin; n <= this.hhiMax; n += this.hhiInterval) {
        this.hhiList.push(n);
      }
  }

  togglePreferNotToAnswer(event) {
    this.model.preferNotToAnswer = event;
  }

  questionUpdate() {

    this.submitted = true;

    if (this.selectedType === 'HouseholdIncome') {
      var i = 0;
      var defaults = this.questionForm.get('defaults').value;

      while (i < defaults.length){
        this.questionForm.get(['defaults']).get([i]).get('order').setValue(defaults[i].order)
        i++;
        }

      }

    if (!this.questionForm.valid) {

      return;
    }

    // fetch the inputType from types[] based on selectedType

    let inputType = this.types.filter(item => item.value === this.selectedType).map(item => item.inputType)[0];

    if (this.selectedType === 'Geo/Zip') {
      inputType = this.types.filter(item => item.value === 'Division').map(item => item.inputType)[0];
     }
     this.questionForm.controls['inputType'].setValue(inputType);


    // when type is Ethnicity or Gender, we fetch the defaults from options[]
    if (this.selectedType === 'Ethnicity' || this.selectedType === 'Gender' || this.selectedType === 'Hispanic' ) {
      let defaults = ((this.questionForm.get('options') as FormArray).controls).filter(x => x.value != 'pnta');
      if (defaults.length > 0) {
        (this.questionForm.get('defaults') as FormArray).clear();
        defaults.forEach(item => {
          (this.questionForm.get('defaults') as FormArray).push(this.addOptionDefault(item.value.value, item.value.default));
        });
      }
    };

    this.quotaService.EditQuestion(this.questionForm.value).subscribe( data => {
      this.model = data;

    }, error => {
       this.alertify.error('Unable to edit Quota Question');
    },
    () => {
      if (this.selectedType === 'Geo/Zip') {
        this.questionFormGeo1.controls['inputType'].setValue(inputType);
        var q = this.questionForm.get('question').value;
        this.questionFormGeo1.controls['question'].setValue(q);

        this.quotaService.EditQuestion(this.questionFormGeo1.value).subscribe( data => {
          this.modelGeo1 = data;
        }, error => {
           this.alertify.error('Unable to edit Quota Question');
        },
        () => {
          this.alertify.success('Quota Question edited successfully');
          this.router.navigate(['/quota-questions']);
        });

      }
      else {
        this.alertify.success('Quota Question edited successfully');
      }

      this.router.navigate(['/quota-questions']);
    });
  }

  addOption(_default: number = 0): FormGroup {
    return new FormGroup({
      id: new FormControl(null),
      value: new FormControl(null, [Validators.required]),
      label: new FormControl(null),
      default: new FormControl(_default, [Validators.required]),
      demo: new FormControl(null),
      order: new FormControl(this.questionForm.value.options.length)
    });
  }

  addOptionDefault(value: string = '', percent: number = 0): FormGroup {
    var defaultMin = 0;
    var defaultMax = 0;
    if (this.selectedType=='HouseholdIncome'){
      defaultMin = 0;
      defaultMax = this.hhiMin - 1;
    }
    else if (this.selectedType === 'Age'){
      defaultMin = 18;
      defaultMax = 24;
    }
    return new FormGroup({
      id: new FormControl(null),
      min: new FormControl(defaultMin, [Validators.required]),
      max: new FormControl(defaultMax, [Validators.required]),
      percent: new FormControl(percent, [Validators.required]),
      value: new FormControl(value),
      order: new FormControl(this.questionForm.value.defaults.length)
    });
  }

  addOptionClick() {
    (this.questionForm.get('options') as FormArray).push(this.addOption());
  }

  addOptionDefaultClick() {
    (this.questionForm.get('defaults') as FormArray).push(this.addOptionDefault());
  }

  removeOptionClick(i: number) {

    (this.questionForm.get('options') as FormArray).removeAt(i);

    if (i != this.questionForm.value.options.length) {
      while (i < this.questionForm.value.options.length) {
        this.questionForm.get('options').get([i]).get('order').setValue(i)
        i += 1
      }
    }
    (this.questionForm.get('options') as FormArray).removeAt(i);
    
  }

  removeOptionDefaultClick(i: number) {
    (this.questionForm.get('defaults') as FormArray).removeAt(i);

    if (i != this.questionForm.value.options.length) {
      while (i < this.questionForm.value.defaults.length) {
        this.questionForm.get('defaults').get([i]).get('order').setValue(i)
        i += 1
      }
    }
    (this.questionForm.get('defaults') as FormArray).removeAt(i);
  }

  loadData(id: any) {
    this.quotaService.GetQuestion(id).subscribe( data => {
      this.model = data;
    }, error => {
       this.alertify.error('Unable to fetch Quota Question');
    },
    () => {
      this.bindForm();
    });
  }

  bindGeoForm() {
    this.questionTotal2 = 0;
    this.questionTotal = 0;

    if (this.model && this.modelGeo1) {
      this.questionForm = new FormGroup({
        id: new FormControl(this.model.id, [Validators.required]),
        question: new FormControl(this.model.question, [Validators.required]),
        urlIdentifier: new FormControl((this.model.urlIdentifier || 'zip')),
        category: new FormControl(this.model.category),
        type: new FormControl(this.model.type),
        description: new FormControl(this.model.description),
        inputType: new FormControl(this.model.inputType),
        instanceId: new FormControl(this.model.instanceId),
        preferNotToAnswer: new FormControl(this.model.preferNotToAnswer),
        options: new FormArray([]),
        defaults: new FormArray([])
      });

      this.questionFormGeo1 = new FormGroup({
        id: new FormControl(this.modelGeo1.id, [Validators.required]),
        question: new FormControl(this.modelGeo1.question, [Validators.required]),
        urlIdentifier: new FormControl((this.modelGeo1.urlIdentifier|| 'zip')),
        category: new FormControl(this.model.category),
        type: new FormControl(this.modelGeo1.type),
        description: new FormControl(this.modelGeo1.description),
        inputType: new FormControl(this.modelGeo1.inputType),
        instanceId: new FormControl(this.modelGeo1.instanceId),
        preferNotToAnswer: new FormControl(this.modelGeo1.preferNotToAnswer),
        options: new FormArray([]),
        defaults: new FormArray([])
      });

      this.model.options.forEach(item => {
        (this.questionForm.get('options') as FormArray).push(
          new FormGroup({
            id: new FormControl(item.id, [Validators.required]),
            value: new FormControl(item.value),
            label: new FormControl(item.value),
            default: new FormControl(0, [Validators.required]),
            demo: new FormControl(item.demo),
            order: new FormControl(item.order)
          })
        );
      });

      this.modelGeo1.options.forEach(item => {
        (this.questionFormGeo1.get('options') as FormArray).push(
          new FormGroup({
            id: new FormControl(item.id, [Validators.required]),
            value: new FormControl(item.value),
            label: new FormControl(item.value),
            default: new FormControl(0, [Validators.required]),
            demo: new FormControl(item.demo),
            order: new FormControl(item.order)
          })
        );
      });

      (this.questionForm.controls['defaults'] as FormArray).clear()

      this.sort(this.model.defaults, 'order');

      this.model.defaults.forEach(item => {
        this.questionTotal += item.percent;
        (this.questionForm.get('defaults') as FormArray).push(
          new FormGroup({
            id: new FormControl(item.id, [Validators.required]),
            min: new FormControl(item.min, [Validators.required]),
            max: new FormControl(item.max, [Validators.required]),
            value: new FormControl(item.value),
            percent: new FormControl(item.percent, [Validators.required]),
            order: new FormControl(this.questionForm.value.defaults.length)
          })
        );
      });


      (this.questionFormGeo1.controls['defaults'] as FormArray).clear()

      this.sort(this.modelGeo1.defaults , 'order');

      this.modelGeo1.defaults.forEach(item => {
        this.questionTotal2 += item.percent;
        (this.questionFormGeo1.get('defaults') as FormArray).push(
          new FormGroup({
            id: new FormControl(item.id, [Validators.required]),
            min: new FormControl(item.min, [Validators.required]),
            max: new FormControl(item.max, [Validators.required]),
            value: new FormControl(item.value),
            percent: new FormControl(item.percent, [Validators.required]),
            order: new FormControl(this.questionFormGeo1.value.defaults.length)

          })
        );
      });
      this.selectedType = 'Geo/Zip';
      this.tooltip = 'The values that are passed through will be the exact number respondents input into the text box.'
    }
  }


  bindForm() {

    this.questionTotal = 0;

    if (this.model) {
      this.questionForm = new FormGroup({
        id: new FormControl(this.model.id, [Validators.required]),
        question: new FormControl(this.model.question, [Validators.required]),
        urlIdentifier: new FormControl((this.model.urlIdentifier || this.model.type.toLowerCase())),
        category: new FormControl(this.model.category),
        type: new FormControl(this.model.type),
        description: new FormControl(this.model.description),
        inputType: new FormControl(this.model.inputType),
        instanceId: new FormControl(this.model.instanceId),
        preferNotToAnswer: new FormControl(this.model.preferNotToAnswer),
        options: new FormArray([]),
        defaults: new FormArray([])
      });

      // fetch defaults from defaults[] and add to options[]
      if (this.model.type === 'Ethnicity' || this.model.type === 'Gender' || this.model.type === 'Hispanic') {

        this.sort(this.model.options, 'order')

        this.model.options.forEach(item => {
          if (item.value != 'pnta') {
            let _default = this.model.defaults.find(x => x.value == item.value);
            this.questionTotal += _default.percent;

            (this.questionForm.get('options') as FormArray).push(
              new FormGroup({
                id: new FormControl(item.id, [Validators.required]),
                value: new FormControl(item.value, [Validators.required]),
                label: new FormControl(item.value),
                default: new FormControl(_default?.percent, [Validators.required]),
                demo: new FormControl(item.demo),
                order: new FormControl(this.questionForm.value.options.length)
              })
            );
          }
          else {
            (this.questionForm.get('options') as FormArray).push(
              new FormGroup({
                id: new FormControl(item.id, [Validators.required]),
                value: new FormControl(item.value, [Validators.required]),
                label: new FormControl(item.value),
                default: new FormControl(0, [Validators.required]),
                demo: new FormControl(item.demo),
                order: new FormControl(this.questionForm.value.options.length)
              })
            );
          }


        });
      } else {
        var labelForOption;
        this.model.options.forEach(item => {
          if (this.model.type == 'HouseholdIncome') {
            labelForOption = item.label;
          }
          else {
            labelForOption = item.value
          }
          (this.questionForm.get('options') as FormArray).push(
            new FormGroup({
              id: new FormControl(item.id, [Validators.required]),
              value: new FormControl(item.value, [Validators.required]),
              label: new FormControl(labelForOption),
              default: new FormControl(0, [Validators.required]),
              demo: new FormControl(item.demo),
              order: new FormControl(item.order)
            })
          );
        });

      };
      this.sort(this.model.defaults, 'order')

      this.model.defaults.forEach(item => {
        if (this.model.type != 'Gender' && this.model.type != 'Ethnicity' && this.model.type != 'Hispanic') this.questionTotal += item.percent;

        (this.questionForm.get('defaults') as FormArray).push(
          new FormGroup({
            id: new FormControl(item.id, [Validators.required]),
            min: new FormControl(item.min, [Validators.required]),
            max: new FormControl(item.max, [Validators.required]),
            value: new FormControl(item.value),
            percent: new FormControl(item.percent, [Validators.required]),
            order: new FormControl(this.questionForm.get('defaults').value.length)
          })
        );
      });
        this.changeView(this.model.type);
    }

    if (this.selectedType === 'Age') {
      this.tooltip = 'The values that are passed through will be the exact number respondents input into the text box.'
    }
    else if (this.selectedType === 'HouseholdIncome') {
      this.tooltip = 'The values that are passed through will be the starting income value of the range selected by the respondent.'
    }
    else {
      this.tooltip = 'These are the codes that will pass through in the URL to your survey after the specified demo tag.'    
    }
  }

  calculateTotalPercent()
  {
    var newTotal = 0;

    if (this.model.type == 'Gender' || this.model.type == 'Ethnicity' || this.model.type == 'Hispanic' ){
      this.questionForm.get('options').value.filter(x => x.value != 'pnta').forEach(a => newTotal += a.default );
      this.questionTotal = newTotal;
    }
    else {
      this.questionForm.get('defaults').value.forEach(a => newTotal += a.percent );
      this.questionTotal = newTotal;
    }
  }

  sort(array, sortBy) {
    var A;
    var B
    array.sort((a, b) => {

      if (sortBy === 'order')
      {
        A = a.order;
        B = b.order;
      }
      else if (sortBy === 'min')
      {
        A = a.min
        B = b.min
      }
      else if (sortBy === 'minAge')
      {
        A = a.targetGroup.minAge
        B = b.targetGroup.minAge
      }
      else if (sortBy === 'minHHI')
      {
        A = a.targetGroup.minHHI
        B = b.targetGroup.minHHI
      }
    
      let comparison = 0;
      if (A > B) {
        comparison = 1;
      } else if (A < B) {
        comparison = -1;
      }
      return comparison;
    });
  }

  changeView(type: string) {

    this.selectedType = type;
  }

  changeOrder(type, index, value, form = '') {

    var qForm;
    if (!form || form === 'division') {
      qForm = this.questionForm;
    }
    else {
      qForm = this.questionFormGeo1;
    }
    
    // if (qForm === 'Household Income')
    // {
    //   this.sort(qForm, 'minHHI')
    // }
    // else if (qForm === 'Age')
    // {
    //   this.sort(qForm, 'minAge')
    // }
    // else{
    //   this.sort(qForm, 'order')
    // }
    

    var lastIndex = qForm.get([type]).value.length - 1
    if ((index == 0 && value == -1) || (index == lastIndex && value == 1)) {}
    else {
      var newIndex = index + value;

      
      qForm.get([type]).get([index]).get('order').setValue(newIndex)
      qForm.get([type]).get([newIndex]).get('order').setValue(index)
  
      if (type == 'defaults')
      {
        this.sort(qForm.value.defaults, 'order');  
        var list = (qForm.get('defaults') as FormArray).value;
        (qForm.controls['defaults'] as FormArray).clear()
  
        list.forEach(item => {
          (qForm.get('defaults') as FormArray).push(
            new FormGroup({
              id: new FormControl(item.id),
              value: new FormControl(item.value),
              max: new FormControl(item.max),
              min: new FormControl(item.min),
              percent: new FormControl(item.percent),
              order: new FormControl(item.order)
            })
          )
        });
      }
      else if (type == 'options')
      {
        this.sort(qForm.value.options, 'order');  
  
        var list = (qForm.get('options') as FormArray).value;
  
        (qForm.controls['options'] as FormArray).clear()
  
        list.forEach(item => {
          (qForm.get('options') as FormArray).push(
            new FormGroup({
              id: new FormControl(item.id),
              value: new FormControl(item.value),
              default: new FormControl(item.default),
              demo: new FormControl(item.demo),
              order: new FormControl(item.order)
            })
          )
        });
      }
    }
  }

  openPreview() {
    this.openModalRef = this.modalService.show(this.modalPreviewQuestion);
  }

  preferNotToAnswerChanged(newValue) {
    if (newValue == false) {
      // remove from questionForm
      var j = this.questionForm.get('options').value.findIndex( x=> x.value === 'pnta');
      if (j > -1) this.removeOptionClick(j);
    }
    else {
      // add prefer not to answer to question form
      var preferNotToAnswer = {
        id: null,
        value: 'pnta',
        label: 'Prefer not to answer',
        default: null,
        demo: '',
        order: 9999
      };
      this.questionForm.get('options').value.push(preferNotToAnswer);
    }
  }
}
