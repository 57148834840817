<div class="card">
  <div class="card-header d-flex align-items-center bg-white border-bottom-0">
    <span class="h6 or-primary">Sections</span>
  </div>
  <div class="px-4 py-2">
    <div class="table-responsive">
      <table class="table table-light">
        <thead>
          <tr>
            <th class="nowrap"></th>
            <th class="nowrap">Title</th>
            <th class="nowrap">Type</th>
            <th class="nowrap">Role</th>
            <th class="nowrap"></th>
            <th class="nowrap"></th>
            <th class="nowrap"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let item of sections; let idx = index">
            <tr>
              <td class="align-middle text-center cursor-pointer" (click)="item.open = !item.open" style="width: 50px;">
                <i *ngIf="item.type=='card'" class="fas {{ !item.open ? 'fa-plus-circle' : 'fa-minus-circle' }} fa-lg text-secondary"></i>
              </td>
              <td class="align-middle nowrap">
                <input [(ngModel)]="item.title" class="form-control" name="title-{{idx}}">
              </td>
              <td class="align-middle nowrap">
                <select class="form-control" [(ngModel)]="item.type" name="type-{{idx}}">
                  <option selected value="card">Card</option>
                  <option value="schedule">Schedule</option>
                  <option value="programming">Programming</option>
                  <option value="links-and-vendors">Links & Vendors</option>
                  <option value="quotas">Quotas</option>
                  <option value="id-suite">QC FLow</option>
                  <option value="finance">Finance</option>
                  <option value="vendor-finance">Vendor Finance</option>
                  <option value="close">Internal Closure Process</option>
                  <option value="close-external">External Closure Process</option>
                </select>
              </td>

              <td class="align-middle nowrap">
                <or-type-ahead [selectedValues]="item.roles === null ? [] : item.roles"  [labelField]="'name'" [valueField]="'id'" multi=true [dataCallback]="getRoleList" placeholder="Select a Role" (valueChange)="updateRole($event, item)"></or-type-ahead>
              </td>
              <td class="align-middle nowrap">
                <button (click)="changeOrder('down', item, idx);" type="button" class="btn btn-xs border-0 opacity-70" *ngIf="idx < sections.length - 1">
                  <i class="fa fa-arrow-down"></i>
                </button>
              </td>
              <td class="align-middle nowrap">
                <button (click)="changeOrder('up', item, idx);" type="button" class="btn btn-xs border-0 opacity-70" *ngIf="idx > 0">
                  <i class="fa fa-arrow-up"></i>
                </button>
              </td>
              <td class="align-middle nowrap">
                <div class="d-flex justify-content-center align-items-center">
                  <button (click)="sections = arrayRemove(sections, item)" type="button" type="button" class="btn btn-xs btn-outline-danger">
                    <i class="fa fa-times"></i>
                  </button>
                </div>
              </td>

            </tr>
            <tr *ngIf="item?.open && item.type=='card'" >
              <td class="bg-white" colspan="4">
                <div class="row mx-5">
                  <div class="col mx-5">
                    <div class="pb-1">
                      <span class="h6 or-primary">Cards</span>
                    </div>
                  </div>
                </div>
                <div class="row mx-5">
                  <div class="col mx-5">
                    <div class="table-responsive">
                      <table class="table table-sm">
                        <thead>
                          <tr>
                            <th class="nowrap">Title</th>
                            <th class="nowrap">Type</th>
                            <th class="nowrap">Role</th>
                            <th class="nowrap"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let card of item?.cards; let idxcard = index">
                            <td class="align-middle nowrap bg-white">
                              <input [(ngModel)]="card.cardTitle" class="form-control" name="cardTitle-{{idx}}-{{idxcard}}">
                            </td>
                            <td class="align-middle nowrap bg-white">
                              <select class="form-control" [(ngModel)]="card.cardType" name="cardType-{{idx}}-{{idxcard}}">
                                <option value="project-info">Project Info</option>
                                <option value="survey-field-plan">Survey Field Plan </option>
                                <option value="survey-finance">Survey Finance </option>
                                <option value="survey-performance-chart">Survey Performance Chart</option>
                                <option value="survey-performance-table">Survey Performance Table</option>
                                <option value="reconciliation">Reconciliation</option>
                                <option value="idsuite">IDSuite</option>
                                <option value="idsuite-client">IDSuite Client</option>
                                <option value="response-id">ResponseID</option>
                                <option value="survey-cleanid">Survey CleanID </option>
                                <option value="survey-dedupe">Survey Dedupe</option>
                                <option value="survey-partner-setup">Survey Vendor Setup</option>
                                <option value="vendor">Vendor Card</option>
                              </select>
                            </td>
                            <td class="align-middle nowrap">
                              <or-type-ahead [selectedValues]="card.roles === null ? [] : card.roles"  [labelField]="'name'" [valueField]="'id'"  multi=true [dataCallback]="getRoleList" placeholder="Select a Role" (valueChange)="updateRole($event, card)"></or-type-ahead>
                            </td>
                            <td class="align-middle nowrap bg-white">
                              <div class="d-flex justify-content-center align-items-center">
                                <button (click)="item.cards = arrayRemoveCard(item, item.cards, card)" type="button" type="button" class="btn btn-xs btn-outline-secondary">
                                  <i class="fa fa-times"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="row mx-5">
                  <div class="col mx-5">
                    <button type="button" class="btn btn-sm btn-outline-secondary inline mb-3" (click)="item.cards.push(addCard())"><em class="fa fa-plus-circle"></em> Add Card</button>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <hr>
    <div class="col px-0">
      <button type="button" class="btn btn-sm btn-outline-secondary inline mb-3" (click)="addSection()"><em class="fa fa-plus-circle"></em> Add Section</button>
    </div>
  </div>
</div>
