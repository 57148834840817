import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertifyService, AuthService, InstanceService, ProjectService, RoleService, SiteSection, UtilsService } from 'core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { of } from 'rxjs';

@Component({
  selector: 'app-site-setup-edit',
  templateUrl: './site-setup-edit.component.html',
  styleUrls: ['./site-setup-edit.component.scss']
})
export class SiteSetupEditComponent implements OnInit {

  @Input() public set editSection(data) {
    this._editSection = data;
    this.instanceId = this.auth.getInstance();
    this.getSections();
    this.getProjectTypes();
  }
  @Input() public set mode(data) {
    this._mode = data;
  }

  _editSection;
  _mode;
  submitted = false;
  sections: any[];
  instanceId = '';
  editForm: FormGroup;
  model: SiteSection;
  sectionTypes: any[] = [];
  instanceRoles: any[] = [];
  projectTypes = [];
  roles: any[] = [];
  selectedRoles: any[] = [];

  @Output() saveevent = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    private alertify: AlertifyService,
    public utils: UtilsService,
    private instanceService: InstanceService,
    private projectService: ProjectService,
    public auth: AuthService,
    public roleService: RoleService) { }

  ngOnInit(): void {

    this.getSectionTypes();
    this.getInstanceRoles();

    if (this._mode === 'add') {

      this.editForm = new FormGroup({
        id: new FormControl(''),
        instanceId: new FormControl(this.instanceId, [Validators.required]),
        sortOrder: new FormControl(0),
        type: new FormControl(null),
        projectTypeId: new FormControl(null),
        otherInstanceId : new FormControl(null),
        label: new FormControl('', [Validators.required]),
        icon: new FormControl(''),
        parentId: new FormControl(null),
        instanceRoleId: new FormControl(null)
      });

    } else {
      this.loadData();
    }
  }

  getProjectTypes() {
    this.projectService.GetProjectTypes(this.instanceId).subscribe((data) => {
      this.projectTypes = data;
    },
    error => {
      this.alertify.error('There was an error getting project types');
    });
  }

  submitForm() {

    this.submitted = true;

    if (!this.editForm.valid) {
      return;
    }

    this.editForm['instanceId'] = this.instanceId;
    if(this.editForm.value['otherInstanceId'] === ''){
      this.editForm.get('otherInstanceId').setValue(null);
    }

    this.editForm.addControl("roles", new FormControl(this.selectedRoles.map(x => x.id)));

    if (this.editForm.value.id !== '') {
      if (this.editForm.value.instanceRoleId === 'null') this.editForm.value.instanceRoleId = null;
      this.instanceService.editSection(this.editForm.value).subscribe(data => {
        this.model = data;
        this.saveevent.emit(this.model);
        this.alertify.success('Section edited successfully');
      }, error => {
         this.alertify.error('Unable to edit section');
      });

    } else {
      this.instanceService.addSection(this.editForm.value).subscribe(data => {
        this.model = data;
        this.saveevent.emit(this.model);
        this.alertify.success('Section added successfully');
      }, error => {
         this.alertify.error('Unable to add Section');
      });
    }

    this.bsModalRef.hide();
  }

  loadData() {

    this.model = this._editSection;

    this.editForm = new FormGroup({
      id: new FormControl(this.model.id),
      instanceId: new FormControl(this.model.instanceId, [Validators.required]),
      sortOrder: new FormControl(this.model.sortOrder),
      type: new FormControl(this.model.type),
      projectTypeId: new FormControl(this.model.projectTypeId),
      otherInstanceId: new FormControl(this.model.otherInstanceId),
      label: new FormControl(this.model.label, [Validators.required]),
      icon: new FormControl(this.model.icon),
      parentId: new FormControl(this.model.parentId),
      instanceRoleId: new FormControl(this.model.instanceRoleId)
    });

  }

  getSections() {
    this.instanceId = this.auth.getInstance();

    this.instanceService.getSections(this.instanceId).subscribe(data => {
      this.sections = data;
    }, error => {
       this.alertify.error('Unable to get parent sections');
    });
  }

  onIconPickerSelect(icon: string): void {
    this.editForm.get('icon').setValue(icon);
  }

  getSectionTypes() {
    this.sectionTypes = [
      { name: 'Home', value: 'Home' },
      { name: 'Home (Manager)', value: 'HomeManager' },
      { name: 'Team', value: 'Team' }
    ];

    const access = this.auth.getUser().access;

    if (access.clientEnable) {
      this.sectionTypes.push({ name: 'Clients', value: 'Clients' });
      this.sectionTypes.push({ name: 'Client Report', value: 'ReportsClient' });
      this.sectionTypes.push({ name: 'Client Report-Coll', value: 'ReportsClientColl' });
    }

    if (access.partnerEnable) {
      this.sectionTypes.push({ name: 'Vendors', value: 'Vendors' });
      this.sectionTypes.push({ name: 'Vendor Report', value: 'ReportsVendor' });
    }

    if (access.projectEnable) {
      this.sectionTypes.push({ name: 'Project List', value: 'ProjectList' });
      this.sectionTypes.push({ name: 'Performance', value: 'ReportsPerformance' });
      this.sectionTypes.push({ name: 'Audit', value: 'ReportsAudit' });
      this.sectionTypes.push({ name: 'Report Builder', value: 'ReportsReportBuilder' });
      this.sectionTypes.push({ name: 'Task List', value: 'TaskList' });
      this.sectionTypes.push({ name: 'Workload Reports', value: 'ReportsWorkload' });
    }

    if (access.cleanIDEnable) {
      this.sectionTypes.push({ name: 'CleanID Reports', value: 'ReportsCleanID' });
      this.sectionTypes.push({ name: 'CleanID Nav Reports', value: 'ReportsCleanIDNav' });
    }
    if (access.responseIDEnable) {
      this.sectionTypes.push({ name: 'ResponseID API Reports', value: 'ReportsResponseIDApi' });
      this.sectionTypes.push({ name: 'ResponseID Nav Reports', value: 'ReportsResponseIDNav' });
    }
    this.sectionTypes.push({ name: 'Licensee Report', value: 'Licensee' });

  }

  getInstanceRoles() {
    this.roleService.GetInstanceRoles(this.instanceId, true).subscribe((data) => {
      this.roles = data;
      // load roles
      if (this.model !== null) {
        this.model.roles.forEach(item => {
          const role = this.roles.find(x => x.id === item);
          if (role !== null) {
            this.selectedRoles.push(role);
          }
        });
      }
      if(this.selectedRoles.length === 0)
      {
        this.selectedRoles.push(this.roles.find(x => x.id === 'Internal'));
      }

    },
    error => {
      this.alertify.error('There was an error getting roles');
    });
  }

  getRoleList = (filter) => {
    return of(this.roles);
  }

  updateRole(newValue) {
    this.selectedRoles = newValue;
  }

}
