@if (auth.isInternal() && !isClientview) {
  <div class="btn-group notifications ml-3" dropdown (onShown)="openMessages()" container="body">
    <div class="bell d-flex align-items-center justify-content-center position-relative">
      <div class="d-flex align-items-center justify-content-center cursor-pointer" type="button" dropdownToggle aria-controls="dropdown-animated">
        <i class="far fa-bell nav-secondary fa-lg"></i>
        @if (auth.getUser()?.hasNewMessages) {
          <span class="count">&nbsp;</span>
        }
      </div>
    </div>
    <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu py-0 shadow notifications-content" role="menu">
      <li class="py-2 or-primary-bg text-white">Notifications
        <a [routerLink]="['/alert-settings']" class="float-right">
          <i class="fas fa-expand cursor-pointer opacity-90 text-white"></i>
        </a>
      </li>
      @if (!loading) {
        <div class="scrollbar overflow-y">
          @for (message of items; track message) {
            <li>
              <div class="media">
                <!-- ProjectStatus -->
                @if (['ProjectAllocation','ProjectCreated','ProjectStatusClosed','ProjectStatusInvoiced','ProjectStatusLive','ProjectStatusPaused','ProjectOverQuota'].includes(message.type)) {
                  <i class="align-self-center notification-icon fak fa-project-alerts or-primary"></i>
                }
                @if (['VendorCostConfirmed','VendorOverQuota','VendorOverQuotaGroup','VendorProjectStop','VendorReconciledID','VendorStatus'].includes(message.type)) {
                  <i class="align-self-center notification-icon fa-md fa-vendors fak or-primary"></i>
                }
                @if (['NoteAdded'].includes(message.type)) {
                  <i class="align-self-center notification-icon fak fa-notes fa-md or-primary"></i>
                }
                @if (['SecondaryPmChange'].includes(message.type)) {
                  <i class="align-self-center notification-icon fa-md fa-team fak or-primary"></i>
                }
                @if (['DOR Alert','OQ Alert'].includes(message.type)) {
                  <i class="align-self-center notification-icon fak fa-project-alerts or-primary"></i>
                }
                @if (['MilestoneEndDate','TasksAssignedToMe','TasksStatusChange'].includes(message.type)) {
                  <i class="align-self-center notification-icon fa-md fa-tasklist fak or-primary"></i>
                }
                <div class="media-body">
                  <a [routerLink]="['/projects', message.projectId]" class="font-weight-bold or-primary-link text-white"><h6 class="my-0 or-secondary">{{ message.title }}</h6></a>
                  @if (message.isNew) {
                    <small class="badge badge-pill badge-success text-white text-uppercase float-right mt-1">new</small>
                  }
                  <span><i class="far fa-clock fa-sm pr-1"></i>{{ message.time }}</span>
                </div>
              </div>
            </li>
          }
        </div>
      }
      @if (loading) {
        <li>
          <div class="row p-2">
            <div class="col d-flex justify-content-center">
              <div class="loading loading-lg"></div>
            </div>
          </div>
        </li>
      }
    </ul>
  </div>
}

@if (auth.isVendor()) {
  <div class="btn-group notifications">
    <span type="button" class="btn-lg p-2" [routerLink]="['/alert-settings']">
      <i class="far fa-bell text-secondary fa-lg"></i>
    </span>
  </div>
}
