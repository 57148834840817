<div class="card">
  <div class="card-header or-primary-bg text-white ">
    <div class="h4 inline float-left"><em class="fa fa-user-tag fa-sm"></em> Manage all Roles</div>
    <div class="float-right">
      <div class="form-inline form">
        <input [(ngModel)]="filterText" type="search" class="form-control form-control-sm inline"
          placeholder="Filter...">
        <button type="button" (click)="openModal('add', false)" class="btn btn-sm btn-outline-light inline ml-2"
          routerLinkActive="active"><em class="fa fa-plus-circle"></em> Add Role</button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div>
      <tabset>
        <!-- The query behind this returns all users so until this is rectified this should only be visiblt to superadmin -->
        <tab heading="System Roles" *ngIf="auth.isSuperAdmin()">
          <div class="my-2">
            <or-list [items]="systemRoles | filter : filterText : filterColumns" [layout]="layout"
              [actions]="systemRoleactions" (action)="handleAction($event, true)"></or-list>
          </div>
        </tab>

        <tab heading="User defined Roles">
          <div class="my-2">
            <or-list [items]="roles | filter : filterText : filterColumns" [layout]="layout" [actions]="actions"
              (action)="handleAction($event, false)"></or-list>
          </div>
        </tab>
      </tabset>
    </div>

  </div>

  <ng-template #modalConfirm>
    <div class="modal-header">
      <p class="h5">Confirm Delete</p>
    </div>
    <div class="modal-body text-center">
      <p class="h6">Are you sure you want to delete Role {{this.deleteRole.name}}?</p>
      <button type="button" class="btn btn-outline-secondary m-2" (click)="confirmDelete()">Yes</button>
      <button type="button" class="btn btn-secondary" (click)="openModalRef.hide()">No</button>
    </div>
  </ng-template>