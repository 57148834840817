<div class="d-flex mb-3 mt-1 border-bottom">
  <div class="text-truncate or-secondary h4 m-2"> Keys </div>
  <div class="sectionbtn nav-item mt-1">
    <a class="nav-link or-primary cursor-pointer" [class.font-weight-bold]="tab == 'api-keys'" (click)="tab = 'api-keys'">API Keys </a>
  </div>
  <div class="sectionbtn nav-item mt-1">
    <a class="nav-link or-primary cursor-pointer" [class.font-weight-bold]="tab == 'security-keys'" (click)="tab = 'security-keys'">Security Keys </a>
  </div>
</div>

<app-apikey-manager *ngIf="tab == 'api-keys'"></app-apikey-manager>
<app-securitykey-manager *ngIf="tab == 'security-keys'"></app-securitykey-manager>