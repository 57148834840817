import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { InstanceService, AlertifyService, UtilsService, Instance,SettingsService } from 'core';
import { Router,ActivatedRoute } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-instance-manager',
  templateUrl: './instance-manager.component.html',
  styleUrls: ['./instance-manager.component.scss']
})
export class InstanceManagerComponent implements OnInit {

  instance: any;
  instances: any[];
  deleteInstance: any;
  selectedtemplate = 'blank';
  submitted = false;
  editForm = new FormGroup({ name: new FormControl('', [Validators.required]) });

  templates = [
    {name: 'Blank', description: 'Blank Instance', icon: 'fas fa-server', type: 'blank' },
    {name: 'Fieldwork', description: 'Nav Fieldwork', icon: 'fas fa-check-double', type: 'fieldwork' },
    {name: 'Enterprise', description: 'Nav Enterprise', icon: 'fas fa-chart-line', type: 'enterprise' }
  ];

  modelInstance = {} as Instance;

  @ViewChild('modalConfirm')
  modalRefConfirm: TemplateRef<any>;

  openModalRef: any;
  filterColumns = ['name'];
  settings= { sortBy: null, sortDir: 'ASC', appliedFilters: [{id: 'active', values: ['Yes'],label:'Active'}] };
  filterText: string;
  layout = [
            {label: 'Profile', id: 'img', type: 'img'},
            {label: 'Name', id: 'name', sortable: true},
            {label: 'Description', id: 'description'},
            {label: 'Product type', id: 'productType'},
            {label: 'License type', id: 'licenseType'},
            {label: 'Active', id: 'active',  filterable: true},
            {label: 'ResponseID', id: 'responseIDEnable', type: 'toggle'},
            {label: 'CleanID', id: 'cleanIDImplementation', type: 'cleanID'},
            {label: 'Actions', type: 'actions-inline'}
  ];
  actions = [{label: 'Edit Instance', type: 'edit', icon: 'pen-square'}, {label: 'Delete Instance', type: 'delete', icon: 'trash-alt'}];

  constructor(private modalService: BsModalService,
    private instanceService: InstanceService,
    private activatedRoute: ActivatedRoute,
    private settingsService: SettingsService,
    private router: Router,
    private alertify: AlertifyService,
    public utils: UtilsService) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {     
      if(params['edit'] == 'edit') {
        this.settings = this.settingsService.getUsersViewSettings('Instance');
      }else{
        this.settings= { sortBy: null, sortDir: 'ASC', appliedFilters: [{id: 'active', values: ['Yes'],label:'Active'}] };
        this.saveUserListSetting(this.settings);
      }

    });

    this.getInstances();
   
  }

  handleAction(action) {
    const dataEdit: any = action.row;

    if (action.type === 'edit') {
      this.router.navigate(['/instance/edit/' + dataEdit.id]);
    }
    if (action.type === 'delete') {
      this.deleteInstance = action.row;
      this.openModalRef = this.modalService.show(this.modalRefConfirm);
    }
    }

  openModalInstance(modal) {
    this.openModalRef = this.modalService.show(modal, {animated: true, keyboard: false, class: 'modal-md' });
  }


  saveUserListSetting(settings) {
    this.settingsService.setUsersViewSetting(settings, 'Instance');
  }
  confirmDelete() {
    this.instanceService.deleteInstance(this.deleteInstance.id).subscribe( data => {

    }, error => {
       this.alertify.error('Unable to delete instance');
       this.openModalRef.hide();
    },
    () => {
      this.alertify.success(this.deleteInstance.name + ' deleted successfully');
      this.openModalRef.hide();
      this.getInstances();
    });
  }

  selectTemplate(type) {
    this.selectedtemplate = type;
  }

  submitForm() {
    this.submitted = true;

    if (!this.editForm.valid) return;
    
    this.instanceService.addInstance({ name: this.editForm.value.name, template: this.selectedtemplate}).subscribe(data => {
      this.instance = data;
        if (this.selectedtemplate == 'fieldwork') {
          this.alertify.success('Instance with Nav Fieldwork Template created successfully!');
        }
        else if (this.selectedtemplate == 'enterprise'){
          this.alertify.success('Instance with Nav Enterprise Template created successfully!');
        }
        else {
          this.alertify.success('Instance created successfully');
        }
    }, error => {
        this.alertify.error('Unable to add Instance');
    }, () => {
      this.openModalRef.hide();
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
        this.router.navigate(['/instance/edit/' + this.instance.id])
      );
    });
  }

  getInstances() {
    this.instanceService.getInstances().subscribe(data => {
        this.instances = data.map(item => { 
          item.active = item.active ? 'Yes' : 'No';
          return item;
        });   
        console.log( this.instances)
      }, error => {
          this.alertify.error('Unable to get Instances');
      });
    }
  }

