import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService, AlertifyService } from 'core';

@Component({
  selector: 'app-confirmation-link',
  templateUrl: './confirmation-link.component.html',
  styleUrls: ['./confirmation-link.component.scss']
})
export class ConfirmationLinkComponent implements OnInit {
  model: any = {};
  confirmationForm: FormGroup;
  constructor(private auth: AuthService, private router: Router,
              private activatedRoute: ActivatedRoute, private alertify: AlertifyService) { }

  ngOnInit() {
      this.activatedRoute.queryParams.subscribe(params => {
        this.model.code = params['code'];
      });
      this.confirmationForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required, Validators.minLength(4)]),
        confirmPassword: new FormControl('', [Validators.required])
      }, this.passwordMatchValidator);
  }

  passwordMatchValidator(g: FormGroup) {
    return g.get('password').value === g.get('confirmPassword').value ? null : {mismatch: true};
  }

  confirmEmailToken() {
    this.model.email = this.confirmationForm.value.email;
    this.model.password = this.confirmationForm.value.password;
    this.auth.confirmEmailToken(this.model).subscribe(next => {
      this.alertify.success('Your password has been set up.');
      this.router.navigate(['']);
    }, error => {
      this.alertify.error(error);
    }, () => {
       this.router.navigate(['']);
    });
  }
}