<div class="wholeModal">
  <div class="modal-header text-white">
    <p class="h4 position-absolute"><em class="fa fa-cog fa-sm"></em> Vendor Mapping Setup</p>
    <button type="button" class="close pull-right text-white" aria-label="Close" (click)="bsModalRef.hide();">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div>
      <input type="hidden">
      <div class="card">
        <div class=" card-body overflow-y scrollbar px-1">
          <div class="col">
            <div class="">
              <div class="row pb-3 px-4" *ngIf="partner.linkedPartnerId !== undefined && partner.linkedPartnerId !== null && partner.linkedPartnerId !== '00000000-0000-0000-0000-000000000000'">
                <div class="col p-0">
                  <input readonly type="text" class="form-control" [value]="partner.linkedPartnerName" />
                </div>
                <div class="col">
                  <button (click)="copyMappings()" class="btn btn-secondary btn-sm">Copy Mappings</button>
                </div>
              </div>

              <div class="row pb-3 px-4" *ngIf="questionList != []">
                <div class="col p-0">
                  <select name="groupSelect" class="form-control form-control-sm inline mr-3" [(ngModel)]="questionSelect" [ngModelOptions]="{standalone: true}" id="instanceId">
                    <option [value]="null" disabled selected>Select quota group</option>
                    <ng-container *ngFor="let group of questionList | keyvalue">
                      <ng-container  *ngIf="group.value.setup == false"> 
                        <option [value]="group.key">
                          {{group.key}}
                        </option>
                      </ng-container>
                    </ng-container>
                  </select>
                </div>
                <div class="col">
                  <button (click)="addQuestion()" class="btn btn-secondary btn-sm">Add Group</button>
                </div>
              </div>
              <div class="card-footer seperation pb-0"></div>
            </div>
          </div>
          <div class="modalCardBody overflow-y scrollbar px-3">
            <div class="px-3 pb-3">
              <!-- Warnings -->
              <!-- <ng-container *ngIf="emptyCodeExists">
               <div class="warning alert-danger alert mb-2">
                 <!-- <i class="fas fa-info-circle fa-lg mr-2 "></i> -->
               <!-- A group code property cannot be empty.
               </div>
             </ng-container> -->
             <ng-container *ngIf="duplicateCodeExists">
               <div class="warning alert-danger small alert mb-2">
                 <!-- <i class="fas fa-info-circle fa-lg mr-2 "></i> -->
                 All group codes must be unique.
               </div>
             </ng-container>
             <ng-container *ngIf="duplicateQuotaCodeExists">
               <div class="warning alert-danger small alert mb-2">
                 <!-- <i class="fas fa-info-circle fa-lg mr-2 "></i> -->
                 All quota codes in a group must be unique.
               </div>
             </ng-container>
            </div>
           
            <ng-container *ngFor="let question of vendorQuotaMap.quotaGroupMappings; let i = index">
              <div *ngIf="question.isDeleted == false" class="mb-3">
                <!-- Hispanic / Gender / Ethnicity -->
                    <ng-container>
                        <hr *ngIf="i > 0">
                        <div class=" float-right">
                          <button (click)="removeQuestion(i, question.type)" class="btn btn-xs btn-outline-danger mx-2"><i class="fa fa-lg fa-times "></i></button>
                        </div>
                        <div class=" mb-2">
                          <div class="h5">{{question.type == 'HouseholdIncome' ? 'Income' : question.type}}</div>
                         
                        </div>
                        <div class=" mb-3">
                          <div class="col-4">
                            <label for="groupCode" class="required" required>
                              Group code
                            </label>
                            <input type="text"placeholder="" name="groupCode" (change)="checkForOverlaps()" [(ngModel)]="question.code" class="form-control form-control-sm" maxlength="20"  [ngClass]="{'is-invalid' : (triedSubmitting && (question.code == '' || question.code == null)) || groupCodeDict[question.code] > 1}">
                          </div>
                        </div>
                        
                        <div class="col pr-0">
                          <table class="w-100 ">
                            <tr  *ngIf="question.quotaMappings.length > 0">
                              <th class="quotaColumn">Quota</th>
                              <th class="">
                                Code
                              </th>
                              <th class="">
                              </th>
                            </tr>
                            <tr *ngFor="let mapping of question.quotaMappings; let m = index">
                              <ng-container *ngIf="mapping.isDeleted == false">
                                <td>
                                  <ng-container *ngIf="question.type != 'Income'">
                                    {{mapping.quotaString}}
                                  </ng-container>
                                  <ng-container *ngIf="question.type == 'Income'">
                                    {{mapping.label}}
                                  </ng-container>
                                </td>
                                <td>
                                  <input [(ngModel)]="mapping.code" type="text" class="form-control form-control-sm" (change)="checkForOverlaps()" maxlength="10" [ngClass]="{'is-invalid' : quotaCodeDict[question.type][mapping.code] > 1}">
                                </td>
                                <td>
                                  <button tabindex="-1" class="btn text-secondary" (click)="removeMapping(question, m)">
                                    <i class="fa fa-times"></i>
                                  </button>
                                </td>
                              </ng-container>
                              
                            </tr>
                            <tr>
                              <td class="">
                               
                                <div class="quotaSelect row col" *ngIf="question.type != 'Income' && question.type != 'HouseholdIncome' && question.type != 'Age' && question.type != 'Geo/Zip'">
                                  <select [(ngModel)]="optionSelect[question.type]" class="form-control form-control-sm mt-2 col">
                                    <option [value]="null" disabled selected>Select a quota</option>
                                    <option [value]="'all'" class="">Add all</option>
                                    <ng-container *ngIf="question.type != 'Income'">
                                      <option *ngFor="let option of questionList[question.type].options" [value]="option.value">{{option.value}}</option>
                                    </ng-container>
                                    <ng-container *ngIf="question.type == 'Income'">
                                      <option *ngFor="let option of questionList[question.type].options" [value]="option.label">{{option.label}}</option>
                                    </ng-container>
                                  </select>
                                  <div class="px-2"*ngIf="question.type != 'Income' && question.type != 'Age' && question.type != 'Geo/Zip'" >
                                    <button (click)="addMapping(question)" class="btn mt-1 px-0 text-secondary"><i class="fa fa-plus"></i></button>
                                  </div>
                                </div>
                              </td>     
                              <td>
                              
                              </td>        
                              <td></td>         
                            </tr>
                          </table>
                        </div>
                        
                    </ng-container>
                  </div>
                
            </ng-container>
          </div>
          <div class="card-footer text-right">
            <button (click)="saveMapping()" class="btn btn-sm or-primary-bg text-white"><em class="fa fa-save"></em> Save Mapping</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>