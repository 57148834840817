

    <form [formGroup]="clientForm" (ngSubmit)="clientAdd()" novalidate>
      <div class="card m-3">
        <div class="card-header text-white or-primary-bg">
          <p class="h4 inline"><em class="fa fa-project-diagram fa-sm"></em> New Client Details</p>
        </div>
        <div class="card-body">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <label for="name" class="required">Name</label>
          <input type="text" class="form-control" formControlName="name" id="name" [ngClass]="submitted && utils.showCssValidField(clientForm, 'name')" />
        </div>
        <div class="col-md-6 col-sm-12">
          <label for="website">Website </label>
          <input type="website" class="form-control" formControlName="website" id="website" placeholder="www.example.com" />
        </div>
        <div class="col-md-6 col-sm-12">
          <label for="internalAccountOwnerId" >Account Owner</label>
          <select class="custom-select d-block w-100" formControlName="internalAccountOwnerId" id="internalAccountOwnerId">
              <option *ngFor="let owner of owners" [value]="owner.id">{{owner.name}}</option>
          </select>
        </div>
        <div class="col-md-6 col-sm-12">
          <label for="salesTerm">Sales Term</label>
          <input type="text" class="form-control" formControlName="salesTerm" id="salesTerm" />
        </div>
        <div class="col-md-6 col-sm-12">
          <label for="salesTermValue">Sales Term Value </label>
          <input type="number" class="form-control" formControlName="salesTermValue" id="salesTermValue" placeholder=0 />
        </div>
      </div>
      <div class="row m-3">
          <div class="col-12">
            <label for="img">Profile <span class="text-muted"> (512 x 512 PNG or JPEG)</span></label>
          </div>
          <div class="col-md-12 col-lg-6">
            <or-upload  [smallMsg]="'512 x 512 PNG or JPG'" [showPreviewImg]=true [directToS3]=true [acceptImages]=true formControlName="img" id="img" ></or-upload>
          </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-6 col-sm-12">
          <h5>Address <small class="text-muted">(Optional)</small></h5>
          <div>
            <label for="address">Address Line 1</label>
            <input type="text" class="form-control" formControlName="address1" id="address1" placeholder="1234 Main St">
          </div>
          <div>
            <label for="address2">Address Line 2 </label>
            <input type="text" class="form-control"  formControlName="address2" id="address2" placeholder="Apartment or suite">
          </div>
          <div>
            <label for="country">City</label>
            <input type="text" class="form-control" id="city" formControlName="city" placeholder="">
          </div>
          <div>
            <label for="state">State/Province</label>
            <input type="text" class="form-control" id="state" formControlName="state" placeholder="">
          </div>
          <div>
            <label for="country">Country</label>
            <input type="text" class="form-control" id="country" formControlName="country" placeholder="">
          </div>
          <div>
            <label for="zip">Zip/Post Code</label>
            <input type="text" class="form-control" id="zip" formControlName="zip" placeholder="">
          </div>
        </div>

        <div class="col-md-6 col-sm-12">
          <h5>Social <small class="text-muted">(Optional)</small></h5>
          <div>
            <label for="facebook" >Facebook</label>
            <input type="text" class="form-control"  formControlName="facebook" id="facebook" placeholder="http://facebook.com/example" >
          </div>
          <div>
              <label for="linkedIn">LinkedIn</label>
              <input type="text" class="form-control"  formControlName="linkedIn" id="linkedIn" placeholder="http://linkedIn.com/example" >
          </div>
          <div>
            <label for="twitter">Twitter </label>
            <input type="text" class="form-control" formControlName="twitter" id="twitter" placeholder="http://twitter.com/example" >
          </div>
          <div>
            <label for="skype">Skype </label>
            <input type="text" class="form-control" formControlName="skype" id="skype" placeholder="Username" >
          </div>
        </div>
      </div>
     </div>
     <div class="card-footer text-right">
        <div class="btn btn-light mr-2" [routerLink]="['/clients']" type="button">Cancel</div>
        <button class="btn or-primary-bg text-white" type="submit">Save</button>
      </div>
    </div>
    </form>

