<div class="d-flex mb-3 mt-1 border-bottom">
  <div class="text-truncate or-secondary h4 m-2"> Vendors </div>
</div>

<div class="card">
  <div class="card-header or-primary-bg text-white">
    <p class="h4 inline float-left"><em class="fa fa-handshake fa-sm"></em> Manage all Vendors</p>
    <div class="float-right">
      <div class="form-inline form">
        <input [(ngModel)]="filterText" type="search" class="form-control form-control-sm inline" placeholder="Filter...">
        <!-- <button
          type="button"
          [routerLink]="['/vendors/add']"
          class="btn btn-sm btn-outline-light inline ml-2"
          routerLinkActive="active">
          <em class="fa fa-plus-circle"></em> Add Vendor
        </button> -->
        <button
          type="button"
          (click)="addOrClone()"
          class="btn btn-sm btn-outline-light inline ml-2"
          routerLinkActive="active">
          <em class="fa fa-plus-circle"></em> Add Vendor
        </button>
        <button
          *ngIf="auth.isSuperAdmin()"
          type="button"
          [routerLink]="['/vendors/add']"
          [state]="{ isGlobal: true }"
          class="btn btn-sm btn-outline-light inline ml-2"
          routerLinkActive="active">
          <em class="fa fa-plus-circle"></em> Add Global Vendor
        </button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="d-flex mb-3 mt-1 border-bottom" *ngIf="auth.isSuperAdmin()">
      <div class="sectionbtn nav-item mt-1">
        <a class="nav-link or-primary cursor-pointer" [class.font-weight-bold]="tab == 'instance-level'" (click)="tab = 'instance-level'">Instance<span *ngIf="partnersLocal?.length > 0"> ({{partnersLocal.length}})</span></a>
      </div>
      <div class="sectionbtn nav-item mt-1">
        <a class="nav-link or-primary cursor-pointer" [class.font-weight-bold]="tab == 'global-level'" (click)="tab = 'global-level'">Global<span *ngIf="partnersGlobal?.length > 0"> ({{partnersGlobal.length}})</span></a>
      </div>
    </div>

    <div class="mt-0" *ngIf="tab == 'instance-level'">
      <or-list [items]="partnersLocal | filter : filterText : filterColumns" [layout]="layoutLocal" [actions]="actions" (action)="handleAction($event)"></or-list>
    </div>
    <div class="mt-0" *ngIf="tab == 'global-level'">
      <or-list [items]="partnersGlobal | filter : filterText : filterColumns" [layout]="layoutGlobal" [actions]="actions" (action)="handleAction($event)"></or-list>
    </div>
  </div>
</div>

<ng-template #modalConfirm>
  <div class="modal-header">
    <p class="h5">Confirm Delete</p>
  </div>
  <div class="modal-body text-center">
    <p class="h6">Are you sure you want to delete {{this.deletePartner.name}}?</p>
    <button type="button" class="btn btn-outline-secondary m-2" (click)="confirmDelete()">Yes</button>
    <button type="button" class="btn btn-secondary" (click)="openModalRef.hide()">No</button>
  </div>
</ng-template>

<ng-template #modalAddOrLink>
  <div class="modal-header d-flex align-items-center justify-content-center">
    <div class="header-grid w-100 position-relative">
      <div class="d-flex justify-content-center align-content-center mt-3">
        <h2 class="nav-secondary mb-0 ml-5 font-weight-bold">Create a new vendor</h2>
      </div>
      <div class="d-flex justify-content-end align-content-center position-absolute w-100 sticky-top">
        <i class="fas fa-close grey4" (click)="closeModal()"></i>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col"></div>
      <div class="col-8 col-md-6">
        <div class="row mb-2 mt-2">
          <div class="col">
            <div class="nav-border1 nav-rounded d-flex align-items-center justify-content-center cursor-pointer" style="height: 200px; width: 200px"
                  [class.active-option]="createType=='quick'" (click)="createType='quick'">
              <i class="far fa-4x fa-plus grey3"></i>
            </div>
            <div class="col mt-3 px-0">
              <span class="d-block nav-font16 nav-secondary font-weight-bold mb-1">Start from scratch</span>
            </div>
          </div>
          <div class="col">
            <div class="nav-border1 nav-rounded d-flex align-items-center justify-content-center cursor-pointer" style="height: 200px; width: 200px"
                  [class.active-option]="createType=='clone'" (click)="createType='clone'">
              <i class="fas fa-handshake fa-4x grey3"></i>
            </div>
            <div class="col mt-3 px-0">
              <span class="d-block nav-font16 nav-secondary font-weight-bold mb-1">Use a global vendor</span>
              <span class="d-block nav-font16 grey5">
                <div class="justify-content-center or-dropdown">
                  <select class="form-control form-control-sm form-control-nav" (change)="copyPartner($event)">
                    <option value="">-- Select --</option>
                    <option *ngFor="let p of partnersGlobal" [value]="p.id">{{p.name}}</option>
                  </select>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col"></div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row d-flex w-100" >
      <div class="col text-right pr-0">
        <button class="btn nav-btn-primary mx-2 shadow-none font-weight-bold" (click)="closeModal()">Cancel</button>
        <button class="btn nav-btn-accent shadow-none font-weight-bold" [attr.disabled]="createType=='clone' && !selectedPartnerId ? true : null" (click)="create()">Create</button>
      </div>
    </div>
  </div>
</ng-template>
