
<div class="d-flex mb-3 mt-1 border-bottom">
  <div class="text-truncate or-secondary h4 m-2"> Instances </div>
</div>

<div class="card">
  <div class="card-header or-primary-bg text-white">
    <p class="h4 inline float-left"><em class="fa fa-server fa-sm"></em> Manage all Instances</p>
    <div class="float-right">
      <div class="form-inline form">
        <input [(ngModel)]="filterText" type="search" class="form-control form-control-sm inline" placeholder="Filter...">
        <button type="button" (click)="openModalInstance(modal)" class="btn btn-sm btn-outline-light inline ml-2" routerLinkActive="active"><em class="fa fa-plus-circle"></em> Add Instance</button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="mt-0">
      <or-list [items]="instances | filter : filterText : filterColumns" [layout]="layout"
       [listsettings]="settings"(settingsupdate)="saveUserListSetting($event)" [actions]="actions" (action)="handleAction($event)"></or-list>
    </div>
  </div>
</div>

<ng-template #modalConfirm>
  <div class="modal-header">
    <p class="h5">Confirm Delete</p>
  </div>
  <div class="modal-body text-center">
    <p class="h6">Are you sure you want to delete {{this.deleteInstance.name}}?</p>
    <button type="button" class="btn btn-outline-secondary m-2" (click)="confirmDelete()">Yes</button>
    <button type="button" class="btn btn-secondary" (click)="openModalRef.hide()">No</button>
  </div>
</ng-template>


<ng-template #modal>
  <div class="modal-header text-white">
    <div class="h5">Add New Instance</div>
    <button type="button" class="close pull-right text-white" aria-label="Close" (click)="openModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="editForm" novalidate>
      <div class="row mb-3">
        <div class="col">
          <label for="name" class="required">Name</label>
          <input type="text" class="form-control" formControlName="name" id="name" [ngClass]="submitted && utils.showCssValidField(editForm, 'name')">
        </div>
      </div>
      <label class="font-weight-normal mb-3 required d-block mb-2">Template</label>
      <div class="gridtemplate">
        <ng-container *ngFor="let template of templates">
          <div class="btn btn-sm btn-light d-inline-flex shadow w-100 p-2" [class.active]="template.type == selectedtemplate" (click)="selectTemplate(template.type)">
            <div class="col-2 d-flex align-items-center">
              <i class="fas fa-lg align-self-center or-title opacity-70 pr-2" [class]="template.icon"></i>
            </div>
            <div class="col-10 text-center">
              <h5>{{ template.name }}</h5>
              <span>{{ template.description }}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </form>
  </div>
  <div class="modal-footer text-right">
    <button type="button" class="btn btn-sm btn-secondary" (click)="openModalRef.hide()">Cancel</button>
    <or-spinner-button label="Confirm" #spinner customClass="btn btn-sm btn-outline-secondary float-right" (callback)="submitForm()"></or-spinner-button>
  </div>
</ng-template>

