import { Component, Input, OnInit } from '@angular/core';
import { AlertifyService, AuthService, Partner, PartnerService, SecurityKeyService, UtilsService } from 'core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-partner-edit-redirects',
  templateUrl: './partner-edit-redirects.component.html',
  styleUrls: ['./partner-edit-redirects.component.scss']
})
export class PartnerEditRedirectsComponent implements OnInit {

  @Input('parentGroup')
  parentGroup = null;

  @Input('vendorId')
  vendorId;

  @Input('globalPartnerId')
  globalPartnerId = '00000000-0000-0000-0000-000000000000';

  submitted = false;
  healthConfigs = [];

  partner: Partner = {
    id: '',
    instanceId: '',
    name: '',
    connectionId: '',
    userDomain: '',
    img: '',
    address: {
      address1: '',
      address2: '',
      city: '',
      country: ''
    }
  };

  redirects;

  layout = [
    { label: 'Event', id: 'event', type: 'text' },
    { label: 'Url', id: 'url', type: 'text' },
    { label: 'Actions', type: 'actions-inline' }
  ];

  actions = [
    { label: 'Edit Redirect', type: 'edit', icon: 'pen-square' }
  ];

  url;
  urlEvent;
  urlParamValues = [];

  editEndpoint = false;

  constructor(
    public auth: AuthService,
    public utils: UtilsService,
    public modalService: BsModalService,
    private securityKeyService: SecurityKeyService,
    private partnerService: PartnerService,
    private alertify: AlertifyService) { }

  ngOnInit(): void {
    forkJoin([
      this.partnerService.GetPartner(this.vendorId, true),
    ]).subscribe(responseList => {
      this.partner = responseList[0];
      this.updateRedirectList();
    })
  }

  updateRedirectList() {
    this.redirects = [
      { event: 'COMPLETE', url: this.partner.completeRedirectURL },
      { event: 'QC', url: this.partner.qcRedirectURL },
      { event: 'TERMINATE', url: this.partner.terminateRedirectURL },
      { event: 'OVERQUOTA', url: this.partner.overQuotaRedirectURL }
    ];
  }

  copyRedirects() {
    this.partnerService.copyRedirects(this.vendorId, this.globalPartnerId).subscribe(redirects => {
      if (redirects === null) {
        this.alertify.warning('Nothing to copy');
        return;
      }
      this.alertify.success('Redirects copied from linked global vendor');
      this.parentGroup.value.completeRedirectURL = redirects.completeRedirectURL;
      this.parentGroup.value.terminateRedirectURL = redirects.terminateRedirectURL;
      this.parentGroup.value.overQuotaRedirectURL = redirects.overQuotaRedirectURL;
      this.parentGroup.value.qcRedirectURL = redirects.qcRedirectURL;
      this.updateRedirectList();
    }, error => {
      console.log(error);
    });
  }
}
