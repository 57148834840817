import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { AlertifyService, AuthService, Partner, PartnerService, SecurityKeyService, UtilsService, Webhook } from 'core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SurveyEngineService } from 'projects/core/src/services/survey-engine.service';

@Component({
  selector: 'app-partner-edit-webhooks',
  templateUrl: './partner-edit-webhooks.component.html',
  styleUrls: ['./partner-edit-webhooks.component.scss']
})
export class PartnerEditWebhooksComponent implements OnInit {
  @Input('hooks')
  hooks: Webhook[] = [];
  @Input('vendorid')
  vendorid;
  @Input('globalPartnerId')
  globalPartnerId = '00000000-0000-0000-0000-000000000000';

  keys = [];

  openModalRef: BsModalRef;
  @ViewChild('modal') modalRef: BsModalRef;

  submitted = false;
  layout = [
    {label: 'Event', id: 'hookEvent', type: 'text'},
    {label: 'Method', id: 'method', type: 'text'},
    {label: 'Endpoint', id: 'endpoint', type: 'text'},
    {label: 'Actions', type: 'actions-inline'}
  ];
  actions = [
    {label: 'Edit Webhook', type: 'edit', icon: 'pen-square'},
    {label: 'Delete Webhook', type: 'delete', icon: 'trash-alt'}
  ];

  eventValue = '';
  methodValue = '';
  endpointValue = '';
  urlParamValues = [];
  headerValues = [];
  bodyValues = [];

  urlParamEdit = '';
  headerEdit = '';
  bodyEdit = '';

  webhook;
  uneditedWebhook;

  constructor(
    private alertify: AlertifyService,
    private surveyEngineService: SurveyEngineService,
    public auth: AuthService,
    public utils: UtilsService,
    public modalService: BsModalService,
    private securityKeyService: SecurityKeyService,) { }

  ngOnInit(): void {
    this.loadSecurityKeys();
  }

  loadSecurityKeys() {
    this.securityKeyService.getSecurityKeys(this.auth.getInstance()).subscribe(data => {
      this.keys = data;
    });
  }

  createNew(modal) {
    this.openModalRef = this.modalService.show(modal, {animated: true, keyboard: false, class: 'modal-md' });

    this.webhook = {
      id: null,
      instanceId: this.auth.getInstance(),
      vendorId: this.vendorid,
      hookEvent: '',
      method: '',
      endpoint: '',
      urlParams: '',
      headers: '',
      body: ''
    };

    this.eventValue = '';
    this.methodValue = '';
    this.endpointValue = '';
    this.urlParamValues = [];
    this.headerValues = [];
    this.bodyValues = [];
  }

  handleAction(action, modal) {
    const dataEdit: any = action.row;
    if (action.type === 'edit') {
      this.openModalRef = this.modalService.show(modal, {animated: false, keyboard: false, class: 'modal-md' });

      this.webhook = this.hooks.find(x => x.id === action.row.id);
      this.uneditedWebhook = JSON.parse(JSON.stringify(this.webhook));

      this.eventValue = this.webhook.hookEvent;
      this.methodValue = this.webhook.method;
      this.endpointValue = this.webhook.endpoint;

      this.urlParamValues = [];
      this.headerValues = [];
      this.bodyValues = [];

      if (this.webhook.urlParams) {
        // substring(1) removes the ? at the start
        let urlParamPairs = this.webhook.urlParams.substring(1).split("&");
        for (var pair of urlParamPairs) {
          pair = pair.split("=");
          let key = pair[0];
          let value = pair[1];

          if (value == "#cleanid#" || value == "#orid#" || value == "#orid#") {
            this.urlParamValues.push({key: key, value: value, type:'built-in'});
          }
          else if (value.charAt(0) == '#' && value.charAt(value.length - 1) == '#') {
            this.urlParamValues.push({key: key, value: value.substring(1, value.length-1), type:'passthrough'});
          }
          else if (value.charAt(0) == '{' && value.charAt(value.length - 1) == '}') {
            this.urlParamValues.push({key: key, value: value.substring(1, value.length-1), type:'return'});
          }
          else if (value.charAt(0) == '(' && value.charAt(value.length - 1) == ')') {
            this.urlParamValues.push({key: key, value: value.substring(2, value.length-2).split("(")[1], type:'secret-key'});
          }
          else {
            this.urlParamValues.push({key: key, value: value, type:'freetext'});
          }
        }
      }

      if (this.webhook.headers) {
        if (this.webhook.headers.charAt(0) == "{" && this.webhook.headers.charAt(this.webhook.headers.length - 1) == "}") {
          for (let [key, value] of Object.entries(JSON.parse(this.webhook.headers))) {
            let headerValue = String(value);

            if(headerValue == "#cleanid#" || headerValue == "#orid#" || headerValue == "#orid#") {
              this.headerValues.push({key: key, value: headerValue, type:'built-in'});
            }
            else if (headerValue.charAt(0) == '#' && headerValue.charAt(headerValue.length - 1) == '#') {
              this.headerValues.push({key: key, value: headerValue.substring(1, headerValue.length-1), type:'passthrough'});
            }
            else if (headerValue.charAt(0) == '{' && headerValue.charAt(headerValue.length - 1) == '}') {
              this.headerValues.push({key: key, value: headerValue.substring(1, headerValue.length-1), type:'return'});
            }
            else if (headerValue.charAt(0) == '(' && headerValue.charAt(headerValue.length - 1) == ')') {
              this.headerValues.push({key: key, value: headerValue.substring(2, headerValue.length-1).split(")")[0], type:'secret-key'});
            }
            else {
              this.headerValues.push({key: key, value: value, type:'freetext'});
            }
          }
        }
        else {
          var headerPairs = this.webhook.headers.split(",");
          for (var pair of headerPairs) {
            pair = pair.split(":");
            this.headerValues.push({key: pair[0], value: pair[1]});
          }
        }
      }

      if (this.webhook.body) {
        for (let [key, value] of Object.entries(JSON.parse(this.webhook.body))) {
          let bodyValue = String(value);

          if(bodyValue == "#cleanid#" || bodyValue == "#orid#" || bodyValue == "#orvid#") {
            this.bodyValues.push({key: key, value: bodyValue, type:'built-in'});
          }
          else if (bodyValue.charAt(0) == '#' && bodyValue.charAt(bodyValue.length - 1) == '#') {
            this.bodyValues.push({key: key, value: bodyValue.substring(1, bodyValue.length-1), type:'passthrough'});
          }
          else if (bodyValue.charAt(0) == '{' && bodyValue.charAt(bodyValue.length - 1) == '}') {
            this.bodyValues.push({key: key, value: bodyValue.substring(1, bodyValue.length-1), type:'return'});
          }
          else if (bodyValue.charAt(0) == '(' && bodyValue.charAt(bodyValue.length - 1) == ')') {
            this.bodyValues.push({key: key, value: bodyValue.substring(2, bodyValue.length-1).split(")")[0], type:'secret-key'});
          }
          else {
            this.bodyValues.push({key: key, value: value, type:'freetext'});
          }
        }
      }
    } else if (action.type === 'delete') {
      let deleteWebhook = action.row;
      this.surveyEngineService.deleteWebhook(deleteWebhook.vendorId, deleteWebhook.id).subscribe(data => {
        this.hooks = data;
        this.alertify.success('Webhook deleted successfully');
      }, error => {
         this.alertify.error('Unable to delete webhook');
      });
    }
  }

  closeAllInputs() {
    for (var itemList of [this.urlParamValues, this.headerValues, this.bodyValues]) {
      for (let item of itemList) {
        item.keyEdit = false;
        item.typeEdit = false;
        item.valueEdit = false;
      }
    }
  }

  handleSecretKeyChange(item) {
    item.value = this.keys.find(key => key.name === item.key).value;
  }

  addUrlParam() {
    this.urlParamValues.push({key: '', value: '', type: 'freetext'});
  }

  addHeader() {
    this.headerValues.push({key: '', value: '', type: 'freetext'});
  }

  addBodyValue() {
    this.bodyValues.push({key: '', value: '', type: 'freetext'})
  }

  deleteRow(section, i) {
    switch (section)  {
      case 'urlParam': {
        this.urlParamValues.splice(i, 1);
      }
      case 'header': {
        this.headerValues.splice(i, 1);
      }
      case 'body': {
        this.bodyValues.splice(i, 1);
      }
    }
  }

  cancel() {
    let i = this.hooks.findIndex(x => x.id === this.webhook.id);
    if (i !== null && i !== undefined) {
      this.hooks[i] = this.uneditedWebhook;
    }
    this.closeModal();
  }

  closeModal() {
    this.openModalRef.hide();
    this.openModalRef = null;
  }

  save() {
    if (this.eventValue === '' || this.methodValue === '' || this.endpointValue === '') {
      this.alertify.error('Hook event, method and endpoint required');
      return;
    }

    let urlParams = '?';
    for (let item of this.urlParamValues) {
      if (item.type == 'freetext' || item.type == 'built-in') {
        urlParams += item.key + '=' + item.value;
      }
      else if (item.type == 'passthrough') {
        urlParams += item.key + '=' + '#' + item.value + '#';
      }
      else if (item.type == 'return') {
        urlParams += item.key + '=' + '{' + item.value + '}';
      }
      else if (item.type == 'secret-key') {
        urlParams += item.key + '=' + '('+ '(' + item.type + ')' + '(' + item.value + ')' + ')';
      }
      urlParams += '&';
    }
    urlParams = urlParams.substring(0, urlParams.length - 1);

    let headers = {};
    for (let item of this.headerValues) {
      if (item.type == 'freetext' || item.type == 'built-in') {
        headers[item.key] = item.value;
      }
      else if (item.type == 'passthrough') {
        headers[item.key] = '#' + item.value + '#';
      }
      else if (item.type == 'return') {
        headers[item.key] = '{' + item.value + '}';
      }
      else if (item.type == 'secret-key') {
        headers[item.key] = '('+ '(' + item.type + ')' + '(' + item.value + ')' + ')';
      }
    }

    let body = {};
    for (let item of this.bodyValues) {
      if (item.type == 'freetext' || item.type == 'built-in') {
        body[item.key] = item.value;
      }
      else if (item.type == 'passthrough') {
        body[item.key] = '#' + item.value + '#';
      }
      else if (item.type == 'return') {
        body[item.key] = '{' + item.value + '}';
      }
      else if (item.type == 'built-in') {
        body[item.key] = item.value;
      }
      else if (item.type == 'secret-key') {
        body[item.key] = '('+ '(' + item.type + ')' + '(' + item.value + ')' + ')';
      }
    }

    this.webhook.hookEvent = this.eventValue;
    this.webhook.method = this.methodValue;
    this.webhook.endpoint = this.endpointValue;
    this.webhook.urlParams = urlParams;
    this.webhook.body = JSON.stringify(body);
    this.webhook.headers = JSON.stringify(headers);

    if (this.webhook.id == null) {
      this.webhook.id = uuidv4();
      this.hooks.push(this.webhook);
    }

    this.surveyEngineService.saveWebhooks(this.webhook.vendorId, this.hooks).subscribe(data => {
      this.hooks = data;
      this.alertify.success('Webhook updated successfully');
      this.closeModal();
    }, error => {
      this.alertify.error('Unable to save webhook');
    })
  }

  cloneWebhooks() {
    this.surveyEngineService.copyWebhooks(this.vendorid, this.globalPartnerId).subscribe(data => {
      if (data === null) {
        this.alertify.warning('Nothing to copy');
        return;
      }
      this.alertify.success('Webhooks copied from linked global vendor');
      this.hooks = data;
    }, error => {
      console.log(error);
    });
  }
}
