import { Injectable } from '@angular/core';
import { Instance, SiteSection } from 'core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstanceService {
  baseUrl = environment.apiEndpoint + '/instance/';

  siteSectionsFlat: SiteSection[] = [];
  siteSections: SiteSection[] = [];
  siteSectionsLinks = [
    { type: 'ProjectList', url: '/projects' },
    { type: 'Team', url: '/team' },
    { type: 'Vendors', url: '/vendors' },
    { type: 'Clients', url: '/clients' },
    { type: 'ReportsCleanID', url: '/reports/cleanid/api' },
    { type: 'ReportsCleanIDNav', url: '/reports/cleanid/nav' },
    { type: 'TaskList', url: '/schedule' },
    { type: 'ReportsPerformance', url: '/reports/performance' },
    { type: 'Licensee', url: '/reports/licensee' },
    { type: 'ReportsAudit', url: '/reports/audit' },
    { type: 'ReportsReportBuilder', url: '/reports/builder' },
    { type: 'ReportsWorkload', url: '/reports/workload' },
    { type: 'ReportsClient', url: '/reports/client/invoice' },
    { type: 'ReportsClientColl', url: '/reports/client/coll' },
    { type: 'ReportsVendor', url: '/reports/vendor' },
    { type: 'ReportsResponseID', url: '/reports/responseid' },
    { type: 'ReportsResponseIDApi', url: '/reports/responseid/api' },
    { type: 'ReportsResponseIDNav', url: '/reports/responseid/nav' },
    { type: 'Dashboard', url: '/dashboard' },
    { type: 'Home', url: '/home' },
    { type: 'HomeManager', url: '/home/manager' },
    { type: 'Account', url: '/account' }
  ];

  constructor(private http: HttpClient) { }

  getInstances(): Observable<Instance[]> {
    return this.http.get<Instance[]>(this.baseUrl + '');
  }

  getInstance(id: any): Observable<Instance> {
    return this.http.get<Instance>(this.baseUrl + id);
  }

  addInstance(model: any): Observable<Instance> {
    return this.http.post<Instance>(this.baseUrl + '', model);
  }

  editInstance(model: any): Observable<Instance> {
    return this.http.put<Instance>(this.baseUrl + model.id, model);
  }

  deleteInstance(id: string) {
    return this.http.delete(this.baseUrl + id);
  }

  getUserSections(): Observable<SiteSection[]> {
    return this.http.get<SiteSection[]>(this.baseUrl + 'setup/userSections', { headers : {cache : '0'}});
  }

  getSections(instanceId): Observable<SiteSection[]> {
    return this.http.get<SiteSection[]>(this.baseUrl + 'setup/sections?instanceId='+ instanceId, { headers : {cache : '0'}});
  }

  addSection(model: any): Observable<SiteSection> {
    return this.http.post<SiteSection>(this.baseUrl + 'setup/sections', model);
  }

  editSection(model: any): Observable<SiteSection> {
    return this.http.put<SiteSection>(this.baseUrl + 'setup/sections/' + model.id, model);
  }

  deleteSection(id: any): Observable<SiteSection> {
    return this.http.delete<SiteSection>(this.baseUrl + 'setup/sections/' + id);
  }

  UpdateInstanceValue(instanceId: string, fieldId: string, value: any): Observable<any> {
    let field: any = {fieldId};
    if (typeof value === 'boolean') {
      field.bVal = value;
    }
    else if (typeof value === 'string') {
      field.sVal = value;
    }
    else if (typeof value === 'number') {
      field.nVal = value;
    }
    else if (value instanceof Date) {
      field.dVal = value;
    }
    return this.http.put<any>(this.baseUrl + instanceId + '/field', field );
  }
  SetupSiteSections(data) {

    this.siteSectionsFlat = [];
    let sections = [];
    data.forEach(item => {
      item.children = [];

      if (item.type == 'Partners') item.type = 'Vendors';
      item.icon = (item?.icon.trim() == '' ? null : item.icon);

      if (item.type) {
        item.url = this.siteSectionsLinks.find(x => x.type == item.type)?.url;

        if (item.projectTypeId !== null && item.type === 'ProjectList') {
          item.url = item.url + '/list/' + item.projectTypeId;
        }
        if (item.otherInstanceId !== null && (item.type === 'ReportsResponseIDApi' || item.type === 'ReportsCleanID')) {
          item.url = item.url + '/' + item.otherInstanceId;
        }
        item.icon = (item.icon ? item.icon : 'fak fa-' + (item?.type.toLowerCase()) + ' fa-md');
      } else {
        item.icon = (item.icon ? item.icon : 'fak fa-' + (item?.label.replace(' ', '').toLowerCase()) + ' fa-md');
      }
      this.siteSectionsFlat.push(item);
      if (item.parentId == null)
        sections.push(item);
    });

    sections.forEach(item => {
      item.children = data.filter(x => x.parentId == item.id);
    });
    this.siteSections = sections;
  }

  GetCurrentSection() {
    if(this.siteSectionsFlat == null || this.siteSectionsFlat.length == 0) return null;

    let url = window.location.pathname;
    let arr = url.split('/');
    let path = '/' + arr[1].toLowerCase();

    let currentSection = null;

    const arrReturnUrl = decodeURIComponent(url).replace('?', '').split('=');
    if (arrReturnUrl.length > 0) {
      currentSection = this.siteSectionsFlat.find(x => x.url?.includes( arrReturnUrl[0]));
    }

    if (!currentSection) {
      currentSection= this.siteSectionsFlat.find(x => x.url === path);
    }
    if (!currentSection) {
      currentSection = this.siteSectionsFlat.find(x => x.url === url);
    }
    if (!currentSection) {
      currentSection = this.siteSectionsFlat.find(x => x.url?.includes(path));
    }

    if (currentSection?.parentId !== null) {
      let parent = this.siteSectionsFlat.find(x => x.id === currentSection?.parentId);
      return  {label: currentSection?.label, url:currentSection?.url, icon:currentSection?.icon, siteLabel: parent?.label};
    }
    return  {label: currentSection?.label, url:currentSection?.url, icon:currentSection?.icon, siteLabel: currentSection?.label};
  }

}
