  <!-- <form [formGroup]="resetForm" (ngSubmit)="reset()" class="form-signin text-center mt-5">
    <label for="email" class="sr-only">Email address</label>
    <input type="email" class="form-control" [ngClass]="{'is-invalid': resetForm.get('email').errors && resetForm.get('email').touched}" id="email"  formControlName="email"  aria-describedby="emailHelp" placeholder="Email">
    <div class="invalid-feedback">Please enter a valid email!</div>

    <label for="password" class="sr-only">Email address</label>
    <input type="password" class="form-control  mt-3" [ngClass]="{'is-invalid': resetForm.get('password').errors && resetForm.get('password').touched}" id="password" value="password"  formControlName="password" placeholder="Password">
    <div class="invalid-feedback" *ngIf="resetForm.get('password').hasError('required') && resetForm.get('password').touched" >Password is required!</div>
    <div class="invalid-feedback" *ngIf="resetForm.get('password').hasError('minlength') && resetForm.get('password').touched" >Password must be at least 4 charcters!</div>

    <label for="confirmPassword" class="sr-only">Password</label>
    <input type="password" class="form-control is-invalid" [ngClass]="{'is-invalid': resetForm.get('confirmPassword').errors && resetForm.get('confirmPassword').touched ||resetForm.get('confirmPassword').touched &&resetForm.hasError('mismatch')}" id="confirmPassword" value="password"  formControlName="confirmPassword" placeholder="Confirm Password">
    <div class="invalid-feedback" *ngIf="resetForm.get('confirmPassword').hasError('required') && resetForm.get('confirmPassword').touched " >Confirm password is required!</div>
    <div class="invalid-feedback" *ngIf="resetForm.hasError('mismatch') && resetForm.get('confirmPassword').touched " >Passwords must match!</div>
    <button class="btn btn-lg btn-primary btn-block" [disabled]="!resetForm.valid" type="submit">Reset</button>
  </form> -->

  <div class="card mt-5" style="max-width: 450px; margin:0 auto">
    <div class="card-header or-secondary-bg text-white">
      <h5>Reset Password</h5>
    </div>
    <div class="card-body">
      <form [formGroup]="resetForm" (ngSubmit)="reset()" class="form-signin text-center">
        <label for="email" class="sr-only">Email address</label>
        <input type="email" class="form-control" [ngClass]="{'is-invalid': resetForm.get('email').errors && resetForm.get('email').touched}" id="email"  formControlName="email" aria-describedby="emailHelp" placeholder="Email">
        <div class="invalid-feedback">Please enter a valid email!</div>

        <label for="password" class="sr-only">Email address</label>
        <input type="password" class="form-control mt-3" [ngClass]="{'is-invalid': resetForm.get('password').errors && resetForm.get('password').touched}" id="password" value="password"  formControlName="password" placeholder="Password">
        <div class="invalid-feedback" *ngIf="resetForm.get('password').hasError('required') && resetForm.get('password').touched">Password is required!</div>
        <div class="invalid-feedback" *ngIf="resetForm.get('password').hasError('minlength') && resetForm.get('password').touched">Password must be at least 4 charcters!</div>

        <label for="confirmPassword" class="sr-only">Password</label>
        <input type="password" class="form-control is-invalid" [ngClass]="{'is-invalid': resetForm.get('confirmPassword').errors && resetForm.get('confirmPassword').touched ||resetForm.get('confirmPassword').touched &&resetForm.hasError('mismatch')}" id="confirmPassword" value="password"  formControlName="confirmPassword" placeholder="Confirm Password">
        <div class="invalid-feedback" *ngIf="resetForm.get('confirmPassword').hasError('required') && resetForm.get('confirmPassword').touched">Confirm password is required!</div>
        <div class="invalid-feedback" *ngIf="resetForm.hasError('mismatch') && resetForm.get('confirmPassword').touched">Passwords must match!</div>

        <button class="btn btn-lg btn-primary btn-block" [disabled]="!resetForm.valid" type="submit">Reset</button>
      </form>
    </div>
    <div class="card-footer text-muted text-center  or-secondary-bg text-white">
      <small>© 2024 OpinionRoute, LLC. | All Rights Reserved.</small>
    </div>
  </div>
