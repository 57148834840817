import { Component, Input, OnInit } from '@angular/core';
import { AlertifyService, AuthService, DropdownService, UtilsService } from 'core';

@Component({
  selector: 'app-partner-edit-main-details',
  templateUrl: './partner-edit-main-details.component.html',
  styleUrls: ['./partner-edit-main-details.component.scss']
})
export class PartnerEditMainDetailsComponent implements OnInit {

  @Input('parentGroup')
  parentGroup = null;

  submitted = false;
  healthConfigs = [];
  partnersGlobal: any[];
  selectedPartner: any;
  hasGlobalPartner = false;

  constructor(
    public auth: AuthService,
    public utils: UtilsService,
    private alertify: AlertifyService,
    private dd: DropdownService) { }

  ngOnInit(): void {
    this.hasGlobalPartner = this.parentGroup.value.globalPartner !== '00000000-0000-0000-0000-000000000000';
    this.getPartners();
  }

  showCssValidField(field) {
    if (this.submitted) {
      return (this.submitted && this.utils.showCssValidField(this.parentGroup.form, field));
    }
  }

  getPartners() {
    this.dd.getGlobalPartners().subscribe(data => {
      this.partnersGlobal = data;
      this.selectedPartner = this.partnersGlobal.find(x => x.value.toLowerCase() === this.parentGroup.value.globalPartner.toLowerCase());
    }, error => {
       this.alertify.error('Unable to get global vendors');
    });
  }
}
