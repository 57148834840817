import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartnerEditComponent } from './partner-edit.component';
import { ComponentsModule } from 'core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PartnerEditMainDetailsComponent } from './partner-edit-main-details/partner-edit-main-details.component';
import { PartnerEditRedirectsComponent } from './partner-edit-redirects/partner-edit-redirects.component';
import { PartnerEditWebhooksComponent } from './partner-edit-webhooks/partner-edit-webhooks.component';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule
  ],
  declarations: [
    PartnerEditComponent,
    PartnerEditMainDetailsComponent,
    PartnerEditRedirectsComponent,
    PartnerEditWebhooksComponent
  ]
})
export class PartnerEditModule { }
