import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService, UtilsService } from 'core';

@Component({
  selector: 'app-project-types-main-details',
  templateUrl: './project-types-main-details.component.html',
  styleUrls: ['./project-types-main-details.component.scss']
})
export class ProjectTypesMainDetailsComponent implements OnInit {

  @Input('selectedType')
  selectedType = null;

  @Input('healthConfigs')
  healthConfigs = [];

  @Input('securityConfigs')
  securityConfigs = [];

  @ViewChild('typesForm')
  private listViewForm: NgForm;

  submitted = false;
  linkSecure = false;
  constructor(
    public auth: AuthService,
    public utils: UtilsService) { }

  ngOnInit(): void { 
    this.linkSecure = (this.selectedType.defaultSecurityConfigSurvey == null) ? false: true;
  }

  showCssValidField(field) {
    if (this.submitted) {
      return (this.submitted && this.utils.showCssValidField(this.listViewForm.form, field));
    }
  }
}
