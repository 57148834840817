import { Component, OnInit , AfterViewInit} from '@angular/core';

import { AuthService, } from 'core';
import { HttpClient } from '@angular/common/http';
import { UploaderService } from 'projects/core/src/services/uploader.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
 
  constructor( private auth: AuthService, private http: HttpClient, public uploader: UploaderService, private router: Router) { }

  ngOnInit() {
    const user = this.auth.getUser()
    if (!user.superAdmin && this.auth.loggedIn()) {
        this.router.navigate(['/usermanager']);
    }
  }
  onFileSelected(event) {
    this.uploader.upload(event);
  }
}