import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResetLinkComponent } from './reset-link.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

  ],
  declarations: [ResetLinkComponent]
})
export class ResetLinkModule { }
