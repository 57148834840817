import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService, DropdownService, PartnerService, ReportsService } from 'core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-live-monitoring',
  templateUrl: './live-monitoring.component.html',
  styleUrls: ['./live-monitoring.component.scss']
})
export class LiveMonitoringComponent implements OnInit {

  typeReport: string;
  instanceId: string;
  projectId = '';
  partnerId = '';
  markerData: any = {};
  enableProjectId = false;
  enablePartnerId = false;

  @ViewChild('modalConfirm')
  modalRefConfirm: BsModalRef;

  openModalRef: any;

  constructor(
    private reportService: ReportsService,
    private dropdownService: DropdownService,
    private partnerService: PartnerService,
    public auth: AuthService
  ) { }

  ngOnInit() {
    this.instanceId = this.auth.getUser().instanceId;
    this.typeReport = 'hour';
    this.loadData();
  }

  loadData() {
    // Fetch real data from backend
    this.reportService.GetLiveMonitoringData(this.typeReport, this.projectId, this.partnerId).subscribe( data => {
      this.splitData(data);
    }, error => {

    });
  }

  splitData(data: any) {
    let entries: any = [];
    let starts: any = [];
    let completes: any = [];
    let dropoffs: any = [];
    let fraud: any = [];
    let invalid: any = [];
    let errors: any = [];
    let dupes: any = [];

    data.forEach(e => {

      switch (e.chartType) {
        case 'Entries': entries.push([e.timeBucket, e.colCount]); break;
        case 'Starts': starts.push([e.timeBucket, e.colCount]); break;
        case 'Completes': completes.push([e.timeBucket, e.colCount]); break;
        case 'Dropoffs': dropoffs.push([e.timeBucket, e.colCount]); break;
        case 'Fraud': fraud.push([e.timeBucket, e.colCount]); break;
        case 'Invalid': invalid.push([e.timeBucket, e.colCount]); break;
        case 'Errors': errors.push([e.timeBucket, e.colCount]); break;
        case 'Dupes': dupes.push([e.timeBucket, e.colCount]); break;
        default:  break;
      }
      
    });

    this.markerData.entries = entries;
    this.markerData.starts = starts;
    this.markerData.completes = completes;
    this.markerData.dropoffs = dropoffs;
    this.markerData.fraud = fraud;
    this.markerData.invalid = invalid;
    this.markerData.errors = errors;
    this.markerData.dupes = dupes;
    
  }

  changeTypeReport(event: any) {
    this.typeReport = event.target.value;
    this.loadData();
  }

  getProjectList = (filter) => {
    return this.dropdownService.projects(filter, '', 'survey');
  }

  updateProject(value) {
    this.projectId = value[0]?.value;
    this.loadData();
  }

  getPartnerList = (filter) => {
    return this.dropdownService.getPartners();  
  }

  updatePartner(value) {
    this.partnerId = value[0]?.value;
    this.loadData();
  }
}
