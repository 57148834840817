import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Instance, AlertifyService, InstanceService, UtilsService } from 'core';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-instance-edit',
  templateUrl: './instance-edit.component.html',
  styleUrls: ['./instance-edit.component.scss']
})
export class InstanceEditComponent implements OnInit {

  model = {} as Instance;
  roles: string[];
  submitted = false;
  openModalRef: any;
  newKeyField: string;
  editForm: FormGroup;
  @ViewChild('modalConfirm')
  modalConfirm: TemplateRef<any>;

  constructor(private alertify: AlertifyService, private instanceService: InstanceService, private router: Router,
              private route: ActivatedRoute, public utils: UtilsService, private modalService: BsModalService ) {

                this.editForm = new FormGroup({
                  name: new FormControl('', [Validators.required]),
                  description: new FormControl(''),
                  img: new FormControl(''),
                  responseIDPrivateKey: new FormControl(''),
                  cleanIDPrivateKey: new FormControl(''),
                  vIDBatchPackage: new FormControl('0'),
                  vIDRealtimePackage: new FormControl('0'),
                  cleanIDEnable: new FormControl(false),
                  cleanIDLicenseTrial: new FormControl(false),
                  projectEnable: new FormControl(false),
                  partnerEnable: new FormControl(false),
                  clientEnable: new FormControl(false),
                  clientView: new FormControl(false),
                  responseIDEnable: new FormControl(false),
                  cleanIDImplementation: new FormControl(''),
                });
     }

  ngOnInit() {
   this.route.paramMap.subscribe(params => {
      const id: string = params.get('id');

      if (id) {
        this.loadData(id);
      } else if (history.state.data != null) {
        this.model = history.state.data;
        this.bindForm();
      }
    });
  }
  cancelClick() {
    this.router.navigate(['/instancemanager'], { queryParams: { edit: 'edit' } })
      
  }
  submitForm() {
    this.submitted = true;

    if (!this.editForm.valid) return;

    if (!this.editForm.value) {
      this.instanceService.addInstance(this.editForm.value).subscribe(data => {
        this.model = data;
      }, error => {
         this.alertify.error('Unable to add Instance');
      }, () => {
        this.alertify.success('Instance added successfully');
        //this.router.navigate(['/instancemanager']);
        this.router.navigate(['/instancemanager'], { queryParams: { edit: 'edit' } })
      
      });
    } else {
      this.instanceService.editInstance(this.editForm.value).subscribe(data => {
        this.model = data;
      }, error => {
         this.alertify.error('Unable to edit Instance');
      }, () => {
        this.alertify.success('Instance edited successfully');
        //this.router.navigate(['/instancemanager']);
        this.router.navigate(['/instancemanager'], { queryParams: { edit: 'edit' } })
      });
    }
  }

  loadData(id: any) {
    this.instanceService.getInstance(id).subscribe( data => {
      this.model = data;
    }, error => {
       this.alertify.error('Unable to get Instance');
    }, () => {
      this.bindForm();
    });
  }

  bindForm() {
    if (this.model) {

      this.editForm = new FormGroup({
        id: new FormControl(this.model.id, [Validators.required]),
        name: new FormControl(this.model.name, [Validators.required]),
        description: new FormControl(this.model.description),
        img: new FormControl(this.model.img),
        responseIDPrivateKey: new FormControl(this.model.responseIDPrivateKey),
        cleanIDPrivateKey: new FormControl(this.model.cleanIDPrivateKey),
        cleanIDEnable: new FormControl(this.model.cleanIDEnable),
        cleanIDLicenseTrial: new FormControl(this.model.cleanIDLicenseTrial),
        projectEnable: new FormControl(this.model.projectEnable),
        partnerEnable: new FormControl(this.model.partnerEnable),
        clientEnable: new FormControl(this.model.clientEnable),
        clientView: new FormControl(this.model.clientView),
        responseIDEnable: new FormControl(this.model.responseIDEnable),
        cleanIDImplementation: new FormControl(this.model.cleanIDImplementation)
      });
    }
  }

  createNewKey(type) {
    var newKey = uuidv4();
    this.newKeyField = type;
    if (type == 'cleanIDPrivateKey') {
      if(this.editForm.getRawValue().cleanIDPrivateKey != ''){
        this.openModalRef = this.modalService.show(this.modalConfirm);
      }
      else {
        this.editForm.patchValue( {
          cleanIDPrivateKey: newKey
        })
      }
    }
    else if (type == 'responseIDPrivateKey') {
      if(this.editForm.getRawValue().responseIDPrivateKey != ''){
        this.openModalRef = this.modalService.show(this.modalConfirm);
      }
      else {
        this.editForm.patchValue( {
          responseIDPrivateKey: newKey
        })
      }
    }
   
  }
  confirmNewKey(){
    var type = this.newKeyField;
    var newKey = uuidv4();
    if (type == 'cleanIDPrivateKey') {
      this.editForm.patchValue( {
        cleanIDPrivateKey: newKey
      });
    }
    else if (type == 'responseIDPrivateKey') {
      this.editForm.patchValue( {
        responseIDPrivateKey: newKey
      })
    }
    this.openModalRef.hide();
  }
}
