
<!-- Navbar -->
<app-navbar *ngIf="auth.loggedIn()"></app-navbar>

<div class="d-flex" id="wrapper">

  <!-- Sidebar -->
  <app-sidebar *ngIf="auth.loggedIn()"></app-sidebar>

  <!-- Page Content -->
  <div id="page-content-wrapper" class="w-100" [class.logged-in]="auth.loggedIn()">
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </div>

</div>
