<div class="d-flex mb-3 mt-1 border-bottom" *ngIf="!typeSelected">
  <div class="text-truncate or-secondary h4 m-2"> Project Manager </div>
  <div class="vl ml-2"></div>
  <ng-container *ngIf="auth.isSuperAdmin()">
    <div class="sectionbtn nav-item mt-1">
      <a class="nav-link or-primary cursor-pointer" [class.font-weight-bold]="tab == 'type'" (click)="tab = 'type'">Types </a>
    </div>
    <div class="sectionbtn nav-item mt-1">
      <a class="nav-link or-primary cursor-pointer" [class.font-weight-bold]="tab == 'list'" (click)="tab = 'list'">List Views </a>
    </div>
  </ng-container>
  </div>

<app-project-types *ngIf="auth.isSuperAdmin() && tab == 'type'" (onselect)="selectedProjectType($event)"></app-project-types>


<app-project-listviews *ngIf="tab == 'list'"></app-project-listviews>

