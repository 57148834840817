import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ComponentsModule, HelpersModule } from 'core';
import { RouterModule } from '@angular/router';
import { DashboardManagerComponent } from './dashboard-manager.component';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    HelpersModule,
    RouterModule
  ],
  declarations: [
    DashboardManagerComponent
  ]
})
export class DashboardManagerModule { }