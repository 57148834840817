<div class="card" *ngIf=integrations.length>
  <div class="card-header or-primary-bg text-white">
    <p class="h4 inline float-left"><em class="fa fa-chart-line fa-sm"></em> Added Integrations</p>
  </div>   
  <div class="card-body customgrid">
    <div class="card" *ngFor="let integration of integrations">      
      <img class="card-img-top" src="{{integration.imageUrl}}" alt="Card image cap">
      <div class="card-body">
        <button type="button" class="btn btn-sm btn-secondary inline" (click)="openModal(modal, integration)" routerLinkActive="active"><em class="fa fa-plus-circle"></em> Edit App Keys</button>           
      </div>
    </div>      
  </div>
</div>

<div class="card" *ngIf=availableIntegrations.length>
  <div class="card-header or-primary-bg text-white">
    <p class="h4 inline float-left"><em class="fa fa-chart-line fa-sm"></em> Available Integrations</p>
  </div>   
  <div class="card-body customgrid">
    <div class="card" *ngFor="let integration of availableIntegrations">      
      <img class="card-img-top" src="{{integration.imageUrl}}" alt="Card image cap">
      <div class="card-body">
        <button type="button" class="btn btn-sm btn-secondary inline" (click)="openModal(modal, integration)" routerLinkActive="active"><em class="fa fa-plus-circle"></em> Add APP</button>           
      </div>
    </div>      
  </div>
</div>
            
<!-- MODAL ADD PUBLIC KEY -->
<ng-template #modal>
  <div class="modal-header">
    <p class="h4 position-absolute"><em class="fa fa-key fa-sm"></em>App Settings
    </p>
    <button type="button" class="close pull-right text-white" aria-label="Close" (click)="modalOpenRef.hide();">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">    
    <!-- <input type="hidden" [(ngModel)]="appModel.id" name="id"> -->
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="row mb-3">
              <div class="col">
                <label for="ClientAppKey" class="required">Client APP Key</label>
                <div class="input-group">
                  <input type="text" class="form-control" name="clientAppKey" [ngModel]="appModel?.clientAppKey" (ngModelChange)="appModel && appModel.clientAppKey = $event">
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <label for="ClientSecret" class="required">Client Secret</label>
                <div class="input-group">
                  <input type="text" class="form-control" name="clientSecret"  [ngModel]="appModel?.clientSecret" (ngModelChange)="appModel && appModel.clientSecret = $event">
                </div>
              </div>
            </div>   
            
          </div>
        </div>
      </div>    
      <div class="card-footer text-right"> 
        <button type="submit" class="btn btn-sm or-primary-bg text-white mr-2" (click)="testKeys()"><em class="fa fa-save"></em> Test Keys</button>              
        <button type="button" class="btn btn-sm or-secondary-bg text-white mr-2" (click)="removeIntegration(this.appModel.id)"><em class="fa fa-trash"></em> Remove APP</button>                      
        <button type="submit" class="btn btn-sm or-primary-bg text-white" (click)="submitIntegrationForm()"><em class="fa fa-save"></em> Save</button>           
      </div>            
    </div>    
  </div>
</ng-template>
      
      