import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ComponentsModule, HelpersModule } from 'core';

import { ClientComponent } from './client.component';
import { ClientAddComponent } from './client-add/client-add.component';
import { ClientEditComponent } from './client-edit/client-edit.component';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    RouterModule,
    HelpersModule,
    ReactiveFormsModule
  ],
  declarations: [
    ClientComponent,
    ClientAddComponent,
    ClientEditComponent
    ]
})
export class ClientModule { }
