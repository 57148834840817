import { Component, OnInit, OnChanges, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { ClientService, AlertifyService, UserService, ComponentsModule, HelpersModule, DropdownService, AuthService } from 'core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})

export class ClientComponent implements OnInit {
  clients: any[];
  filterColumns = ['name'];
  filterText: string;
  instanceId = '';
  deleteClient: any;

  @ViewChild('modalConfirm')
  modalRefConfirm: TemplateRef<any>;

  openModalRef: any;
  layout = [
            {label: 'Profile', id: 'img', type: 'img'},
            {label: 'Name', id: 'name', sortable: true},
            {label: 'Website', id: 'website', type: 'link'},
            {label: 'Actions', type: 'actions-dropdown'}
  ];
  actions = [
  {label: 'Edit Client', type: 'edit'}, 
  {label: 'Delete Client', type: 'delete'},
  {label: 'Clone Client', type: 'clone'}
];

  constructor(private modalService: BsModalService,
              public auth: AuthService,
              private clientService: ClientService,
              private router: Router,
              private alertify: AlertifyService) { }

  ngOnInit() {
    this.instanceId = this.auth.getInstance();
    this.getClients();
  }

  getClients() {
    this.clientService.GetClients(this.instanceId).subscribe( data => {
      this.clients = data;
    }, error => {
       this.alertify.error('Unable to get clients');
    });
  }

  handleAction(action) {
    if (action.type === 'edit') {
      this.router.navigate(['/client/edit/' + action.row.id]);
    }
    if (action.type === 'delete') {
      this.deleteClient = action.row;
      this.openModalRef = this.modalService.show(this.modalRefConfirm);
    }
    if (action.type === 'clone') {
      this.router.navigate(['/client/clone/' + action.row.id]);
    }
  }

  confirmDelete() {
    this.clientService.DeleteClient(this.deleteClient).subscribe( data => {

    }, error => {
       this.alertify.error('Unable to delete Client');
    },
    () => {
      this.alertify.success(this.deleteClient.name + ' deleted successfully');
      this.openModalRef.hide();
      this.getClients();
    });
  }

}
