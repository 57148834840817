
<div class="row">
  <div class="col-md-12 mt-2">
    <div   class="form-group">
      <p class="text-secondary mb-1">Complete Redirect URL
        <em (click)="copyLink(urls.completeRedirectURL)"  *ngIf="urls.completeRedirectURL !=''" class="btn fa fa-copy"></em>
      </p>
      <div class="input-group">
        {{ urls.completeRedirectURL }}
    </div>
    </div>
    <div class="form-group">
      <p class="text-secondary mb-1">Terminate Redirect URL
        <em (click)="copyLink(urls.terminateRedirectURL)"  *ngIf="urls.terminateRedirectURL !=''" class="btn fa fa-copy"></em>
      </p>
      <div class="input-group">
        {{ urls.terminateRedirectURL }}
      </div>
    </div>
    <div class="form-group">
      <p class="text-secondary mb-1">Overquota Redirect URL
        <em (click)="copyLink(urls.overQuotaRedirectURL)"  *ngIf="urls.overQuotaRedirectURL !=''" class="btn fa fa-copy"></em>
      </p>
      <div class="input-group">
        {{ urls.overQuotaRedirectURL }}
      </div>
    </div>
    <div class="form-group">
      <p class="text-secondary mb-1">QC Redirect URL
        <em (click)="copyLink(urls.qcRedirectURL)"  *ngIf="urls.qcRedirectURL !=''" class="btn fa fa-copy"></em>
      </p>
      <div class="input-group">
        {{ urls.qcRedirectURL }}
      </div>
    </div>
    <hr>
    <button type="button" class="btn btn-sm btn-secondary" (click)="copyAllLinks()"><em class="fa fa-copy"></em> Copy All URL's</button>
  </div>
</div>

