import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertifyService, AuthService, DropdownService, Role, RoleService, RoleUser, UserService, UtilsService } from 'core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-role-edit',
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.scss']
})
export class RoleEditComponent implements OnInit {

  @Input() public set editRole(data) {
    this.model = data;
    this.instanceId = this.auth.getInstance();
  }
  @Input() public set mode(data) {
    this._mode = data;
  }
  @Input() public set system(data) {
    this._systemRole = data;
  }

  
  _mode;
  _systemRole;
  submitted = false;
  roles: any[];
  users: any[] = [];
  instanceId = '';
  editForm: FormGroup;
  deleteMember: any;
  model = {} as Role;
  openModalRefUser: any;

  @ViewChild('modalConfirmUser')
  modalRefConfirmUser: TemplateRef<any>;

  filterColumns = ['userName'];
  filterText: string;

  layout:any = [
    {label: 'Name', id: 'userName', sortable: true},
    {label: 'Email', id: 'email', sortable: true}
  ];
  actions = [{label: 'Remove Member', type: 'deleteMember', icon: 'trash-alt'}];

  @Output() saveevent = new EventEmitter();

  constructor(public bsModalRef: BsModalRef,
    private alertify: AlertifyService,
    public utils: UtilsService,
    private userService: UserService,
    private modalService: BsModalService,
    private roleService: RoleService,
    public auth: AuthService) { }

  ngOnInit(): void {
    
    if(!this._systemRole){
      this.layout = [
        {label: 'Name', id: 'userName', sortable: true},
        {label: 'Email', id: 'email', sortable: true},
        {label: 'Actions', type: 'actions-inline'}
      ];
    }

    if (this._mode === 'add') {

      this.editForm = new FormGroup({
        id: new FormControl(''),
        instanceId: new FormControl(this.instanceId, [Validators.required]),
        name: new FormControl('', [Validators.required]),
        description: new FormControl(''),
        type: new FormControl('')
      });

    } else {
      this.loadData();
    }
  }

  submitForm() {

    this.submitted = true;

    if (!this.editForm.valid) {
      return;
    }

    if (this.editForm.value.id !== "") {
      this.roleService.EditRole(this.editForm.value).subscribe(data => {
        this.model = data;
        this.saveevent.emit(this.model);
        this.alertify.success('Role edited successfully');
      }, error => {
         this.alertify.error('Unable to edit Role');
      });

    } else {
      this.roleService.AddRole(this.editForm.value).subscribe(data => {
        this.model = data;
        this.saveevent.emit(this.model);
        this.alertify.success('Role added successfully');
      }, error => {
         this.alertify.error('Unable to add Role');
      });
    }

    this.bsModalRef.hide();
  }

  loadData() {

    this.editForm = new FormGroup({
      id: new FormControl(this.model.id),
      instanceId: new FormControl(this.model.instanceId, [Validators.required]),
      name: new FormControl(this.model.name, [Validators.required]),
      description: new FormControl(this.model.description),
      type: new FormControl(this.model.type)
    });

    if (this._mode === 'manage-users')
      this.bindUsers();
  }

  getUserList = (filter) => {
    return this.userService.GetInstanceUsers(this.instanceId);
  }

  updateUsers(value) {
    const newUser = value[0];
    const roleUser: RoleUser = { instanceRoleId: this.model.id, userId: newUser.id };
    this.roleService.AddUser(roleUser).subscribe(data => {
      if (data.message) {
        this.alertify.error(data.message);
        return;
      }
      this.alertify.success('Member(s) updated successfully');
      this.bindUsers();
    }, error => {
       this.alertify.error('Unable to add member');
    });
  }

  handleAction(action) {
    if (action.type === 'deleteMember') {
      this.deleteMember = action.row;
      this.openModalRefUser = this.modalService.show(this.modalRefConfirmUser, {id: 2}); // has to inform ID when using nested modals
    }
  }

  confirmDelete() {
    this.roleService.RemoveUser(this.deleteMember.id).subscribe(data => {
      this.bindUsers();
    }, error => {
       this.alertify.error('Unable to delete member');
    },
    () => {
        this.alertify.success(this.deleteMember.userName + ' deleted successfully');
        this.openModalRefUser.hide();
    });
  }

  bindUsers() {
    this.roleService.GetAllRoleUsers(this.model.id).subscribe(data => {
      this.model.users = data;
    }, error => {
       this.alertify.error('Unable to get users');
    });
  }

}
