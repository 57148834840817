import { UpperCasePipe } from '@angular/common';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AlertifyService, AuthService, DropdownService, InstanceService } from 'core';

@Component({
  selector: 'app-recruitid',
  templateUrl: './recruitid.component.html',
  styleUrls: ['./recruitid.component.scss'],
  providers: [UpperCasePipe]
})
export class RecruitidComponent implements OnInit {

  tab = 'report';
  instance = null;
  instanceId;
  profile = 'moderate';
  profileDefaults: any;
  settings: any;
  threshold = 25;
  licenseData: any;
  loading = true;
  defaultUrl = 'https://analytics.zoho.com/open-view/2230517000002806651/18124dddad36a152906c9bee33141c14?ZOHO_CRITERIA=%22vwRecruitId%22.%22AccountId%22%3D#accountId#';

  @ViewChild('myIframe') public myIframe;

  constructor(
    public auth: AuthService,
    private alertify: AlertifyService,
    private instanceService: InstanceService,
    public upperPipe: UpperCasePipe) { }

  ngOnInit() {
    this.instanceId = this.auth.getInstance();
    this.setInstance();
  }

  renderChart() {
    this.loading = true;
    const iframe = this.myIframe.nativeElement;
    const cleanIDAccount = this.instance.cleanIDApiKey.split('-');
    if (iframe != null && iframe.hasAttribute('src') && cleanIDAccount.length === 3) {
      iframe.attributes.src.value = this.defaultUrl.replace('#accountId#', cleanIDAccount[1]);
    }
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  }

  setInstance() {
    // TO DO : GET ACCOUNT NUM
    this.instanceService.getInstance(this.instanceId).subscribe( data => {
      this.instance = data;
      this.renderChart();
    }, error => {
       this.alertify.error('Unable to get Instance');
    });
  }

}
