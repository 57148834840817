import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ApiKeyManagerComponent } from './apikey-manager/apikey-manager.component';
import { SecurityKeyManagerComponent } from './securitykey-manager/securitykey-manager.component';
import { KeyManagerComponent } from './key-manager.component';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot()
  ],
  declarations: [
    KeyManagerComponent,
    ApiKeyManagerComponent,
    SecurityKeyManagerComponent
  ]
})
export class KeyManagerModule { }
