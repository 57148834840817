import { Component, OnInit, ChangeDetectorRef, Input, OnChanges } from '@angular/core';
import { SettingsService, AlertifyService } from 'core';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  providers: [SettingsService]
})
export class AlertsComponent implements OnInit, OnChanges {
  @Input() instanceId: string;
  settingsModel: any = {};

  constructor(private settingsService: SettingsService, private alertify: AlertifyService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.getSettings();
  }

  ngOnChanges() {

    this.getSettings();
  }
  getSettings() {
  this.settingsService.getSurveySettings(this.instanceId).subscribe(result => {
    this.settingsModel = result;
    this.cdr.detectChanges();
    }, error => console.error(error));
  }

  saveSettings() {
    this.settingsModel.instanceId = this.instanceId;

    this.settingsService.saveSurveySettings(this.settingsModel).subscribe(() => {
      this.alertify.success('Alert settings have been saved');

      }, error => {
        this.alertify.error('There was a problem saving these settings');
      });
    }
}
