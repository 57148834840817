
<div class="d-flex mb-3 mt-1 border-bottom">
  <div class="text-truncate or-secondary h4 m-2"> CleanID </div>
  <div class="vl ml-2"></div>
  <div class="sectionbtn nav-item">
    <a class="nav-link or-primary mt-1 cursor-pointer" [class.font-weight-bold]="tab == 'licenseV3'" (click)="showLicenseV3()">License</a>
  </div>
  <div class="sectionbtn nav-item">
    <a class="nav-link or-primary mt-1 cursor-pointer" [class.font-weight-bold]="tab == 'report'" (click)="showReport()">Summary Report</a>
  </div>

  <div class="sectionbtn nav-item ml-auto">
    <a class="nav-link opacity-70">
      <a class="btn btn-sm bg-secondary text-white" href="https://nav2.navigatorsurveys.com/onlinehelp?page=en/kb/cleanid" id="help_link" target="_new">Online Manual</a>
    </a>
  </div>
  <div class="sectionbtn nav-item">
    <a class="nav-link opacity-70">
      <a class="btn btn-sm bg-secondary text-white" href="{{ getCleanIDTestPage() }}" id="test_link" target="_new">Test CleanID</a>
    </a>
  </div>
</div>

<div class="card" *ngIf="tab == 'settings'">
  <div class="card-header or-primary-bg text-white">
    <p class="h4 inline float-left"><em class="fa fa-user fa-sm"></em> Manage Settings</p>
  </div>
  <div class="card-body">
    <div class="mt-0">
      <h5>Fraud Detection Profile</h5>
      <div>
        <button type="button" class="btn btn-sm or-primary-bg text-white" (click)="saveSettings()">Request Change</button>
      </div>
      <p>
        To increase the quality of your survey responses, our fraud detection mechanism uses these settings to
        determine if a response is fraudulent or not.
        These settings apply to all your projects.
      </p>
      <div class="btn-group mb-3 " role="group">
        <button type="button" class="btn btn-secondary text-white" [class.active]="profile === 'conservative'"
          (click)="setProfile('conservative')">Conservative (30)</button>
        <button type="button" class="btn btn-secondary text-white" [class.active]="profile === 'moderate'"
          (click)="setProfile('moderate')">Moderate (25)</button>
        <button type="button" class="btn btn-secondary text-white" [class.active]="profile === 'aggressive'"
          (click)="setProfile('aggressive')">Aggressive (20)</button>
        <button type="button" class="btn btn-secondary text-white" [class.active]="profile === 'custom'"
          (click)="setProfile('custom')">Custom</button>
      </div>
      <div *ngIf="profile === 'custom'" class="mb-3">
        <h5>Threshold</h5>
        <select [(ngModel)]="threshold">
          <option value="30">Conservative (30)</option>
          <option value="25">Moderate (25)</option>
          <option value="20">Aggressive (20)</option>
        </select>
      </div>
      <div *ngIf="profile === 'custom'" class="alert alert-danger" role="alert">
        This feature currently requires consultation with a Fraud Detection Specialist to assure stability of your
        respondent traffic. We want you to have the custom setting so please contact your <a
          href="mailto:cleanid-support@orsurveys.com">account representative </a>to set up a consultation.
      </div>

      <div class="cleanid-settings" *ngIf="settings != null">
        <ng-container *ngFor="let card of layout">
          <div class="card mb-3">
            <div class="card-header"><b>{{ card.section }}</b></div>
            <div class="card-body">
              <div *ngFor="let setting of card.settings" class="row">
                <label class="col-sm-8 col-form-label">{{ setting.label }}</label>
                <div class="col-sm-4">
                  <div>
                    <select class="custom-select" [(ngModel)]="settings[setting.ref]" (change)="setProfile('custom')">
                      <option value="1">Low</option>
                      <option value="2">Medium</option>
                      <option value="3">High</option>
                      <option value="4">Critical</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="card" *ngIf="tab == 'report'">
  <div class="card-header or-primary-bg text-white">
    <p class="h4 inline float-left"><em class="fa fa-user fa-sm"></em> Summary Report</p>
  </div>
  <div class="card-body">
    <div class="mt-0">
      <or-cleanid-report></or-cleanid-report>
    </div>
  </div>
</div>

<div class="card" *ngIf="tab == 'licenseV3'">
  <div class="card-header or-primary-bg text-white">
    <div class="row align-items-center">
      <div class="col">
        <span class="h4 text-white"><em class="fa fa-user fa-sm"></em> License Details</span>
      </div>
      <div class="col-3 text-right text-white">
        <div class="btn-group" role="group">
          <button type="button" (click)="setTableData('active')"  [class.bg-white]="filterMode=='active'" class="btn border-white btn-sm {{ filterMode=='active' ? '' : 'text-white' }}">
            Active
          </button>
          <button type="button" (click)="setTableData('all')" [class.bg-white]="filterMode=='all'" class="btn border-white btn-sm {{ filterMode=='all' ? '' : 'text-white' }}">
            All Keys
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="mt-0">
      <or-list [items]="licenseDataV3" [layout]="licenseColsV3"[actions]="[]" [enableExport]=true></or-list>
    </div>
  </div>
</div>
