import { Component, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService, AlertifyService, Integration, AppIntegration, AppIntegrationService } from 'core';

@Component({
  selector: 'app-integration',
  templateUrl: './app-integration.component.html',
  styleUrls: ['./app-integration.component.scss']
})

export class AppIntegrationComponent implements OnInit {
  tab = 'urls'
  instanceId: string;
  submitted = false;
  integrations: any[];  
  availableIntegrations: any[];
  addedIntegrations: any[];
  model = {} as Integration;
  appModel = {} as AppIntegration;

  @ViewChild('modal')
  modalRef: BsModalRef;
  modalOpenRef;    

  constructor(public auth: AuthService, 
    public appIntegrationService: AppIntegrationService,
    private alertify: AlertifyService, 
    public modalService: BsModalService) {  }

  ngOnInit() {
    this.instanceId = this.auth.getInstance();
    this.loadIntegrationsData(); 
    this.loadAvailbleIntegrationsData();     
  }

  public openModal(template: any, integration: any) {
    this.model = integration;
    this.instanceId = this.auth.getInstance();
    this.appModel = {} as AppIntegration;
    this.appIntegrationService.getAppIntegration(integration.id,this.instanceId).subscribe(data => {  
      if (data != null || data != undefined) {
        this.appModel = data;
      }                 
     }, error => {
        this.alertify.error(error);
        console.log(error);
     });
    
    this.modalOpenRef = this.modalService.show(template, { ignoreBackdropClick: true, keyboard: false });
  }

  loadIntegrationsData() {
     this.appIntegrationService.getAllIntegrations(this.instanceId).subscribe( data => {    
      this.integrations = data;
    }, error => {
       this.alertify.error(error);
       console.log(error);
    });
  }

  loadAvailbleIntegrationsData() {
    this.instanceId = this.auth.getInstance();
    this.appIntegrationService.getAvailableIntegrations(this.instanceId).subscribe( data => {    
     this.availableIntegrations = data;
   }, error => {
      this.alertify.error(error);
      console.log(error);
   });
 }

  removeIntegration(id:string) {    
    this.instanceId = this.auth.getInstance();
    this.appIntegrationService.DeleteIntegrations(this.appModel.id).subscribe( data => {     
    }, error => {
       this.alertify.error(error);
    },
    () => {
      this.alertify.success('Integration deleted successfully');
      this.modalOpenRef.hide();
      this.loadIntegrationsData(); 
      this.loadAvailbleIntegrationsData(); 
    });  
  }

  submitIntegrationForm() {
    this.submitted = true;            
     this.appModel.instanceId = this.instanceId; 
     this.appModel.integrationId = this.model.id;      
      this.appIntegrationService.insertAppIntegrationSettings(this.appModel).subscribe( data => {     
      }, error => {
         this.alertify.error(error);
      },
      () => {
        this.alertify.success(this.model.vendorName+' saved successfully');
        this.modalOpenRef.hide();
        this.loadIntegrationsData(); 
        this.loadAvailbleIntegrationsData(); 
  
      });  
    this.modalOpenRef.hide();
  }

  testKeys() {
    this.submitted = true;            
     this.appModel.instanceId = this.instanceId; 
     this.appModel.integrationId = this.model.id;      
      this.appIntegrationService.testKeys(this.appModel).subscribe( data => {     
      }, error => {
         this.alertify.error(error);
      },
      () => {
        this.alertify.success(this.model.vendorName+' Keys tested successfully');
        //this.modalOpenRef.hide();
        this.loadIntegrationsData(); 
        this.loadAvailbleIntegrationsData(); 
  
      });  
   // this.modalOpenRef.hide();
  }
}
