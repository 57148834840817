import { Component } from '@angular/core';
import { AlertifyService, AuthService } from 'core';
import { Location } from '@angular/common';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import { Router } from '@angular/router';
import { environment } from 'projects/core/src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent {

    title = 'admin';
    currentPath: string;
    idleState = 'Not started.';
    timedOut = false;
    lastPing?: Date = null;

    constructor(public auth: AuthService, public idle: Idle, private keepalive: Keepalive, location: Location, router: Router, private alertify: AlertifyService) {
       // sets an idle timeout.
       idle.setIdle(environment.idleSeconds);

       // sets a timeout period of inactivity, the user will be considered timed out.
       idle.setTimeout(environment.timeoutAfterIdleSeconds);

         // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
       idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

       idle.onIdleEnd.subscribe(() => {
           this.idleState = 'No longer idle.';
           // console.log(this.idleState);
          });

       idle.onTimeout.subscribe(() => {
             this.idleState = 'Timed out!';
             this.timedOut = true;
             // console.log(this.idleState);
             this.auth.logOut();
         });

       idle.onIdleStart.subscribe(() => {
           this.idleState = 'You\'ve gone idle!';
           // console.log(this.idleState);
           this.alertify.confirm(this.idleState, 'Hit Ok to stay logged in!', this.reset);
          });


       idle.onTimeoutWarning.subscribe((countdown) => {
           this.idleState = 'You will time out in ' + countdown + ' seconds!';
           // console.log(this.idleState);
          });

        // Sets the ping interval to 15 seconds
        // keepalive.interval(15);
        // keepalive.onPing.subscribe(() => this.lastPing = new Date());

         // Lets check the path everytime the route changes, stop or start the idle check as appropriate.
       router.events.subscribe((val) => {

             this.currentPath = location.path();
             if (this.currentPath !== '') {
                 idle.watch();
             } else {
                idle.stop();
             }

         });
     }

     reset() {
    /*
      console.log(this.idle);

      this.idle.watch();
      this.idleState = 'Started.';
      this.timedOut = false;
    */
  }
  }
