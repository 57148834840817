<div class="d-flex mb-3 mt-1 border-bottom">
  <div class="text-truncate or-secondary h4 m-2"> ResponseID </div>
  <div class="vl ml-2"></div>
  <div class="sectionbtn nav-item ml-auto">
    <a class="nav-link opacity-70">
      <a class="btn btn-sm bg-secondary text-white"
        href="https://nav2.navigatorsurveys.com/onlinehelp?page=en/kb/responseid"
        id="help_link" target="_new">Online Manual</a>
    </a>
  </div>
  <div class="sectionbtn nav-item">
    <a class="nav-link opacity-70">
      <a class="btn btn-sm bg-secondary text-white" href="{{ getResponseIDTestPage() }}" id="test_link" target="_new">Test ResponseID</a>
    </a>
  </div>
</div>

<div class="card">
  <div class="card-header or-primary-bg text-white">
    <div class="row align-items-center">
      <div class="col">
        <span class="h4 text-white"><em class="fa fa-user fa-sm"></em> License Details</span>
      </div>
      <div class="col-3 text-right text-white">
        <div class="btn-group" role="group">
          <button type="button" (click)="setTableData('active')"  [class.bg-white]="filterMode=='active'" class="btn border-white btn-sm {{ filterMode=='active' ? '' : 'text-white' }}">
            Active
          </button>
          <button type="button" (click)="setTableData('all')" [class.bg-white]="filterMode=='all'" class="btn border-white btn-sm {{ filterMode=='all' ? '' : 'text-white' }}">
            All Keys
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="mt-0">
      <or-list [items]="licenseDataV3" [layout]="licenseColsV3"[actions]="[]" [enableExport]=true></or-list>
    </div>
  </div>
</div>
