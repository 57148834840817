<div class="d-flex mb-3 mt-1 border-bottom">
  <div class="text-truncate or-secondary h4 m-2"> Clients </div>
</div>

<div class="card">
  <div class="card-header or-primary-bg text-white">
    <p class="h4 inline float-left"><em class="fa fa-user-tie fa-sm"></em> Manage all Clients</p>
    <div class="float-right">
      <div class="form-inline form">
        <input type="search" [(ngModel)]="filterText" class="form-control form-control-sm inline" placeholder="Filter...">
        <button type="button" [routerLink]="['/client/add']" class="btn btn-sm btn-outline-light inline ml-2" routerLinkActive="active"><em class="fa fa-plus-circle"></em> Add Client</button>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div >
      <or-list [items]="clients | filter : filterText : filterColumns" [layout]="layout" [actions]="actions" (action)="handleAction($event)"></or-list>
    </div>
  </div>
</div>


<ng-template #modalConfirm>
  <div class="modal-header">
    <p class="h5">Confirm Delete</p>
  </div>
  <div class="modal-body text-center">
    <p class="h6">Are you sure you want to delete {{this.deleteClient.name}}?</p>
    <button type="button" class="btn btn-outline-secondary m-2" (click)="confirmDelete()">Yes</button>
    <button type="button" class="btn btn-secondary" (click)="openModalRef.hide()">No</button>
  </div>
</ng-template>
